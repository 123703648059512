
export const SupporterIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.73 10.69">
    <defs>
      <style>{`.cls-1{fill:#000;}`}</style>
    </defs>
    <g id="レイヤー_2" data-name="レイヤー 2">
      <g id="レイヤー_5" data-name="レイヤー 5">
        <path className="cls-1" d="M3.37,5.14A2.57,2.57,0,1,0,.8,2.57,2.57,2.57,0,0,0,3.37,5.14Z"/>
        <path className="cls-1" d="M3.37,5.73C1.24,5.73.18,6.71,0,7.29V9.85c0,.47.54.84,1.21.84H5.53c.66,0,1.2-.37,1.2-.84V7.29C6.56,6.71,5.49,5.73,3.37,5.73Z"/>
      </g>
    </g>
  </svg>
)
