import React, { FC } from "react";
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Field, FieldProps, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { AaTextField } from "components/front/layout/aniale/AaTextField";
import { goErrorPage } from "lib/frontRouting";
import { useRouter } from "next/router";
import { CvsType, Store } from "interfaces/front/business";

export const CvsDialog: FC<{
  isOpen: boolean;
  close: () => void;
  onCvsSelected: (cvsInput: CvsInput) => void;
  onCancel: () => void;
  injectionAmount?: number;
  submitLabel?: string;
  stores: Store[];
}> = ({ close, injectionAmount, isOpen, onCancel, onCvsSelected, submitLabel = "決定", stores }) => {
  const router = useRouter();
  const onSubmit = async (formikValues: CvsFormValues, helper: FormikHelpers<CvsFormValues>) => {
    const store = stores.find((store) => store.name === formikValues.cvs_type);
    if (store) {
      await onCvsSelected({
        store,
        phone_number: formikValues.phone_number,
      });
      await close();
      await helper.resetForm();
      await helper.setSubmitting(false);
    } else {
      await goErrorPage(router);
    }
  };
  return (
    <Dialog onClose={onCancel} open={isOpen} fullWidth scroll="body">
      <Formik<CvsFormValues> initialValues={initialValues} onSubmit={onSubmit} validationSchema={Schema}>
        {({ isSubmitting, handleSubmit }): React.ReactNode => (
          <>
            <DialogTitle disableTypography>
              <span>コンビニの選択</span>
              <IconButton
                onClick={onCancel}
                style={{
                  position: "absolute",
                  right: "6px",
                  top: "0",
                  color: "gray",
                }}
              >
                <Close viewBox={"0 0 24 24"} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {injectionAmount && (
                <div>
                  <div style={{ fontSize: "12px" }}>決済金額</div>
                  <div style={{ margin: "10px 0" }}>{injectionAmount} 円</div>
                </div>
              )}
              <Field component={StoreSelect} name="cvs_type" type="text" stores={stores} />
              <div style={{ margin: "20px 0 0" }}>
                <div style={{ fontSize: "12px" }}>お客様の携帯・ご自宅の電話番号(ハイフンなし)</div>
                <Field
                  fullWidth
                  component={AaTextField}
                  variant="outlined"
                  name="phone_number"
                  type="text"
                  inputProps={{ maxLength: 11 }}
                  size="small"
                  style={{ margin: "10px 0" }}
                />
                <div style={{ fontSize: "12px" }}>※コンビニで入金される際にも入力いただく場合がございます。</div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleSubmit()} disabled={isSubmitting} type="submit" color="primary" variant={"contained"} fullWidth>
                {submitLabel}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

type StoreSelectProps = FieldProps & {
  stores: Store[];
};
const StoreSelect: FC<StoreSelectProps> = ({
  field,
  form: { setFieldValue, values, touched, errors, isSubmitting },
  stores,
}: StoreSelectProps) => {
  const selectStyle = (store: Store): React.CSSProperties => {
    if (values[field.name] === store.name) {
      return { backgroundColor: "#d5daff" };
    } else {
      return {};
    }
  };
  return (
    <>
      {stores.map((store: Store, index) => (
        <React.Fragment key={index}>
          <Card
            variant="outlined"
            style={{
              margin: "10px 0 0",
              cursor: "pointer",
              ...selectStyle(store),
            }}
            onClick={() => {
              if (isSubmitting) return;
              setFieldValue(field.name, store.name);
            }}
          >
            <Grid container alignItems={"center"} style={{ padding: "10px" }}>
              <Grid item>
                <img alt={store.label} src={`/img/cvs/${store.name}.png`} width="30px" />
              </Grid>
              <Grid item style={{ paddingLeft: "10px" }}>
                <span>{store.label}</span>
              </Grid>
            </Grid>
          </Card>
        </React.Fragment>
      ))}
      {touched[field.name] && errors[field.name] && <p className="form_template_area_block--error">{errors[field.name]}</p>}
    </>
  );
};

const Schema = object().shape({
  cvs_type: string().required("上記のいずれかのコンビニを選択してください。"),
  phone_number: string()
    .matches(/^\d{10,11}$/, "電話番号は10~11桁の数値で入力してください")
    .required("必須項目です"),
});

type CvsFormValues = {
  cvs_type: CvsType | "";
  phone_number: string;
};

const initialValues: CvsFormValues = {
  cvs_type: "",
  phone_number: "",
};

export type CvsInput = {
  store: Store;
  phone_number: string;
};
