import styled from "styled-components";

const TextInput = styled.input<{ $error?: boolean }>`
  padding: 8px 10px;
  border: 1px solid ${({ $error }) => ($error ? "#f44336" : "rgba(0, 0, 0, 0.23)")};
  border-radius: 4px;
  line-height: 1em;
  box-shadow: 0 2px 2px -2px rgb(0 0 0 / 70%) inset;
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  font-weight: normal;

  &:hover {
    border-color: ${({ $error }) => ($error ? "#f44336" : "rgba(0, 0, 0, 0.87)")};
  }
`;
export default TextInput;
