import React, { FC, HTMLAttributes, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useFormikContext } from "formik";
import FamilyRadio from "./FamilyRadio";
import BottomSlide from "components/front/motion/BottomSlide";
import Counter from "./Counter";

const DependantsField: FC<HTMLAttributes<HTMLDivElement>> = ({ ...props }) => {
  const formik = useFormikContext();
  const field = formik.getFieldProps("support.dependants_flag");
  const existsDependants = useMemo(() => field.value === "あり", [field.value]);
  useEffect(() => {
    formik.setFieldValue("family.dependants", defaultDependants);
  }, [existsDependants]);
  return (
    <Root {...props}>
      <FamilyRadio name={field.name} label="扶養家族" options={["なし", "あり"]} />
      <BottomSlide in={existsDependants}>
        <FieldSet>
          <StyledCounter label="15歳以下" name="family.dependants.lte_15" />
          <StyledCounter label="16～18歳" name="family.dependants.between_16_and_18" />
          <StyledCounter label="19～22歳" name="family.dependants.between_19_and_22" />
          <StyledCounter label="23～69歳" name="family.dependants.between_23_and_69" />
          <StyledCounter label="70歳以上 (同居の親・祖父母)" name="family.dependants.gte_70_relatives" />
          <StyledCounter label="70歳以上 (上記以外)" name="family.dependants.gte_70_others" />
        </FieldSet>
      </BottomSlide>
    </Root>
  );
};
export default DependantsField;

const defaultDependants = {
  lte_15: 0,
  between_16_and_18: 0,
  between_19_and_22: 0,
  between_23_and_69: 0,
  gte_70_relatives: 0,
  gte_70_others: 0,
};

const Root = styled.div``;
const FieldSet = styled.div`
  padding: 5px 0;
`;
const StyledCounter = styled(Counter)`
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
`;
