import React, { FC, HTMLAttributes } from "react";
import { RadioGroup } from "formik-material-ui";
import { Box, Label, Root } from "components/front/order/recipient/fields/FieldParts";
import { FormHelperText } from "@material-ui/core";
import { ErrorMessage, FastField } from "formik";
import useBirthdayFieldHandler from "lib/hooks/front/useBirthdayFieldHandler";
import styled from "styled-components";

type Props = HTMLAttributes<HTMLDivElement> & { name: string; label: string; fullWidth?: boolean };
const BirthdayField: FC<Props> = ({ name, label, fullWidth, ...props }) => {
  const { years, months, days, year, month, day, onYearChange, onMonthChange, onDayChange } = useBirthdayFieldHandler(name, "1990-01-01");
  return (
    <FastField component={RadioGroup} name={name} {...props}>
      <Root>
        <Label>{label}</Label>
        <StyledBox $fullWidth={fullWidth}>
          <Select value={year} onChange={onYearChange}>
            {years.map((y, idx) => (
              <option value={y} key={idx}>
                {y}
              </option>
            ))}
          </Select>
          <Unit>年</Unit>
          <Select value={month} onChange={onMonthChange}>
            {months.map((m, idx) => (
              <option value={m} key={idx}>
                {m}
              </option>
            ))}
          </Select>
          <Unit>月</Unit>
          <Select value={day} onChange={onDayChange}>
            {days.map((d, idx) => (
              <option value={d} key={idx}>
                {d}
              </option>
            ))}
          </Select>
          <Unit>日</Unit>
        </StyledBox>
        <ErrorMessage name={name}>{(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}</ErrorMessage>
      </Root>
    </FastField>
  );
};
export default BirthdayField;

const StyledBox = styled(Box)`
  padding: 10px;
  display: inline-flex;
  align-items: center;
`;
const Select = styled.select`
  padding: 4px;
  background-color: #EEE;
  //border-bottom: solid 1px #3f51b5;
`;
const Unit = styled.span`
  margin: 0 6px;
`;
