import { BaseClientService, Error, Result } from "../BaseClientService";
import { NextRouter } from "next/router";
import { goErrorPage, goLoginPage } from "lib/frontRouting";
import { MessageThread } from "interfaces/front/business";

export type PostQuery = {
  project_slug: string;
  message: string;
}

export class FrontMessageThreadService extends BaseClientService {

  url: string
  onError: (router: NextRouter) => Promise<void>
  onAuthError: (router: NextRouter) => Promise<void>

  constructor() {
    super();
    this.url = this.API_BASE_URL + '/api/front/message_threads';
    this.onError = goErrorPage
    this.onAuthError = goLoginPage
  }

  async contact(query: PostQuery): Promise<Result<{ id: string }, Error>> {
    const body = JSON.stringify(query);
    return super.doPost<{ id: string }, Error>(`${this.url}/contact`, body);
  }

  async list(router: NextRouter, onSuccess: (messageThreads: MessageThread[]) => void): Promise<void> {
    const response = await fetch(this.url, {
      credentials: "include",
      method: 'GET',
      headers: this.headers
    });
    if (response.ok) {
      onSuccess(await response.json() as MessageThread[])
    } else if (response.status === 401) {
      await this.onAuthError(router)
    } else {
      // 400エラーの場合はバリデーションエラーにしたい
      await this.onError(router)
    }
  }
}
