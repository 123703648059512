import { useAmount } from "lib/hooks/useAmount";
import React, { FC, useMemo } from "react";
import { CartAmountIndicatorInfo } from "interfaces/front/page/orderForm";
import styled from "styled-components";

export type Props = {
  info: CartAmountIndicatorInfo;
  isShopping?: boolean;
  shippingFee?: number;
};
const CartAmountArea: FC<Props> = ({ info, isShopping, shippingFee }) => {
  const { formatCourseTotalPrice, formatSystemCommissionAmount, formatShippingFee, formatTotalPrice, discountDataList } = useCartAmountAreaStates(info, shippingFee);
  return (
    <Root>
      <AmountRow>
        <RowHead>{!isShopping ? "コース金額合計" : "合計金額"}</RowHead>
        <RowValue>
          <Amount>{formatCourseTotalPrice}</Amount>円
        </RowValue>
      </AmountRow>
      {info.systemCommissionTotalPrice > 0 && (
        <AmountRow>
          <RowHead>
            システム利用料({SYSTEM_COMMISSION_AMOUNT}円 × {info.systemCommissionTargetQuantity}個)
          </RowHead>
          <RowValue>
            <Amount>{formatSystemCommissionAmount}</Amount>円
          </RowValue>
        </AmountRow>
      )}
      {shippingFee !== undefined && shippingFee > 0 && (
        <AmountRow>
          <RowHead>送料</RowHead>
          <RowValue>
            <Amount>{formatShippingFee}</Amount>円
          </RowValue>
        </AmountRow>
      )}
      {discountDataList.map((discount, index) => (
        <AmountRow key={index}>
          <RowHead>{discount.itemName}</RowHead>
          <RowValue>
            <Amount>-{discount.formatAmount}</Amount>円
          </RowValue>
        </AmountRow>
      ))}
      <AmountRow>
        <RowHead>合計</RowHead>
        <RowValue>
          <BigAmount>{formatTotalPrice}</BigAmount>円
        </RowValue>
      </AmountRow>
    </Root>
  );
};
export default CartAmountArea;

const useCartAmountAreaStates = (info: CartAmountIndicatorInfo, shippingFee?: number) => {
  const formatCourseTotalPrice = useAmount(info.courseTotalPrice);
  const formatSystemCommissionAmount = useAmount(info.systemCommissionTotalPrice);
  const formatShippingFee = useAmount(shippingFee ?? 0);
  const totalWithShipping = shippingFee !== undefined ? info.totalAmount + shippingFee : info.totalAmount;
  const formatTotalPrice = useAmount(totalWithShipping);
  const discountDataList: DiscountData[] = useMemo(
    () =>
      info.actualDiscounts.map((discount) => ({
        itemName: `${discount.name}(${discount.coupon_code})`,
        formatAmount: discount.amount.toLocaleString(),
      })),
    [info.actualDiscounts]
  );
  return { formatCourseTotalPrice, formatSystemCommissionAmount, formatShippingFee, discountDataList, formatTotalPrice };
};

type DiscountData = {
  itemName: string;
  formatAmount: string;
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const AmountRow = styled.div`
  margin: 5px 0;
  padding: 5px 0;
  border-bottom: solid 1px #aaa;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
const RowHead = styled.div`
  font-size: 14px;
`;
const RowValue = styled.div`
  font-size: 14px;
`;
const Amount = styled.span`
  margin-right: 10px;
`;
const BigAmount = styled(Amount)`
  font-size: 20px;
`;

const SYSTEM_COMMISSION_AMOUNT = 220;
