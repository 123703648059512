import { date, object, string } from "yup";
import { FULLWIDTH_PATTERN, KATAKANA_PATTERN } from "lib/constants";
import { validateEncode } from "lib/validation/validateEncode";

const corporateName = () => string().max(50, "50文字以下にしてください").required("必須項目です");
const corporateDepartment = () => string().max(50, "50文字以下にしてください").required("必須項目です");
const nameSei = () =>
  string()
    .max(10, "10文字以下にしてください")
    .matches(FULLWIDTH_PATTERN, { message: "全角文字のみ有効です" })
    .required("必須項目です")
    .test("encoding", "不正な文字が含まれてます。", async function (value) {
      return validateEncode(this, value);
    });
const nameMei = () =>
  string()
    .max(10, "10文字以下にしてください")
    .matches(FULLWIDTH_PATTERN, { message: "全角文字のみ有効です" })
    .required("必須項目です")
    .test("encoding", "不正な文字が含まれてます。", async function (value) {
      return validateEncode(this, value);
    });
const kanaSei = () =>
  string().max(10, "10文字以下にしてください").matches(KATAKANA_PATTERN, { message: "全角カタカナのみ有効です" }).required("必須項目です");
const kanaMei = () =>
  string().max(10, "10文字以下にしてください").matches(KATAKANA_PATTERN, { message: "全角カタカナのみ有効です" }).required("必須項目です");
const phoneNumber = () =>
  string()
    .required("必須項目です")
    .matches(/^\d{10,11}$/, "電話番号は10~11桁の数値で入力してください");
const zipCode = () =>
  string()
    .required("必須項目です")
    .matches(/^\d{7}$/, "郵便番号は7桁の数値で入力してください");
const prefectures = () =>
  string()
    .max(10, "10文字以下にしてください")
    .required("必須項目です")
    .test("encoding", "不正な文字が含まれてます。", async function (value) {
      return validateEncode(this, value);
    });
const city = () =>
  string()
    .max(50, "50文字以下にしてください")
    .required("必須項目です")
    .test("encoding", "不正な文字が含まれてます。", async function (value) {
      return validateEncode(this, value);
    });
const houseNumber = () =>
  string()
    .max(50, "50文字以下にしてください")
    .test("encoding", "不正な文字が含まれてます。", async function (value) {
      return validateEncode(this, value);
    });
const buildingName = () =>
  string()
    .max(100, "100文字以下にしてください")
    .test("encoding", "不正な文字が含まれてます。", async function (value) {
      return validateEncode(this, value);
    });
const gender = () => string()
  .matches(/(male|female)/, { message: "男か女を選択してください" })
  .required("必須項目です")
  .defined()
const birthday = () => date()
  .min("1900-01-01", "1900年以降で入力してください。")
  .max(new Date().toISOString().slice(0, 10), "本日以前で入力してください。")
  .required("必須項目です")
  .defined()

export const addressSchema = object().shape({
  name_sei: nameSei(),
  name_mei: nameMei(),
  kana_sei: kanaSei(),
  kana_mei: kanaMei(),
  phone_number: phoneNumber(),
  zip_code: zipCode(),
  prefectures: prefectures(),
  city: city(),
  house_number: houseNumber(),
  building_name: buildingName(),
});

export const residenceSchema = object().shape({
  name_sei: nameSei(),
  name_mei: nameMei(),
  kana_sei: kanaSei(),
  kana_mei: kanaMei(),
  phone_number: phoneNumber(),
  zip_code: zipCode(),
  prefectures: prefectures(),
  city: city(),
  house_number: houseNumber(),
  building_name: buildingName(),
  gender: gender(),
  birthday: birthday(),
});

export const corporateAddressSchema = object().shape({
  corporate_name: corporateName(),
  corporate_department: corporateDepartment(),
  name_sei: nameSei(),
  name_mei: nameMei(),
  kana_sei: kanaSei(),
  kana_mei: kanaMei(),
  phone_number: phoneNumber(),
  zip_code: zipCode(),
  prefectures: prefectures(),
  city: city(),
  house_number: houseNumber(),
  building_name: buildingName(),
});
