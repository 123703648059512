import { useMemo } from "react";

/**
 * @param isHometownTax ふるさと納税である場合のみtrue
 * @param onestopDeliverable プロジェクトがワンストップ特例申請書送付可能な場合のみtrue
 * @param defaultOnestopFlag 会員のワンストップ特例申請書送付のデフォルト設定
 * @return ワンストップ特例申請書送付要否のフォーム初期値
 */
export const useInitialOnestopFlag = (
  isHometownTax: boolean,
  onestopDeliverable: boolean,
  defaultOnestopFlag: boolean | undefined | null
): boolean | null => {
  return useMemo(() => {
    if (isHometownTax) {
      if (onestopDeliverable) {
        return defaultOnestopFlag != false;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [isHometownTax, onestopDeliverable, defaultOnestopFlag]);
};
