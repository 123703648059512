import React, { FC } from "react";
import { Button, FormControl } from "@material-ui/core";
import { errorToast } from "lib/toast";
import { Address, CorporateAddress, Residence } from "interfaces/front/business";

export const SearchAddressButton: FC<{
  values: Residence | Address | CorporateAddress;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}> = ({ values, setFieldValue }) => {
  return (
    <FormControl fullWidth margin="normal">
      <Button
        onClick={async (): Promise<void> => {
          const result = await fetch(`/client/api/address/${values.zip_code}`);
          const body = await result.json();
          if (body.results) {
            const [address] = body.results;
            setFieldValue("prefectures", address.address1);
            setFieldValue("city", address.address2);
            setFieldValue("house_number", address.address3);
          } else {
            errorToast("入力した郵便番号から住所が見つかりませんでした。");
          }
        }}
        color="primary"
        variant="outlined"
        style={{ width: "80%", margin: "auto 10%" }}
      >
        住所を検索
      </Button>
    </FormControl>
  );
};
