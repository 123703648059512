import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import ResolutionImg from "../image/ResolutionImg";
import { imagePath } from "../image/util";

const Result: FC<HTMLAttributes<HTMLDivElement> & { amount?: string; ambiguous?: boolean }> = ({ amount, ambiguous = false, ...props }) => {
  return (
    <Root {...props}>
      <GeneralContainer>
        <StyledImage pc={"/calculation/mascot-2-pc.png"} sp={"/calculation/mascot-2-sp.png"} loading="lazy" />
        <Box>
          <Headline>あなたが寄附できる限度額</Headline>
          <AmountText>
            <span>約</span>
            <Amount>{amount || " - "}</Amount>
            <span>円</span>
          </AmountText>
        </Box>
      </GeneralContainer>
      {ambiguous && <AmbiguousText>子供の有無や年齢により少なくなる場合があります。</AmbiguousText>}
    </Root>
  );
};
export default Result;

const Root = styled.div``;
const GeneralContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
`;
const StyledImage = styled(ResolutionImg)`
  @media screen and (max-width: 374px) {
    display: none;
  }
  @media screen and (max-width: 960px) {
    margin-right: 5px;
    left: calc(50% - 190px);
    width: 56px;
    height: 71px;
  }
  @media screen and (min-width: 961px) {
    position: absolute;
    bottom: 0;
    left: calc(50% - 350px);
    width: 113px;
    height: 142px;
  }
`;
const Box = styled.div`
  position: relative;
  @media screen and (max-width: 960px) {
    background: url(${imagePath("/calculation/result-box-sp.png")}) no-repeat;
    background-size: 240px 84px;
    width: 240px;
    height: 84px;
  }
  @media screen and (min-width: 961px) {
    background: url(${imagePath("/calculation/result-box-pc.png")}) no-repeat;
    background-size: 450px 150px;
    width: 450px;
    height: 150px;
  }
`;
const Headline = styled.div`
  color: #fff;
  text-align: center;
  position: absolute;
  width: 100%;
  @media screen and (max-width: 960px) {
    top: 10px;
    font-size: 1em;
  }
  @media screen and (min-width: 961px) {
    top: 16px;
    font-size: 1.4em;
  }
`;
const AmountText = styled.div`
  text-align: center;
  position: absolute;
  width: 100%;

  @media screen and (max-width: 960px) {
    bottom: 10px;
    font-size: 1.2em;
  }
  @media screen and (min-width: 961px) {
    bottom: 26px;
    font-size: 1.8em;
  }
`;
const Amount = styled.strong`
  margin: 0 2px;
  font-size: 1.6em;
`;

const AmbiguousText = styled.div`
  margin: 5px;
  font-size: 0.8em;
  text-align: center;
`;
