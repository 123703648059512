import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { UnitPriceAmount, UnitPriceRoot, UnitPriceUnit } from "components/front/layout/content/CourseListCss";

export const UnitPrice: FC<{ price: number }> = ({ price }) => {
  const formatPrice = useMemo(() => price.toLocaleString(), []);
  return (
    <Root>
      <Amount>{formatPrice}</Amount>
      <Unit>円</Unit>
    </Root>
  );
};

const Root = styled.p`
  ${UnitPriceRoot};
`;
const Amount = styled.span`
  ${UnitPriceAmount};
`;
const Unit = styled.span`
  ${UnitPriceUnit};
`;
