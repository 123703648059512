import { NextPage } from "next";
import { ComponentType, createContext, useContext, useEffect, useState } from "react";
import { RealtimeTippingComment, TippingComment } from "interfaces/front/business";
import { FrontProjectService } from "../service/client/FrontProjectService";
import { useFrontAuthContext } from "./withFrontAuth";

type TippingCommentContextType = {
  tippingComment: RealtimeTippingComment | undefined;
  setRealtimeCommentInterval: (newValue: number) => void;
  setTippingComment: (tippingComment: RealtimeTippingComment) => void;
};

const TippingCommentContext = createContext<TippingCommentContextType>({
  tippingComment: undefined,
  setRealtimeCommentInterval: () => {
    /* do nothing */
  },
  setTippingComment: () => {
    /* do nothing */
  },
});

export const useRealtimeTippingCommentContext = (): TippingCommentContextType => {
  return useContext(TippingCommentContext);
};

export const useRealtimeTippingComment = (slug: string): TippingCommentContextType => {
  const [tippingComment, setTippingComment] = useState<RealtimeTippingComment>();
  const [interval, setInterval] = useState<number>();
  const member = useFrontAuthContext();
  useEffect(() => {
    let intervalId: number;
    if (interval) {
      intervalId = window.setInterval(() => {
        (async () => {
          const result = await new FrontProjectService().getRealtimeTippingComment(slug);
          if (result.isSuccess()) {
            const { realtime_comment, next_interval } = result.value;
            if (realtime_comment && member?.nickname !== realtime_comment?.commenter_nickname) {
              // 会員自身のアニエールでないかつ前回と同じアニエールでない場合のみ更新
              setTippingComment(realtime_comment);
            }
            if (interval !== next_interval) {
              setInterval(next_interval);
            }
          }
        })();
      }, interval * 1000);
    }
    return () => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, [interval, member]);
  return { tippingComment, setRealtimeCommentInterval: setInterval, setTippingComment };
};

export function withRealtimeTippingComment<P extends { slug: string }>(ComposedComponent: NextPage<P>): ComponentType<P> {
  function Wrapper(props: P): JSX.Element {
    const realtimeCommentContext = useRealtimeTippingComment(props.slug);
    return (
      <TippingCommentContext.Provider value={realtimeCommentContext}>
        <ComposedComponent {...props} />
      </TippingCommentContext.Provider>
    );
  }

  return Wrapper;
}
