import React, { FC, useState } from "react";
import styled from "styled-components";
import { Form, Formik } from "formik";
import { ComplexSimulatorParameter, FrontHometownTaxService } from "../../../../../lib/service/client/FrontHometownTaxService";
import { useAmount } from "lib/hooks/useAmount";
import { errorToast } from "lib/toast";
import { number, object } from "yup";
import FieldBox from "./FieldBox";
import Result from "./Result";
import { SubmitButton } from "./SubmitButton";
import AmountField from "./form/AmountField";
import SpouseField from "./form/SpouseField";
import DependantsField from "./form/DependantsField";
import HandicappedField from "./form/HandicappedField";
import WidowField from "./form/WidowField";
import HelpDialog from "./HelpDialog";

export type ComplexLimitSimulatorProps = Record<string, never>;
const ComplexLimitSimulator: FC<ComplexLimitSimulatorProps> = () => {
  const [donationLimitAmount, setDonationLimitAmount] = useState<number>();
  const donationLimitAmountFormat = useAmount(donationLimitAmount);
  const [dialogAttributes, setDialogAttributes] = useState<{ open: boolean; taxLabel?: string; helpImage?: string }>({
    open: false,
    taxLabel: undefined,
    helpImage: undefined,
  });
  const closeDialog = () => setDialogAttributes({ ...dialogAttributes, open: false });
  const openDialog = (taxLabel: string, helpImage: string) => {
    setDialogAttributes({ open: true, taxLabel: taxLabel, helpImage: helpImage });
  };
  return (
    <>
      <Formik<ComplexSimulatorParameter>
        validateOnMount
        initialValues={defaultValue}
        validationSchema={object().shape({
          income: amountRule.required("必須項目です。"),
          family: object().shape({
            spouse: object().shape({
              income: amountRule,
            }),
          }),
          conditions: object().shape({
            social_insurance: amountRule,
            life_insurance: amountRule,
            medical_insurance: amountRule,
            earthquake_insurance: amountRule,
            residential_loan: amountRule,
            small_enterprise_relief: amountRule,
          }),
        })}
        onSubmit={async (values) => {
          const service = new FrontHometownTaxService();
          const result = await service.calculateLimit(values);
          if (result.isSuccess()) {
            setDonationLimitAmount(result.value.donation_limit_amount);
          } else {
            errorToast("寄附限度額が計算できませんでした。");
          }
        }}
      >
        {({ isValid }) => (
          <Form>
            <div>
              <StyledFieldBox subject="収入">
                <StyledAmountInput label="給与収入" name="income" onHelp={() => openDialog("支払金額", "源泉徴収票_支払金額.png")} />
              </StyledFieldBox>
              <StyledFieldBox subject="家族構成">
                <StyledSpouseField />
                <StyledDependantsField />
                <StyledWidowField />
                <StyledHandicappedField />
              </StyledFieldBox>
              <StyledFieldBox subject="控除">
                <StyledAmountInput
                  label="社会保険料等の金額"
                  name="conditions.social_insurance"
                  onHelp={() => openDialog("社会保険料等の金額", "源泉徴収票_社会保険料.png")}
                />
                <StyledAmountInput
                  label="生命保険料の控除額"
                  name="conditions.life_insurance"
                  onHelp={() => openDialog("生命保険料の控除額", "源泉徴収票_生命保険料.png")}
                />
                <StyledAmountInput
                  label="地震保険料の控除額"
                  name="conditions.earthquake_insurance"
                  onHelp={() => openDialog("地震保険料の控除額", "源泉徴収票_地震保険料.png")}
                />
                <StyledAmountInput label="医療費控除の金額" name="conditions.medical_insurance" />
                <StyledAmountInput
                  label="住宅借入金等特別控除額"
                  name="conditions.residential_loan"
                  onHelp={() => openDialog("住宅借入等特別控除の額", "源泉徴収票_住宅借入等特別控除.png")}
                />
                <StyledAmountInput label="小規模企業共済等掛金の金額" name="conditions.small_enterprise_relief" />
              </StyledFieldBox>
              <StyledSubmitButton type="submit" disabled={!isValid}>
                {isValid ? "計算する" : "給与収入を入力してください"}
              </StyledSubmitButton>
              {donationLimitAmountFormat && <StyleResult amount={donationLimitAmountFormat} />}
            </div>
          </Form>
        )}
      </Formik>
      <HelpDialog {...dialogAttributes} close={closeDialog} />
    </>
  );
};
export default ComplexLimitSimulator;

const StyledFieldBox = styled(FieldBox)`
  margin-bottom: 40px;
  @media screen and (max-width: 960px) {
    font-size: 13px;
  }
  @media screen and (min-width: 961px) {
    flex-basis: calc(50% - 7.5px);
    flex-grow: 0;
  }
`;
const StyledAmountInput = styled(AmountField)`
  @media screen and (max-width: 960px) {
    margin: 5px 0;
  }
  @media screen and (min-width: 961px) {
    margin: 5px 0 5px 20px;
  }
`;
const StyledSpouseField = styled(SpouseField)`
  margin-bottom: 10px;
`;
const StyledDependantsField = styled(DependantsField)`
  margin-bottom: 10px;
`;
const StyledWidowField = styled(WidowField)`
  margin-bottom: 10px;
`;
const StyledHandicappedField = styled(HandicappedField)`
  margin-bottom: 10px;
`;
const StyledSubmitButton = styled(SubmitButton)`
  display: block;
  margin: 20px auto;
`;
const StyleResult = styled(Result)`
  margin-top: 40px;
`;

const defaultHandicapped: typeof defaultValue.family.handicapped = {
  general: 0,
  special_separated: 0,
  special_together: 0,
};
const defaultDependants: typeof defaultValue.family.dependants = {
  lte_15: 0,
  between_16_and_18: 0,
  between_19_and_22: 0,
  between_23_and_69: 0,
  gte_70_relatives: 0,
  gte_70_others: 0,
};

const emptySpouse: typeof defaultValue.family.spouse = {
  age: "",
  income: "",
};
const defaultValue: ComplexSimulatorParameter = {
  income: "",
  family: {
    spouse: emptySpouse,
    dependants: defaultDependants,
    widow: "",
    handicapped: defaultHandicapped,
  },
  conditions: {
    social_insurance: "",
    life_insurance: "",
    medical_insurance: "",
    earthquake_insurance: "",
    residential_loan: "",
    small_enterprise_relief: "",
  },
  support: {
    spouse_flag: "なし",
    dependants_flag: "なし",
    widow_flag: "非該当",
    handicapped_flag: "なし",
  },
};

const amountRule = number()
  .transform((o, v) => parseFloat(v.replace(/,/g, "")))
  .typeError("数値を入力してください")
  .lessThan(100000000, "申し訳ありません。1億円以上は対応しておりません。");
