import { Dialog, DialogActions } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { FormikProps } from "formik";
import { OnestopDeliverable } from "interfaces/front/business";
import { useActivation } from "lib/hooks/useActivation";
import { useAmount } from "lib/hooks/useAmount";
import { MemberTippingCommentHelper } from "lib/hooks/useMemberTippingComment";
import { AnialeFormHometownTaxData } from "lib/service/client/FrontOrderService";
import React, { FC, useContext, useEffect } from "react";
import { AaAmountSelectField } from "components/front/layout/aniale/AaAmountSelectField";
import { AaFrontTextField } from "components/front/layout/aniale/AaFrontTextField";
import { Loading } from "components/front/layout/Loading";
import OnestopFlagField from "components/front/layout/OnestopFlagField";
import { AaResidenceCard } from "components/front/order/recipient/RecipientCard";
import ResidenceDialog from "components/front/order/recipient/ResidenceDialog";
import { PaymentMethodSelectField } from "components/front/aniale/PaymentMethodSelectField";
import { FieldLabel, FormDialogContent, NormalButton, StyledIconButton, SubmitButton } from "../Styles";
import AvatarSurpriseArea from "components/front/aniale/AvatarSurpriseArea";
import styled from "styled-components";
import { PaymentSetting } from "interfaces/front/page/anialeForm";
import EnhancedEcommerceContext from "lib/contexts/EnhancedEcommerceContext";
import { Aniale } from "interfaces/front/page/project";

const AnialeFormHometownTaxDialog: FC<{
  formikProps: FormikProps<AnialeFormHometownTaxData>;
  projectImageUrl: string;
  onestopDeliverable: OnestopDeliverable;
  aniale: Aniale;
  memberTippingCommentHelper: MemberTippingCommentHelper;
  isOpen: boolean;
  close: () => void;
  onSubmit: () => void;
  payment: PaymentSetting;
}> = ({
  formikProps: { values, errors, isSubmitting, setFieldValue, isValid },
  projectImageUrl,
  onestopDeliverable,
  aniale,
  memberTippingCommentHelper,
  isOpen,
  close,
  onSubmit,
  payment,
}) => {
  const { triggerAnialeFormEvent, triggerPaymentEvent, triggerRecipientEvent } = useContext(EnhancedEcommerceContext.Aniale);
  const currentAmount = useAmount(memberTippingCommentHelper.tippingComment?.commenter_donation_amount_reserved);
  const [recipientOpened, openRecipient, closeRecipient] = useActivation(false);
  useEffect(() => {
    if (isOpen) triggerAnialeFormEvent().catch(console.error);
  }, [isOpen]);
  const openRecipientSafely = () => {
    if (!isSubmitting) {
      openRecipient();
      triggerRecipientEvent().catch(console.error);
    }
  };
  const onFormSubmit = async () => {
    triggerPaymentEvent(values.payment.method).catch(console.error);
    await onSubmit();
  };
  return (
    <>
      <Dialog open={isOpen} fullWidth scroll="body" onClose={close}>
        <div>
          <HeroImage src={projectImageUrl} alt="アニエールプロジェクト画像" />
          <StyledIconButton onClick={close}>
            <Close viewBox={"0 0 24 24"} />
          </StyledIconButton>
        </div>
        <FormDialogContent>
          <FieldLabel>金額</FieldLabel>
          <AaAmountSelectField name="amount" tippingSetting={aniale.tipping_setting} />
          {currentAmount && (
            <CurrentAmountArea>
              <span>現在のアニエール総額:</span>
              <CurrentAmountValue>{currentAmount}</CurrentAmountValue>
              <span>円</span>
            </CurrentAmountArea>
          )}
        </FormDialogContent>
        <AvatarSurpriseArea
          conditions={aniale.tipping_amount_conditions}
          amount={values.amount}
          currentAmount={memberTippingCommentHelper.tippingComment?.commenter_donation_amount_reserved}
        />
        <FormDialogContent>
          <FieldLabel>アニエールコメント</FieldLabel>
          <AaFrontTextField name="comment" rows={3} />
        </FormDialogContent>
        <FormDialogContent>
          <FieldLabel>お届け先(住民票)</FieldLabel>
          {errors.recipient ? (
            <>
              <NormalButton onClick={openRecipientSafely}>住民票情報を入力する</NormalButton>
              <RecipientNotice>住民票情報を入力してください。</RecipientNotice>
            </>
          ) : values.recipient ? (
            <AaResidenceCard residence={values.recipient} onEdit={openRecipientSafely} />
          ) : (
            <></>
          )}
          {onestopDeliverable && (
            <OnestopFlagArea>
              <OnestopFlagField name="onestopFlag" />
            </OnestopFlagArea>
          )}
        </FormDialogContent>
        <FormDialogContent>
          <FieldLabel>お支払い方法</FieldLabel>
          <PaymentMethodSelectField name="payment.method" payment={payment} />
        </FormDialogContent>
        <DialogActions>
          <SubmitButton onClick={onFormSubmit} disabled={isSubmitting || !isValid}>
            {isSubmitting ? <Loading color="black" size="24px" /> : values.payment?.method === "credit_card_reuse" ? "お支払する" : "次へ"}
          </SubmitButton>
        </DialogActions>
      </Dialog>
      <ResidenceDialog
        initialValue={values.recipient}
        isOpen={recipientOpened}
        courseIds={[aniale.id]}
        closeHandle={(residence) => {
          if (residence) {
            setFieldValue("recipient", residence);
          }
          closeRecipient();
        }}
      />
    </>
  );
};
export default AnialeFormHometownTaxDialog;

const HeroImage = styled.img`
  width: 100%;
  height: 100%;
`;
const CurrentAmountArea = styled.div`
  margin: 10px 0 0;
  font-size: 12px;
  width: 100%;
`;
const CurrentAmountValue = styled.span`
  margin: 0 5px;
`;
const OnestopFlagArea = styled.div`
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 10px;
`;
const RecipientNotice = styled.div`
  font-size: 12px;
  color: red;
  margin: 10px 0;
`;
