import React, { FC } from "react";
import Link from "next/link";
import styled from "styled-components";

export type Props = {
  existsStickyContent?: boolean;
};
const Footer: FC<Props> = (props: Props) => {
  return (
    <Root existsStickyContent={props.existsStickyContent}>
      <Content>
        <Nav>
          <NavLogo>
            <a href={process.env.LANDING_POINT as string}>
              <img src="/img/header_logo.png" width="194" height="38" alt="" />
            </a>
          </NavLogo>
          <LinKGroupList>
            <NavList>
              <NavListItem href="https://animefund.zendesk.com/hc/ja/categories/900000170203">お知らせ</NavListItem>
              <NavListItem href="https://animefund.zendesk.com/hc/ja">よくある質問</NavListItem>
              <NavListItem href="https://animefund.zendesk.com/hc/ja/categories/900000172886">ふるさと納税のよくある質問</NavListItem>
              <NavListItem href="https://animefund.zendesk.com/hc/ja/requests/new">お問い合わせ</NavListItem>
            </NavList>
            <NavList>
              <Link href="/term">
                <NavListItem>利用規約</NavListItem>
              </Link>
              <Link href="/privacy">
                <NavListItem>プライバシーポリシー</NavListItem>
              </Link>
              <Link href="/legal">
                <NavListItem>特定商取引法に基づく表記</NavListItem>
              </Link>
              <Link href="/corporate">
                <NavListItem>会社概要</NavListItem>
              </Link>
            </NavList>
          </LinKGroupList>
        </Nav>
        <LinkArea>
          <Block>
            <LinkText>アニメを盛り上げましょう。</LinkText>
            <LinkList>
              <LinkListItem href="https://twitter.com/animefund" target="_blank" rel="noreferrer">
                <img src="/img/footer_link--tw.png" width="29" height="24" alt="" loading="lazy" />
              </LinkListItem>
              <LinkListItem
                href="https://www.facebook.com/%E3%82%A2%E3%83%8B%E3%83%A1%E3%83%95%E3%82%A1%E3%83%B3%E3%83%89-104054028143011"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/img/footer_link--fb.png" width="25" height="25" alt="" loading="lazy" />
              </LinkListItem>
              <LinkListItem href="https://www.youtube.com/channel/UCbNmrHcooD2k1cAWhqkSWiw" target="_blank" rel="noreferrer">
                <img src="/img/footer_link--yt.png" width="28" height="20" alt="" loading="lazy" />
              </LinkListItem>
              <LinkListItem
                href="https://www.instagram.com/animefund/?fbclid=IwAR2LlV9Or8jSqR-Rz0c0ZLo9PLlqpqaWz0L9p0nvwrxk6xgPXHKrb0Lnv08"
                target="_blank"
              >
                <img src="/img/footer_link--ig.png" width="25" height="25" alt="" loading="lazy" />
              </LinkListItem>
            </LinkList>
          </Block>
        </LinkArea>
      </Content>
      <CopyRight>
        <p>© AnimeFund inc.</p>
      </CopyRight>
    </Root>
  );
};

export default Footer;

const Root = styled.footer<{ existsStickyContent?: boolean }>`
  width: 100%;
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 960px) {
    height: auto;
    background-image: url(/img/footer_bg--sp.png);
    padding-top: 21px;
    padding-bottom: ${({ existsStickyContent }) => (existsStickyContent ? "82px" : "12px")};
  }
  @media screen and (min-width: 961px) {
    height: 284px;
    background-image: url(/img/footer_bg.png);
    padding-top: 27px;
    padding-bottom: 10px;
  }
`;
const Content = styled.div`
  width: 960px;
  margin: 0 auto 50px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
`;
const LinKGroupList = styled.div`
  display: flex;
`;
const Nav = styled.div`
  @media screen and (max-width: 960px) {
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid #fff;
    margin-bottom: 30px;
  }
  @media screen and (min-width: 961px) {
    width: 400px;
  }
`;
const NavList = styled.div`
  @media screen and (max-width: 960px) {
    width: 50%;
    padding: 0 8px;
  }
  @media screen and (min-width: 961px) {
    width: 200px;
  }
`;
const NavLogo = styled.div`
  @media screen and (max-width: 960px) {
    margin-bottom: 20px;
    width: 100%;
  }
  @media screen and (min-width: 961px) {
    margin-bottom: 28px;
  }
`;
const NavListItem = styled.a`
  display: block;
  width: auto;
  color: #fff;
  font-size: 14px;
  transition: 0.4s;
  cursor: pointer;

  @media screen and (max-width: 960px) {
    font-size: 12px;
    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }

  @media screen and (min-width: 961px) {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    :hover {
      color: #ff0000;
    }
  }
`;
const LinkArea = styled.div`
  width: 224px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
const Block = styled.div`
  @media screen and (max-width: 960px) {
    margin: 0 auto;
  }
`;
const LinkText = styled.div`
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-style: italic;
  @media screen and (max-width: 960px) {
    text-align: center;
    margin-bottom: 17px;
    transform-origin: bottom center;
  }
  @media screen and (min-width: 961px) {
    text-align: right;
    margin-bottom: 27px;
    transform-origin: right center;
  }
`;
const LinkList = styled.div`
  width: 220px;
  height: 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LinkListItem = styled.a`
  cursor: pointer;
  @media screen and (min-width: 961px) {
    :hover {
      cursor: pointer;

      img {
        filter: drop-shadow(3px 3px 3px red);
      }
    }
  }

  img {
    transition: 0.4s;
  }
`;
const CopyRight = styled.div`
  width: 100%;
  overflow: hidden;
  p {
    transform-origin: center;
    text-align: center;
    font-size: 11px;
    color: #fff;
  }
`;
