import { BaseClientService, ErrorV2, Result } from "lib/service/BaseClientService";
import { Discount, OrderFormData } from "interfaces/front/page/orderForm";
import { QueryCart } from "interfaces/front/business";

export class FrontOrderFormService extends BaseClientService {
  url: string;

  constructor() {
    super();
    this.url = this.API_BASE_URL + "/api/front/order_form_page";
  }

  async getForm(input: OrderFormGetInput): Promise<Result<OrderFormData, ErrorV2>> {
    const body = JSON.stringify(input);
    return this.doPost(`${this.url}/form`, body);
  }

  async getCouponApplicable(input: CouponApplicableInput): Promise<Result<CouponApplicableOutput, ErrorV2>> {
    const body = JSON.stringify(input);
    return this.doPost(`${this.url}/coupon_applicable`, body);
  }

  async getDeliverable(input: OrderFormDeliverableInput): Promise<Result<OrderFormDeliverableOutput, ErrorV2>> {
    const body = JSON.stringify(input);
    return this.doPost(`${this.url}/deliverable`, body);
  }
}

export interface OrderFormGetInput {
  cart: QueryCart;
}

export interface OrderFormDeliverableInput {
  course_ids: number[];
  recipient: {
    zip_code: string;
    prefecture: string;
    city: string;
  };
}

export interface OrderFormDeliverableOutput {
  undeliverable_text: string | null;
}

export interface CouponApplicableInput {
  project_id: number;
  coupon_code: string;
}

export interface CouponApplicableOutput {
  discount: Discount;
}
