import { TestContext, ValidationError } from "yup";
import { extractUnconvertableChar } from "../emoji";

export const validateEncode = async (
  context: TestContext,
  value: string | undefined | null
): Promise<boolean | ValidationError> => {
  if (!value) return true;
  const invalidChars = await extractUnconvertableChar(value as string, "CP932");
  if (invalidChars) {
    return context.createError({
      message: `「${invalidChars.join(" ")}」は入力できません。`,
    });
  } else {
    return true;
  }
};
