export type ProjectCore = {
  id: string;
  code: string;
  recruit_method: RecruitMethod;
  service_type: ProjectServiceType;
  goal_amount: number | null;
  goal_amount_flag: boolean;
  support_start_at: string;
  support_end_at: string;
  onestop_deliverable: OnestopDeliverable;
  delivery_options_flag: boolean;
  delivery_options: {
    method: string;
    shipping_fee_flag: boolean;
  }[];
  pickup_instruction: string;
} & ProjectTotalization;
export type Project = ProjectCore & {
  content: Content;
};
export type ProjectTotalization = {
  order_count: number | null;
  support_amount: number | null;
  support_amount_reserved: number | null;
};

export type ExhibitionProject = ProjectCore & {
  content: ExhibitionContent;
  monument_flag: boolean;
  marketing_content: MarketingContent;
  activities: ProjectActivity[];
};

export type ProjectActivity = {
  announce_at: string;
  title: string;
  content: string;
  prioritizes: boolean;
};

export type OnestopDeliverable = boolean | null;

export type RecruitMethod = "all_in" | "all_or_nothing";
export type ProjectServiceType = "crowd_funding" | "hometown_tax" | "corp_hometown_tax" | "shopping";
export type CourseServiceType = "crowd_funding" | "hometown_tax" | "corp_hometown_tax" | "shopping";

export type Content = {
  title: string;
  summary: string;
  main_content_images: ImageUrl[];
  key_visual_sp_image: ImageUrl | null;
} & TwitterHashTags;

export type TwitterHashTags = {
  twitter_hashtag1: string | null;
  twitter_hashtag2: string | null;
};

export type ExhibitionContent = Content & {
  greeting: string;
  return_intro: string;
  return_chart: string;
  return_chart_title: string;
  novelty_intro: string;
  faq_list: Faq[];
  story_title: string;
  story: string;
  credit_title: string;
  credit: string;
  municipality_about: string;
  copyright_notation: string;
  homepage_url: string;
  instagram_url: string;
  twitter_url: string;
  facebook_url: string;
  movie_url: string;
  sanctuary_title: string;
  sanctuary_text: string;
  course_list_design: CourseListDesign;
  banners: Banner[]
  corporate_logo: string;
};

export type MarketingContent = {
  seo_title: string;
  seo_description: string;
  seo_keywords: string;
  og_title: string;
  og_description: string;
}

export interface Faq {
  q: string;
  a: string;
}

export interface Banner {
  image: string;
  link: string;
}

export type CourseListDesign = "not_display" | "one_column" | "two_column"

export interface ImageUrl {
  id: string;
  url: string;
}

export type Course = RewardCourse | DonationCourse | Aniale;

export function isReward(course: Pick<Course, "type">): course is RewardCourse {
  return course.type === "reward";
}

export function isDonation(course: Pick<Course, "type">): course is DonationCourse | Aniale {
  return course.type === "donation";
}

export function isAniale(course: { is_aniale: boolean }): course is Aniale {
  return course.is_aniale
}

export type ExhibitionRewardCourse = RewardCourse & {
  return_images: ReturnImage[];
  delivery_property: DeliveryProperty;
};
export type ReturnImage = {
  url: string;
};

export type RewardCourse = {
  id: number;
  type: "reward";
  name: string;
  content: string;
  price: number;
  system_commission_flag: boolean;
  service_type: CourseServiceType;
  course_returns: CourseReturn[];
  course_options: CourseOption[];
  course_image: ImageUrl;
  order_count: number | null;
  restricted: boolean;
} & Stock &
  Delivery &
  CourseTerm;

export type CourseTerm = {
  available_at: string | null;
  available_at_note: string;
  expired_at: string | null;
  expired_at_note: string;
};

export type Delivery =
  | {
      delivery_flag: true;
      delivery_note: string;
    }
  | {
      delivery_flag: false;
      delivery_note: "";
    };

export type DeliveryProperty = {
  ng_isolated_island: boolean;
  ng_hokkaido: boolean;
  ng_okinawa: boolean;
}

export type Stock =
  | {
      stock_flag: true;
      stock_quantity: number;
      remained_quantity: number;
    }
  | {
      stock_flag: false;
      stock_quantity: null;
      remained_quantity: null;
    };

export interface CourseReturn {
  id: number;
  catalog_name: string;
  quantity: number;
}

export interface Aniale {
  id: number;
  type: "donation";
  is_aniale: boolean;
  name: string;
  content: "",
  stock_flag: boolean;
  stock_quantity: number | null;
  delivery_note: string;
  delivery_flag: boolean;
  system_commission_flag: boolean;
  service_type: CourseServiceType;
  course_image: CourseImageUrl;
  order_count: number | null;
  course_options: CourseOption[];
  tipping_setting: TippingSetting;
  tipping_amount_conditions: TippingAmountCondition[];
}

export interface DonationCourse {
  id: number;
  type: "donation";
  is_aniale: boolean;
  name: string;
  content: string;
  stock_flag: false;
  stock_quantity: null;
  delivery_note: string;
  delivery_flag: boolean;
  system_commission_flag: boolean;
  service_type: CourseServiceType;
  course_image: CourseImageUrl;
  order_count: number | null;
  course_options: CourseOption[];
  tipping_setting: TippingSetting;
  tipping_amount_conditions: TippingAmountCondition[];
}

export interface CourseImageUrl {
  id?: string;
  url: string;
}

export interface TippingSetting {
  prepared_choice1: number;
  prepared_choice2: number;
  prepared_choice3: number;
}

export interface TippingAmountCondition {
  id: number;
  threshold_amount: number;
  avatar_url: string;
}

export interface CourseOption {
  id: string;
  question: string;
  input_type: InputType;
  selection: string[] | null;
}

export type InputType = "text" | "selectbox";

export interface Payment {
  method: string;
  credit_card?: CreditCard;
  cvs?: Cvs;
}

interface CreditCard {
  onetime_token?: {
    token: string;
    token_key: string;
  };
  reuse?: Record<string, never>;
}

interface Cvs {
  cvs_type: CvsType;
  phone_number: string;
}

export interface Order {
  id: number;
  order_type: "support" | "tipping";
  code: string;
  status: OrderStatus;
  download_count: number;
  member: Member;
  order_details: OrderDetail[];
  order_recipient: OrderRecipient | null;
  order_payment: OrderPayment;
  support_comment?: SupportComment;
  support_comment_template: string;
  system_commission: SystemCommission | null;
  order_project_requisite: OrderProjectRequisite | null;
  ordered_at: string;
}

export interface OrderProjectRequisite {
  onestop_flag: OnestopFlag;
}

// true: 希望する, false: 希望しない, null: 選択してない
export type OnestopFlag = boolean | null;

export type OrderStatus = "completed" | "requested" | "canceled";

export interface OrderPayment {
  payment_method: PaymentMethod;
  amount: number;
  cvs_detail?: CvsDetail;
}

export type PaymentMethod = "cvs" | "credit_card" | "bank" | "no_method";
export type FormPaymentMethod = PaymentMethod | "credit_card_reuse";

export type CvsDetail = (SevenElevenDetail | FamilyMartDetail | OtherCvsDetail) & {
  cvs_type: CvsType;
  bill_date: string;
};
export type SevenElevenDetail = {
  slip_number: string;
  slip_url: string;
};
export type FamilyMartDetail = {
  company_code: string;
  order_number: string;
};
export type OtherCvsDetail = {
  receipt_number: string;
  phone_number: string;
};

export type Store = {
  name: CvsType;
  label: string;
};

export type CvsType = "family_mart" | "lawson" | "mini_stop" | "seico_mart" | "seven_eleven";

export type DeliveryOptions ={
  delivery_options: {
    method: string;
    shipping_fee_flag: boolean;
  }[];
  pickup_instruction: string;
}

export type OrderDeliveryMethod = {
  method: string;
  shipping_fee: number | undefined;
}

export type OrderRecipient = {
  type?: "address" | "residence"
  name_sei: string;
  name_mei: string;
  kana_sei: string;
  kana_mei: string;
  phone_number: string;
  zip_code: string;
  prefectures: string;
  city: string;
  house_number: string;
  building_name: string;
  gender: ResidentGender | null;
  birthday: string | null;
};

export type Address = {
  type?: "address"
  name_sei: string;
  name_mei: string;
  kana_sei: string;
  kana_mei: string;
  phone_number: string;
  zip_code: string;
  prefectures: string;
  city: string;
  house_number: string;
  building_name: string;
};

export type Residence = {
  type?: "residence"
  name_sei: string;
  name_mei: string;
  kana_sei: string;
  kana_mei: string;
  phone_number: string;
  zip_code: string;
  prefectures: string;
  city: string;
  house_number: string;
  building_name: string;
  gender: ResidentGender;
  birthday: string;
};

export type CorporateAddress = {
  type?: "corporate_address";
  name_sei: string;
  name_mei: string;
  kana_sei: string;
  kana_mei: string;
  phone_number: string;
  zip_code: string;
  prefectures: string;
  city: string;
  house_number: string;
  building_name: string;
  corporate_name: string;
  corporate_department: string;
}

export type ResidentGender = "male" | "female";

export interface SystemCommission {
  amount: number;
}

export interface OrderDetail {
  id: number;
  course_id: string;
  course_name: string;
  price: number;
  quantity: number;
  delivery_note: string;
  order_course_options: OrderCourseOption[];
  service_type: CourseServiceType;
  course?: Course;
}

export type RequiredOrderDetail = OrderDetail & {
  course: Course;
};

export interface OrderCourseOption {
  id: number;
  question: string;
  input_value: string;
}

export interface SupportComment {
  id: number;
  commenter_nickname: string;
  comment: string;
}

export interface TippingComment {
  id: number;
  commenter_nickname: string;
  commenter_avatar_url: string | null;
  commenter_donation_amount?: number;
  commenter_donation_amount_reserved?: number;
  comment: string;
  created_at: string;
}

export type RealtimeTippingComment = {
  id: number;
  commenter_nickname: string;
  tipping_amount: number;
  comment: string;
};

export interface Member {
  nickname: string;
  email: string;
  gender: Gender;
  birthday: string;
  subscribes_mail_magazine: boolean;
  can_tweet: boolean;
  avatar: MemberAvatar | null;
  onestop_flag: HopeOnestopDefault;
  member_bank: MemberBank | null;
}

export type HopeOnestopDefault = boolean;

type Gender = "male" | "female" | "unanswered";

export interface MemberAvatar {
  avatar_url: string;
}

export interface MemberBank {
  bank: string;
  branch: string;
  account_type: string;
  number: string;
  holder: string;
}

export type MessageThread = {
  id: number;
  topic_type: "order" | "contact";
  project_title: string;
  service_type: string;
  project_image_url: string;
  goal_amount: number | null;
  support_amount: number | null;
  support_end_at: string;
  course_names: string[];
  unread_count: number;
};

export interface UsedCreditCard {
  masked_card_number: string;
  card_brand_image_url: string;
}

export interface ServerUsedCreditCard {
  masked_card_number: string;
  brand: string;
}

export type QueryCart = QueryCartItem[];
// course_idとquantityにおいてnullは不正なクエリ前提
export type QueryCartItem = {
  course_id: number;
  quantity: number;
  price?: number;
};

export interface MonumentName {
  label: string;
  mine: boolean;
}

export interface Avatar {
  id: number;
  url: string;
}

export interface SbpaymentConfig {
  merchant_id: string;
  service_id: string;
}
