import { useDeviceType } from "lib/util";
import { Ref, useMemo, useRef } from "react";
import { RewardCourse } from "interfaces/front/page/project";

const useRewardCourseToc = <T extends HTMLElement>(areaName: string, rewardCourses: RewardCourse[]): [CourseAnchor[], Ref<T>] => {
  const scrollRoot = useRef<T>(null);
  const { pc } = useDeviceType();
  const anchors = useMemo(() => {
    if (!rewardCourses) return [];
    const onClickToc = (pageAnchor: string) => {
      const target = document.getElementById(`${areaName}_${pageAnchor}`);
      if (!target) return;
      if (scrollRoot.current) {
        const relativeRoot = scrollRoot.current;
        relativeRoot.scrollTo({ behavior: "smooth", top: target.offsetTop - relativeRoot.offsetTop });
      } else {
        const headerOffset = pc ? 108 : 70;
        window.scrollTo({ behavior: "smooth", top: target.getBoundingClientRect().top + window.scrollY - headerOffset });
      }
    };
    return rewardCourses
      .filter((course) => course.page_anchor)
      .map((course) => ({
        label: course.page_anchor,
        handler: () => onClickToc(course.page_anchor),
      }));
  }, [rewardCourses, pc]);
  return [anchors, scrollRoot];
};
export default useRewardCourseToc;

export type CourseAnchor = {
  label: string;
  handler: () => void;
};
