import { useMemo } from "react";
import { CartItem, Discount } from "interfaces/front/page/orderForm";

const useCartAmountInfo = (cartItems: CartItem[], systemCommissionAmount: number, discounts: Discount[]): CartAmountInfo => {
  return useMemo(() => {
    const courseTotalPrice = getCourseTotalPrice(cartItems);
    const systemCommissionTargetQuantity = getSystemCommissionTargetQuantity(cartItems);
    const systemCommissionTotalPrice = systemCommissionAmount * systemCommissionTargetQuantity;
    const actualDiscounts = getActualDiscount(discounts, courseTotalPrice);
    const discountPrice = actualDiscounts.map((discount) => discount.amount).reduce((prev, curr) => prev + curr, 0);
    const totalAmount = courseTotalPrice + systemCommissionTotalPrice - discountPrice;
    return {
      courseTotalPrice,
      systemCommissionTotalPrice,
      systemCommissionTargetQuantity,
      actualDiscounts,
      totalAmount,
      isNeededPayment: totalAmount > 0,
    };
  }, [cartItems, systemCommissionAmount, discounts]);
};
export default useCartAmountInfo;

const getCourseTotalPrice = (cartItems: CartItem[]): number => {
  return cartItems.map((cartItem) => cartItem.price * cartItem.quantity).reduce((prev, next) => prev + next, 0);
};
const getSystemCommissionTargetQuantity = (cartItems: CartItem[]): number => {
  return cartItems
    .filter((cartItem) => cartItem.course.system_commission_flag)
    .map((cartItem) => cartItem.quantity)
    .reduce((prev, next) => prev + next, 0);
};
const getActualDiscount = (discounts: Discount[], courseTotalPrice: number): Discount[] => {
  const result = discounts.reduce(
    (previous, discount) => {
      const subtractable = Math.max(previous.subtractable - discount.amount, 0);
      const actualDiscountAmount = subtractable == 0 ? previous.subtractable : discount.amount;
      const actualDiscount = { ...discount, amount: actualDiscountAmount };
      return {
        subtractable,
        actualDiscounts: [...previous.actualDiscounts, actualDiscount],
      };
    },
    { subtractable: courseTotalPrice, actualDiscounts: [] as Discount[] }
  );
  return result.actualDiscounts;
};

export interface CartAmountInfo {
  courseTotalPrice: number;
  systemCommissionTotalPrice: number;
  systemCommissionTargetQuantity: number;
  actualDiscounts: Discount[];
  totalAmount: number;
  isNeededPayment: boolean;
}
