import React, { FC, HTMLAttributes, memo } from "react";
import { FastField, FieldProps } from "formik";
import styled from "styled-components";

type Props = HTMLAttributes<HTMLElement> & { name: string; label?: string; disabled?: boolean; fullWidth?: boolean; rows?: number };
const TextareaField: FC<Props> = ({ name, label, fullWidth = false, disabled = false, rows = 10, ...props }) => {
  return (
    <FastField name={name}>
      {({ field, meta }: FieldProps<string>) => (
        <Root $fullWidth={fullWidth} {...props}>
          {label && <Label htmlFor={props.id}>{label}</Label>}
          <Textarea disabled={disabled} {...field} $fullWidth={fullWidth} rows={rows} />
          {meta.touched && meta.error && <Error>{meta.error}</Error>}
        </Root>
      )}
    </FastField>
  );
};
export default memo(TextareaField);

const Root = styled.div<{ $fullWidth: boolean }>`
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "inherit")};
`;
const Label = styled.label`
  display: block;
`;
const Textarea = styled.textarea<{ $fullWidth: boolean }>`
  resize: vertical;
  padding: 5px;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "200px")};
`;
const Error = styled.div`
  color: #d02e1d;
`;
