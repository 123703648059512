import React, { FC, HTMLAttributes } from "react";
import { FastField, FieldProps } from "formik";
import styled from "styled-components";
import NumberFormat, { NumberFormatValues } from "react-number-format";

type Props = HTMLAttributes<HTMLElement> & { id?: string; name: string; label: string; disabled?: boolean; fullWidth?: boolean };
const AmountField: FC<Props> = ({ id, name, label, fullWidth = false, disabled = false, ...props }) => {
  return (
    <FastField name={name}>
      {({ field, meta, form }: FieldProps<NumberFormatValues | number | null>) => (
        <Root $fullWidth={fullWidth} {...props}>
          <Label htmlFor={id}>{label}</Label>
          <Input
            type="tel"
            id={id}
            disabled={disabled}
            name={field.name}
            value={isNumberFormatValues(field.value) ? field.value?.formattedValue : field.value}
            onValueChange={(value: NumberFormatValues) => form.setFieldValue(name, value.floatValue)}
            thousandSeparator
            suffix="円"
            $fullWidth={fullWidth}
          />
          {meta.touched && meta.error && <Error>{meta.error}</Error>}
        </Root>
      )}
    </FastField>
  );
};
export default AmountField;

const isNumberFormatValues = (value: NumberFormatValues | number | null): value is NumberFormatValues => {
  return value != null && value.hasOwnProperty("formattedValue");
};

const Root = styled.div<{ $fullWidth: boolean }>`
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "inherit")};
`;
const Label = styled.label`
  display: block;
`;
const Input = styled(NumberFormat)<{ $fullWidth: boolean }>`
  padding: 5px;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "200px")};
`;
const Error = styled.div`
  color: #d02e1d;
`;
