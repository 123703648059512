import React, { FC, useRef, useState } from "react";
import { useField } from "formik";
import { Button, FormHelperText } from "@material-ui/core";
import styled from "styled-components";
import { Discount } from "interfaces/front/page/orderForm";
import TextInput from "components/front/layout/TextInput";
import { FrontOrderFormService } from "lib/service/client/page/FrontOrderFormService";

const DiscountsField: FC<{ name: string; projectId: string; onAdd: (discount: Discount) => void }> = ({ name, projectId, onAdd }) => {
  const [, , helper] = useField<Discount[]>(name);
  const couponCodeInput = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState<string>();
  const handleApply = async () => {
    if (!couponCodeInput.current?.value) return;
    const couponCode = couponCodeInput.current.value;
    const result = await new FrontOrderFormService().getCouponApplicable({ project_id: Number(projectId), coupon_code: couponCode });
    if (result.isSuccess()) {
      // const newDiscounts = new Array(...field.value);
      // const existingDiscountIndex = newDiscounts.findIndex((discount) => discount.coupon_code === couponCode);
      // if (existingDiscountIndex != -1) {
      //   newDiscounts.splice(existingDiscountIndex, 1, result.value.discount);
      // } else {
      //   newDiscounts.splice(newDiscounts.length, 0, result.value.discount);
      // }
      // helper.setValue(newDiscounts);
      couponCodeInput.current.value = "";
      await setErrorMessage(undefined);
      await helper.setValue([result.value.discount]); // 今は1つ前提
      await onAdd(result.value.discount);
    } else {
      await setErrorMessage(result.value.message);
    }
  };
  return (
    <Root>
      <Label>クーポン入力</Label>
      <InputRow>
        <StyledTextInput ref={couponCodeInput} $error={!!errorMessage} />
        <Button variant="outlined" onClick={handleApply}>
          適用
        </Button>
      </InputRow>
      <FormHelperText error>{errorMessage}</FormHelperText>
    </Root>
  );
};
export default DiscountsField;

const Root = styled.div``;
const Label = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
`;
const InputRow = styled.div`
  display: flex;
  align-items: center;
  max-width: 400px;
`;
const StyledTextInput = styled(TextInput)`
  flex-grow: 1;
  margin-right: 5px;
`;
