import { toast } from 'react-toastify';

export const infoToast = (message: string): void => {
  toast.info(message);
}

export const warningToast =  (message: string): void => {
  toast.warning(message, {
    autoClose: false,
  });
}

export const errorToast = (message: string): void => {
  toast.error(message, {
    autoClose: false,
  });
}
