import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import React, { FC, HTMLAttributes } from "react";

const CvsRestriction: FC<HTMLAttributes<HTMLDivElement> & { totalAmount: number }> = ({ totalAmount, ...props }) => {
  const classes = useStyles();
  if (totalAmount < 300_000) return <></>;
  return (
    <div {...props} className={clsx(classes.infoBlock, props.className)}>
      合計金額が30万円以上の場合はコンビニでお支払いすることはできません。
    </div>
  );
};
export default CvsRestriction;

const useStyles = makeStyles(() => ({
  infoBlock: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "silver",
    padding: "10px",
  },
}));
