import React, { FC, HTMLAttributes, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import BottomSlide from "../../../../motion/BottomSlide";
import styled from "styled-components";
import FamilyRadio from "./FamilyRadio";
import WidowSelect from "./WidowSelect";

const WidowField: FC<HTMLAttributes<HTMLDivElement>> = ({ ...props }) => {
  const formik = useFormikContext();
  const field = formik.getFieldProps("support.widow_flag");
  const isWidow = useMemo(() => field.value === "該当", [field.value]);
  useEffect(() => {
    if (isWidow) {
      formik.setFieldValue("family.widow", "ひとり親男");
    } else {
      formik.setFieldValue("family.widow", "");
    }
  }, [isWidow]);
  return (
    <Root {...props}>
      <FamilyRadio name={field.name} label="寡婦 / ひとり親" options={["非該当", "該当"]} />
      <BottomSlide in={isWidow}>
        <StyledWidowSelect name="family.widow" options={WIDOW_OPTIONS} />
      </BottomSlide>
    </Root>
  );
};
export default WidowField;

const WIDOW_OPTIONS = [
  { label: "ひとり親(男)", value: "ひとり親男" },
  { label: "ひとり親(女)", value: "ひとり親女" },
  { label: "寡婦", value: "寡婦" },
];

const Root = styled.div``;
const StyledWidowSelect = styled(WidowSelect)`
  padding: 5px 0;
`;
