import { createSlice } from "@reduxjs/toolkit";

export interface PageState {
  showsDeductionFlowDetail: boolean;
  deductionStepId: string;
}

export const initialState: PageState = {
  showsDeductionFlowDetail: false,
  deductionStepId: "deduction_flow_step3",
};

export const pageSlice = createSlice({
  name: "index",
  initialState,
  reducers: {
    onToggleDeductionFlowDetail: (state) => {
      state.showsDeductionFlowDetail = !state.showsDeductionFlowDetail;
    },
    onOpenDeductionFlowDetail: (state) => {
      state.showsDeductionFlowDetail = true;
    },
  },
});

export const { onToggleDeductionFlowDetail, onOpenDeductionFlowDetail } = pageSlice.actions;

export const animefurusatoReducer = pageSlice.reducer;
