import * as Yup from "yup";
import { isOnamaeOption } from "../sydm2";
import { extractUnconvertableChar } from "../emoji";

const HANKAKU_PATTERN = /^[\x20-\x7E\xA1-\xDF]+$/;

export const orderCourseOptionSchema = () => {
  return Yup.object()
    .shape({
      id: Yup.string().defined(),
      value: Yup.string()
        .required("必須項目です。")
        .defined()
        .when("question", {
          is: (question: string) => isOnamaeOption(question),
          then: Yup.string().test(
            "value",
            "全角10文字、半角16文字以内で入力してください。（全角と半角が混在する場合も10文字となります。）",
            function test(value) {
              if (!value) return true;
              if (value.match(HANKAKU_PATTERN)) {
                return value.length <= 16;
              } else {
                return value.length <= 10;
              }
            }
          ),
          otherwise: Yup.string().max(50, "50文字以下にしてください"),
        })
        .test("encoding", "不正な文字が含まれてます。", async function (value) {
          if (!value) return true;
          const invalidChars = await extractUnconvertableChar(value as string, "CP932");
          if (invalidChars) {
            return this.createError({ message: `「${invalidChars.toString()}」は入力できません。` });
          } else {
            return true;
          }
        }),
    })
    .defined();
};
