import React, { FC } from "react";

type Props = {
  text: string;
};
export const LineBreaker: FC<Props> = (props: Props) => {
  return (
    <>
      {props.text.split("\n").map((str, index) => (
        <React.Fragment key={index}>
          {str}
          <br />
        </React.Fragment>
      ))}
    </>
  );
};
