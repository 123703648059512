import { Button, FormControl, makeStyles } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { FULLWIDTH_PATTERN } from "lib/constants";
import { OrganizerEditInput } from "lib/service/client/AdminOrganizersService";
import React from "react";
import { object, string } from "yup";

export type Props = {
  initialValue?: OrganizerEditInput;
  onSubmit: (query: OrganizerEditInput) => void;
};
const OrganizerForm: React.FC<Props> = (props: Props) => {
  const {
    initialValue = {
      applicant_name: "",
      company_name: "",
      phone_number: "",
      organization_name: "",
      email: "",
      auth_type: "committee",
    },
  } = props;
  const classes = useStyles();
  return (
    <Formik<OrganizerEditInput>
      initialValues={initialValue}
      validationSchema={object().shape({
        applicant_name: string()
          .max(50, "50文字以下にしてください")
          .matches(FULLWIDTH_PATTERN, "全角文字のみ有効です")
          .required("必須項目です"),
        company_name: string().max(50, "50文字以下にしてください").required("必須項目です"),
        organization_name: string().max(50, "50文字以下にしてください").required("必須項目です"),
        phone_number: string()
          .matches(/^\d{10,11}$/, "電話番号の形式に誤りがあります")
          .required("必須項目です"),
        email: string().max(100, "100文字以下にしてください").email("メールアドレスの形式にしてください").required("必須項目です"),
        auth_type: string().required("必須項目です").default("committee"),
      })}
      onSubmit={(values, { setSubmitting }): void => {
        props.onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }): React.ReactNode => (
        <Form className={classes.form}>
          <FormControl className={classes.formControl}>
            <Field label="申込者名" component={TextField} name="applicant_name" />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field label="会社名" component={TextField} name="company_name" />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field label="電話番号" component={TextField} name="phone_number" />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field label="組織名" component={TextField} name="organization_name" />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Field label="メールアドレス" component={TextField} name="email" type="email" />
          </FormControl>
          <Button disabled={isSubmitting} type="submit" variant="contained">
            保存
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default OrganizerForm;

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginTop: theme.spacing(1),
  },
  form: {
    textAlign: "center",
    margin: theme.spacing(5),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  submit: {
    textAlign: "left",
    margin: theme.spacing(1),
  },
  gridList: {
    width: "100%",
    height: 200,
  },
}));
