import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { useActivation } from "lib/hooks/useActivation";
import React, { FC, useEffect, useMemo } from "react";
import YoutubeFrame from "../layout/YoutubeFrame";
import styled from "styled-components";

const PlayableMovie: FC<{ movieUrl: string; isActive: boolean; size?: string }> = ({ movieUrl, isActive, size }) => {
  const [movieReady, prepareMovie, hideMovie] = useActivation(false);
  const youtubeId = useMemo(() => (movieUrl !== "" ? extractYoutubeId(movieUrl) : undefined), [movieUrl]);
  useEffect(() => {
    if (!isActive) {
      hideMovie();
    }
  }, [isActive, hideMovie]);
  return youtubeId ? (
    <Root $isMovie={movieReady}>
      {movieReady ? (
        <StyledYoutubeFrame id={youtubeId} />
      ) : (
        <PlayButtonArea>
          <PlayButton $size={size} onClick={prepareMovie} />
        </PlayButtonArea>
      )}
    </Root>
  ) : (
    <></>
  );
};
export default PlayableMovie;

const StyledYoutubeFrame = styled(YoutubeFrame)`
  height: 100%; //IE11対策(aspect-ratioに変更予定)
`;
const Root = styled.div<{ $isMovie: boolean }>`
  position: absolute;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  pointer-events: ${({ $isMovie }) => ($isMovie ? "auto" : "none")};
`;

const PlayButtonArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PlayButton = styled(PlayCircleOutlineIcon)<{ $size?: string }>`
  && {
    min-width: 60px;
    min-height: 60px;
    width: ${({ $size }) => $size ?? "30%"};
    height: ${({ $size }) => $size ?? "30%"};
    opacity: 0.8;
    color: white;
    pointer-events: auto;
  }
`;

const extractYoutubeId = (url: string): string => {
  if (url.startsWith("https://www.youtube.com/watch")) {
    const searchParams = new URL(url).searchParams;
    return searchParams.get("v") || "";
  } else if (url.startsWith("https://youtu.be")) {
    return url.replace("https://youtu.be/", "");
  } else {
    return "";
  }
};
