import React, { FC, HTMLAttributes, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { TippingSetting } from "interfaces/front/business";
import { InputBase, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const AaAmountSelectField: FC<{
  name: string;
  tippingSetting: TippingSetting;
}> = ({ name, tippingSetting }) => {
  const classes = useStyles();
  const choices: { name: string; value: number }[] = useMemo(() => {
    return [
      { name: "choice1", value: tippingSetting.prepared_choice1 },
      { name: "choice2", value: tippingSetting.prepared_choice2 },
      { name: "choice3", value: tippingSetting.prepared_choice3 },
    ];
  }, [tippingSetting]);
  const formik = useFormikContext();
  const field = formik.getFieldProps(name);
  const meta = formik.getFieldMeta(name);
  const [selected, setSelected] = useState<string>();
  const onClick = (newSelected: string) => {
    if (formik.isSubmitting) return;
    if (newSelected === "custom" && selected !== "custom") {
      formik.setFieldValue(name, "");
      formik.setFieldTouched(name, false);
    } else if (newSelected !== "custom") {
      formik.setFieldValue(name, choices.find((choice) => choice.name === newSelected)?.value);
    }
    setSelected(newSelected);
  };
  useEffect(() => {
    onClick("");
    formik.setFieldTouched(name, false);
  }, []);
  return (
    <div className={classes.root}>
      <div className={classes.choiceAmountArea}>
        {choices.map((choice, index) => (
          <SelectableDiv
            key={index}
            selected={selected === choice.name}
            className={classes.amountItem}
            onClick={() => onClick(choice.name)}
          >
            <span>{choice.value}</span>
            <span className={classes.yen}>円</span>
          </SelectableDiv>
        ))}
      </div>
      <SelectableDiv className={classes.customAmountArea} selected={selected === "custom"} onClick={() => onClick("custom")}>
        <StyledInputBase
          fullWidth
          type="number"
          placeholder="任意"
          disabled={formik.isSubmitting}
          inputProps={{
            ...field,
            value: selected === "custom" ? field.value : "",
          }}
        />
        <span className={classes.yen}>円</span>
      </SelectableDiv>
      {meta.touched && meta.error && <p className={classes.errorText}>{meta.error}</p>}
    </div>
  );
};

const SelectableDiv: FC<HTMLAttributes<HTMLDivElement> & { selected: boolean }> = ({ children, selected, ...props }) => {
  const style = useMemo(() => {
    return selected ? { border: `solid 1px #e3ac00`, color: "#e3ac00" } : { border: "solid 1px #CCC" };
  }, [selected]);
  return (
    <div {...props} style={style}>
      {children}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    margin: "0",
  },
  yen: {
    fontSize: "10px",
    marginLeft: "2px",
  },
  choiceAmountArea: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "5px 0",
  },
  amountItem: {
    border: "solid 1px #CCC",
    padding: "10px",
    borderRadius: "5px",
    marginRight: "5px",
    cursor: "pointer",
  },
  customAmountArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    border: "solid 1px #CCC",
    borderRadius: "5px",
    margin: "5px 0",
    padding: "10px",
    boxShadow: "0 2px 2px -2px rgba(0,0,0,0.7) inset",
  },
  errorText: {
    margin: "5px 0",
    color: "#d70000",
    fontSize: "12px",
  },
}));
const StyledInputBase = withStyles(() => ({
  input: {
    padding: "0",
    fontSize: "14px",
  },
}))(InputBase);
