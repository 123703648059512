import { useAmount2 } from "lib/hooks/useAmount2";
import React, { FC, useMemo } from "react";
import styled from "styled-components";

export type Props = { order: Order; isShopping: boolean };
const OrderAmountArea: FC<Props> = ({ order, isShopping }) => {
  const { formatSupportAmount, formatSystemCommissionAmount, formatTotalAmount, formatShippingFee, systemCommissionTargetQuantity, orderDiscounts } =
    useOrderAmountAreaStates(order);
  return (
    <Root>
      <AmountRow>
        <div>{!isShopping ? "コース金額合計" : "合計金額"}</div>
        <div>
          <span>{formatSupportAmount}</span>
          <Yen>円</Yen>
        </div>
      </AmountRow>
      {order.order_delivery_method?.shipping_fee !== undefined && order.order_delivery_method.shipping_fee > 0 && (
        <AmountRow>
          <div>送料</div>
          <div>
            <span>{formatShippingFee}</span>
            <Yen>円</Yen>
          </div>
        </AmountRow>
      )}
      {formatSystemCommissionAmount && (
        <AmountRow>
          <div>システム利用料(220円 × {systemCommissionTargetQuantity}個)</div>
          <div>
            <span>{formatSystemCommissionAmount}</span>
            <Yen>円</Yen>
          </div>
        </AmountRow>
      )}
      {orderDiscounts.map((discount, index) => (
        <AmountRow key={index}>
          <div>{discount.name}</div>
          <div>
            <span>-{discount.amount}</span>
            <Yen>円</Yen>
          </div>
        </AmountRow>
      ))}
      <AmountRow>
        <div>合計</div>
        <div>
          <Total>{formatTotalAmount}</Total>
          <Yen>円</Yen>
        </div>
      </AmountRow>
    </Root>
  );
};
export default OrderAmountArea;

const useOrderAmountAreaStates = (order: Order) => {
  const supportAmount = useMemo(
    () => order.order_details.map((orderDetail) => orderDetail.price * orderDetail.quantity).reduce((prev, next) => prev + next, 0),
    [order]
  );
  const formatSupportAmount = useAmount2(supportAmount);
  const formatSystemCommissionAmount = useAmount2(order.system_commission?.amount);
  const systemCommissionTargetQuantity = useMemo(
    () => order.order_details.filter((orderDetail) => orderDetail.course.system_commission_flag).length,
    [order]
  );
  const shippingFee = order.order_delivery_method?.shipping_fee ?? 0;
  const formatShippingFee = useAmount2(shippingFee);
  const orderDiscounts = order.order_discounts.map((orderDiscount) => ({ name: orderDiscount.discount_name, amount: orderDiscount.discount_amount.toLocaleString() }))
  const formatTotalAmount = useAmount2(order.order_payment.amount);
  return { formatSupportAmount, formatSystemCommissionAmount, systemCommissionTargetQuantity, formatShippingFee, formatTotalAmount, orderDiscounts };
};

const Root = styled.div`
  @media screen and (max-width: 960px) {
    font-size: 14px;
  }
  @media screen and (min-width: 961px) and (max-width: 1280px) {
    font-size: 15px;
  }
  @media screen and (min-width: 1281px) {
    font-size: 16px;
  }
`;
const AmountRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: solid 1px #aaa;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
const Total = styled.span`
  @media screen and (max-width: 960px) {
    font-size: 20px;
  }
  @media screen and (min-width: 961px) {
    font-size: 22px;
  }
`;
const Yen = styled.span`
  margin-left: 10px;
`;

interface Order {
  order_details: {
    price: number;
    quantity: number;
    course: {
      system_commission_flag: boolean
    }
  }[];
  order_discounts: {
    discount_name: string;
    discount_amount: number;
  }[]
  system_commission: {
    amount: number;
  } | null
  order_payment: {
    amount: number
  }
  order_delivery_method: {
    method: string;
    shipping_fee: number | undefined;
  }
}
