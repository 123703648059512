import { useMemo } from "react";

// 最初に計算するほうのuseAmount
export function useAmount2(amount: string | number | undefined): string | undefined {
  return useMemo(() => {
    if (amount !== undefined) {
      return Number(amount).toLocaleString();
    } else {
      return undefined;
    }
  }, [amount]);
}
