import { withStyles } from "@material-ui/styles";
import { TextField as MuiTextField } from "formik-material-ui";

// ボックスの内側に影を落とす
// そのデザインと合うようにラベルのデザインを少々変更する。
export const AaTextField = withStyles(() => ({
  root: {
    "& fieldset": {
      boxShadow: "0 2px 2px -2px rgba(0,0,0,0.7) inset",
    },
    "& .MuiInputLabel-shrink": {
      padding: "0 8px",
      left: "-6px",
      backgroundColor: "white",
    },
  },
}))(MuiTextField);