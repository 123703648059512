import { Swiper, SwiperSlide } from "swiper/react";
import { BorderedImage } from "./Styles";
import React, { FC, HTMLAttributes } from "react";
import { Avatar } from "interfaces/front/business";
import styled from "styled-components";

const AvatarSwiper: FC<HTMLAttributes<HTMLDivElement> & { avatars: Avatar[] }> = ({ avatars, ...props }) => {
  return (
    <Root {...props}>
      {avatars.length > 1 ? (
        <StyledSwiper navigation={avatars.length > 1}>
          {avatars.map((avatar, index) => (
            <SwiperSlide key={index}>
              <BorderedImage src={avatar.url} width="140px" height="140px" />
            </SwiperSlide>
          ))}
        </StyledSwiper>
      ) : avatars.length == 1 ? (
        <BorderedImage src={avatars[0].url} width="140px" height="140px" />
      ) : (
        <></>
      )}
    </Root>
  );
};
export default AvatarSwiper;

const Root = styled.div`
  width: 200px;
`;
const StyledSwiper = styled(Swiper)`
  width: 200px;

  & .swiper-button-prev {
    color: #e3ac00;
    left: 0;

    &:after {
      text-shadow: 0 0 2px black, 0 0 2px black, 0 0 3px black, 0 0 3px black;
      font-size: 20px;
    }
  }

  & .swiper-button-next {
    color: #e3ac00;
    right: 0;

    &:after {
      text-shadow: 0 0 2px black, 0 0 2px black, 0 0 3px black, 0 0 3px black;
      font-size: 20px;
    }
  }
`;
