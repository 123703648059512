import { BaseClientService, ErrorV2, Result, ValidationErrorV2 } from "../BaseClientService";
import { NextRouter } from "next/router";
import { FormikValues } from "formik/dist/types";
import { infoToast } from "../../toast";
import { goErrorPage, goLoginPage } from "../../frontRouting";

export type ServiceError = {
  error: string;
}

export type RequestResetQuery = FormikValues & {
  email: string;
}

export class FrontPasswordService extends BaseClientService {
  url: string
  onAuthError: () => void
  onError: () => void

  constructor(router: NextRouter) {
    super();
    this.url = this.API_BASE_URL + '/api/front/member_passwords';
    this.onAuthError = () => goLoginPage(router)
    this.onError = () => goErrorPage(router)
  }

  async requestReset(query: RequestResetQuery,
                     onSuccess: () => void,
                     onValidationError: (e: ValidationErrorV2) => void,
                     onError: (message: string) => void
  ): Promise<void> {
    const response = await fetch(`${this.url}/request_reset`, {
      credentials: "include",
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(query)
    });
    if (response.ok) {
      onSuccess()
    } else if (response.status === 401) {
      await this.onAuthError()
      infoToast("ログイン有効期限切れになりました。お手数ですが再度ログインをお願いいたします。")
    } else if (response.status === 400) {
      const error = await response.json() as ErrorV2
      await onValidationError(error.validation_error || {})
    } else if (response.status === 404) {
      const error = await response.json() as ErrorV2
      await onError(error.error || "エラーが発生しました。")
    } else {
      // 400エラーの場合はバリデーションエラーにしたい
      await this.onError()
    }
  }

  async reset(token: string, form: object): Promise<Result<void, ServiceError>> {
    const body = JSON.stringify({...form, reset_password_token: token});
    return await this.doPost(`${this.url}/reset`, body)
  }
}
