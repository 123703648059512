import React, { FC } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, styled, withStyles } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Close } from "@material-ui/icons";
import { AaTextField } from "../../layout/aniale/AaTextField";

const StyledFormControl = styled(FormControl)(() => ({
  minWidth: 300,
  width: "100%",
}));

const StyledDialog = withStyles({
  paper: {
    margin: "16px",
  },
  paperFullWidth: {
    width: "calc(100% - 32px)",
  },
  paperScrollPaper: {
    maxHeight: "calc(100% - 32px)",
  },
})(Dialog);

const Schema = Yup.object().shape({
  comment: Yup.string()
    .trim()
    .required("必須項目です")
    .max(100, "100文字までです。"),
});

export interface CommentInput {
  comment: string;
}

export const SupportCommentEditModal: FC<{
  isOpen: boolean;
  closeHandle: (commentInput?: CommentInput) => void;
  initialValue: CommentInput;
}> = (props) => {
  return (
    <StyledDialog
      fullWidth
      onClose={(): void => props.closeHandle(undefined)}
      aria-labelledby="address-dialog-title"
      open={props.isOpen}
    >
      <DialogTitle disableTypography>
        <span>応援コメントの編集</span>
        <IconButton
          onClick={(): void => props.closeHandle(undefined)}
          style={{
            position: "absolute",
            right: "6px",
            top: "0",
            color: "gray",
          }}
        >
          <Close viewBox={"0 0 24 24"} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={props.initialValue}
          validationSchema={Schema}
          onSubmit={(values): void => {
            props.closeHandle(values);
          }}
        >
          {({ isSubmitting }): React.ReactNode => (
            <Form>
              <StyledFormControl>
                <Field
                  component={AaTextField}
                  name="comment"
                  multiline
                  rows={7}
                  inputProps={{ maxLength: 100 }}
                  variant="outlined"
                />
              </StyledFormControl>
              <DialogActions>
                <Button
                  disabled={isSubmitting}
                  variant={"contained"}
                  type="submit"
                  color="primary"
                  fullWidth
                >
                  確定
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  );
};
