import styled from "styled-components";
import { FieldProps } from "formik";
import { Payment } from "interfaces/front/business";
import { PaymentSetting } from "interfaces/front/page/orderForm";
import { useActivation } from "lib/hooks/useActivation";
import React, { FC, useContext, useMemo, useState } from "react";
import ErrorBlock from "components/front/layout/ErrorBlock";
import { SsDialogOpenButton } from "components/front/layout/SsDialogOpenButton";
import CvsRestriction from "components/front/order/literal/CvsRestriction";
import { CreditCardDialog, TokenResponse } from "./CreditCardDialog";
import { CreditCardReuseCard } from "./CreditCardReuseCard";
import { CvsDialog, CvsInput } from "./CvsDialog";
import CreditCardTokenCard from "./CreditCardTokenCard";
import CvsCard from "./CvsCard";
import EnhancedEcommerceContext from "lib/contexts/EnhancedEcommerceContext";
import BankCard from "components/front/order/payment/BankCard";

export type Props = FieldProps<Payment> & {
  totalAmount: number;
  supportEndAt: string;
  payment: PaymentSetting;
};
const PaymentField: FC<Props> = ({ field, payment, form: { touched, errors, setFieldValue }, ...props }) => {
  const [isCardOpen, cardOpen, cardClose] = useActivation(false);
  const [isCvsOpen, cvsOpen, cvsClose] = useActivation(false);
  const [cardTokenResponse, setCardTokenResponse] = useState<TokenResponse | undefined>(undefined);
  const selectedUsedCreditCard = useMemo<boolean>(() => !!field.value?.credit_card?.reuse, [field.value]);
  const [cvsInput, setCvsInput] = useState<CvsInput | undefined>(undefined);
  const { triggerPaymentEvent } = useContext(EnhancedEcommerceContext.OrderForm);
  const onClickCardPayment = () => {
    cardOpen();
    triggerPaymentEvent("credit_card").catch(console.error);
  };
  const onClickCvsPayment = () => {
    cvsOpen();
    triggerPaymentEvent("cvs").catch(console.error);
  };
  const onChangeCardToken = (response: TokenResponse) => {
    setCvsInput(undefined);
    setCardTokenResponse(response);
    const newPayment: Payment = {
      method: "credit_card",
      credit_card: {
        onetime_token: {
          token: response.token,
          token_key: response.tokenKey,
        },
      },
    };
    setFieldValue(field.name, newPayment);
  };
  const onChangeUsedCreditCard = () => {
    setCvsInput(undefined);
    setCardTokenResponse(undefined);
    setFieldValue(field.name, {
      method: "credit_card",
      credit_card: { reuse: {} },
    });
    triggerPaymentEvent("credit_card_reuse").catch(console.error);
  };
  const onChangeBank = () => {
    setCvsInput(undefined);
    setCardTokenResponse(undefined);
    setFieldValue(field.name, { method: "bank" })
    triggerPaymentEvent("bank").catch(console.error);
  }
  const onChangeCvsStore = (cvsInput: CvsInput) => {
    setCardTokenResponse(undefined);
    setCvsInput(cvsInput);
    const newPayment: Payment = {
      method: "cvs",
      cvs: {
        cvs_type: cvsInput.store.name,
        phone_number: cvsInput.phone_number,
      },
    };
    setFieldValue(field.name, newPayment);
  };
  return (
    <Root>
      {payment.can_use_cvs && <CvsRestriction totalAmount={props.totalAmount} />}
      {touched[field.name] && errors[field.name] && <StyledErrorBlock>{errors[field.name]}</StyledErrorBlock>}
      {!field.value && <StyledErrorBlock>決済方法を選択してください。</StyledErrorBlock>}
      {selectedUsedCreditCard && payment.used_credit_card && <CreditCardReuseCard usedCreditCard={payment.used_credit_card} />}
      {cardTokenResponse && <CreditCardTokenCard card={cardTokenResponse} />}
      {cvsInput && <CvsCard cvs={cvsInput} />}
      {field.value?.method === "bank" && <BankCard />}
      {payment.can_use_credit_card_reuse && payment.used_credit_card && (
        <SsDialogOpenButton label={["以前使用した", "クレジットカードを使用する"]} onClick={onChangeUsedCreditCard} margin="20px auto" />
      )}
      {payment.can_use_credit_card && (
        <>
          <SsDialogOpenButton label="クレジットカードを入力する" onClick={onClickCardPayment} margin="20px auto" />
          <CreditCardDialog
            isOpen={isCardOpen}
            close={cardClose}
            onGetToken={onChangeCardToken}
            onCancel={cardClose}
            sbpaymentConfig={payment.sbpayment}
          />
        </>
      )}
      {payment.can_use_cvs && props.totalAmount < 300_000 && (
        <>
          {<SsDialogOpenButton label="コンビニを選択する" onClick={onClickCvsPayment} margin="20px auto" />}
          <CvsDialog
            isOpen={isCvsOpen}
            close={cvsClose}
            onCvsSelected={onChangeCvsStore}
            onCancel={cvsClose}
            stores={payment.available_stores}
          />
        </>
      )}
      {payment.can_use_bank && (
        <SsDialogOpenButton label="銀行振込を選択する" onClick={onChangeBank} margin="20px auto" />
      )}
    </Root>
  );
};
export default PaymentField;

const Root = styled.div`
  & > * {
    @media screen and (max-width: 960px) {
      margin: 10px 0;
    }
    @media screen and (min-width: 961px) {
      margin: 14px 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }
`;
const StyledErrorBlock = styled(ErrorBlock)`
  @media screen and (max-width: 960px) {
    font-size: 12px;
  }
  @media screen and (min-width: 961px) {
    font-size: 14px;
  }
`;
