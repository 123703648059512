import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";

export const CrowdFundingNote: FC<HTMLAttributes<HTMLUListElement>> = ({ ...props }) => {
  return (
    <Root {...props}>
      <TermText>原則として、決済完了後はご自身の都合によるキャンセルを受け付けておりません。</TermText>
      <TermText>
        本プロジェクトに関する詳細説明、トラブル、返金、リターン返品等は、各プロジェクトでのお問い合わせにてご相談ください。
      </TermText>
      <TermText>在庫数に限りのあるリターンは、「応援を確定する」までに「売り切れ」となり確定できない場合があります。</TermText>
      <TermText>
        配送予定月を必ずご確認願います。通販サイトと異なり、応援後のリターンの配送までに数カ月要します。
        <br />
        また、状況によってリターンの配送時期が変動する可能性がございます。ご了承ください。
      </TermText>
    </Root>
  );
};

export const ShoppingNote: FC<HTMLAttributes<HTMLUListElement>> = ({ ...props }) => {
  return (
    <Root {...props}>
      <TermText>原則として、決済完了後はご自身の都合によるキャンセルを受け付けておりません。</TermText>
      <TermText>
        本プロジェクトに関する詳細説明、トラブル、返金、リターン返品等は、各プロジェクトでのお問い合わせにてご相談ください。
      </TermText>
      <TermText>在庫数に限りがありますので、「購入を確定する」までに「売り切れ」となり確定できない場合があります。</TermText>
    </Root>
  );
};

export const HometownTaxNote: FC<HTMLAttributes<HTMLUListElement>> = ({ ...props }) => {
  return (
    <Root {...props}>
      <TermText>原則として、決済完了後はご自身の都合によるキャンセルを受け付けておりません。</TermText>
      <TermText>在庫数に限りのある返礼品は、「応援を確定する」までに「売り切れ」となり確定できない場合があります。</TermText>
      <TermText>
        配送予定月を必ずご確認願います。通販サイトと異なり、寄附後の返礼品の配送までに時間を要します。
        <br />
        また、状況によって返礼品の配送時期が変動する可能性がございます。ご了承ください。
      </TermText>
    </Root>
  );
};

export const CorpHometownTaxNote: FC<HTMLAttributes<HTMLUListElement>> = ({ ...props }) => {
  return (
    <Root {...props}>
      <TermText>応援完了後、該当自治体へ通知が届きますのでご了承ください。</TermText>
      <TermText>振込タイミング、手続きの流れ、メリットなどのご不明点がございましたら応援完了後にお気軽にお問い合わせください。</TermText>
    </Root>
  );
}

const Root = styled.ul``;
const TermText = styled.li`
  line-height: 1.428em;
  margin-bottom: 8px;
  position: relative;
  @media screen and (max-width: 960px) {
    padding-left: 14px;
    font-size: 12px;
  }
  @media screen and (min-width: 961px) {
    padding-left: 17px;
    font-size: 14px;
  }

  &:before {
    background-color: #696969;
    border-radius: 20px;
    content: "";
    height: 8px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 8px;
    @media screen and (max-width: 960px) {
      left: 2px;
      top: 6px;
      width: 6px;
      height: 6px;
    }
    @media screen and (min-width: 961px) {
      left: 2px;
      top: 6px;
      width: 6px;
      height: 6px;
    }

    & a {
      color: #0000ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
