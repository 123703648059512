import { FamilyType } from "../../components/front/lp/animefurusato/calculation/types";

export type EasySimulatorParameter = {
  income: string;
  family: FamilyType;
};

export const calcDonationLimit = (income: string, family: string): number => {
  return DONATION_LIMIT_MAP.find((pattern) => pattern.income === income && pattern.family === family)?.limit || 0;
};

const DONATION_LIMIT_MAP: {
  family: FamilyType;
  income: string;
  limit: number;
}[] = [
  { family: "独身", income: "200万", limit: 15000 },
  { family: "独身", income: "250万", limit: 22000 },
  { family: "独身", income: "300万", limit: 28000 },
  { family: "独身", income: "350万", limit: 34000 },
  { family: "独身", income: "400万", limit: 42000 },
  { family: "独身", income: "450万", limit: 52000 },
  { family: "独身", income: "500万", limit: 61000 },
  { family: "独身", income: "550万", limit: 69000 },
  { family: "独身", income: "600万", limit: 77000 },
  { family: "独身", income: "700万", limit: 108000 },
  { family: "独身", income: "800万", limit: 129000 },
  { family: "独身", income: "900万", limit: 151000 },
  { family: "独身", income: "1000万", limit: 176000 },
  { family: "独身", income: "1500万", limit: 389000 },
  { family: "独身", income: "2000万", limit: 564000 },

  { family: "既婚(配偶者控除なし)", income: "200万", limit: 14000 },
  { family: "既婚(配偶者控除なし)", income: "250万", limit: 21000 },
  { family: "既婚(配偶者控除なし)", income: "300万", limit: 28000 },
  { family: "既婚(配偶者控除なし)", income: "350万", limit: 34000 },
  { family: "既婚(配偶者控除なし)", income: "400万", limit: 42000 },
  { family: "既婚(配偶者控除なし)", income: "450万", limit: 52000 },
  { family: "既婚(配偶者控除なし)", income: "500万", limit: 61000 },
  { family: "既婚(配偶者控除なし)", income: "550万", limit: 69000 },
  { family: "既婚(配偶者控除なし)", income: "600万", limit: 77000 },
  { family: "既婚(配偶者控除なし)", income: "700万", limit: 108000 },
  { family: "既婚(配偶者控除なし)", income: "800万", limit: 129000 },
  { family: "既婚(配偶者控除なし)", income: "900万", limit: 151000 },
  { family: "既婚(配偶者控除なし)", income: "1000万", limit: 176000 },
  { family: "既婚(配偶者控除なし)", income: "1500万", limit: 389000 },
  { family: "既婚(配偶者控除なし)", income: "2000万", limit: 564000 },

  { family: "既婚(配偶者控除あり)", income: "200万", limit: 7000 },
  { family: "既婚(配偶者控除あり)", income: "250万", limit: 13000 },
  { family: "既婚(配偶者控除あり)", income: "300万", limit: 19000 },
  { family: "既婚(配偶者控除あり)", income: "350万", limit: 26000 },
  { family: "既婚(配偶者控除あり)", income: "400万", limit: 33000 },
  { family: "既婚(配偶者控除あり)", income: "450万", limit: 41000 },
  { family: "既婚(配偶者控除あり)", income: "500万", limit: 49000 },
  { family: "既婚(配偶者控除あり)", income: "550万", limit: 60000 },
  { family: "既婚(配偶者控除あり)", income: "600万", limit: 69000 },
  { family: "既婚(配偶者控除あり)", income: "700万", limit: 86000 },
  { family: "既婚(配偶者控除あり)", income: "800万", limit: 120000 },
  { family: "既婚(配偶者控除あり)", income: "900万", limit: 141000 },
  { family: "既婚(配偶者控除あり)", income: "1000万", limit: 166000 },
  { family: "既婚(配偶者控除あり)", income: "1500万", limit: 389000 },
  { family: "既婚(配偶者控除あり)", income: "2000万", limit: 564000 },
];
