import { OrderRecipient, Residence, } from "interfaces/front/business";
import { useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { FrontOrderService, TippingFormOutput } from "lib/service/client/FrontOrderService";
import { useResidenceOrAddress } from "lib/hooks/useOrderRecipient";
import { MemberTippingCommentHelper } from "lib/hooks/useMemberTippingComment";
import { PaymentSetting } from "interfaces/front/page/anialeForm";

// TODO: フォームですべての情報を取得できていない問題
export const useTippingFormInitialValues = (
  isOpen: boolean,
  memberTippingCommentHelper: MemberTippingCommentHelper,
  projectId: number | string
): [Residence | null | undefined, string | undefined, PaymentSetting | undefined] => {
  const router = useRouter();
  const [orderRecipient, setOrderRecipient] = useState<OrderRecipient | null>();
  const [, residence] = useResidenceOrAddress(true, orderRecipient);
  const [paymentSetting, setPaymentSetting] = useState<PaymentSetting>();
  const initialComment = useMemo(
    () =>
      memberTippingCommentHelper.tippingComment === null
        ? "応援しています！がんばってください！"
        : memberTippingCommentHelper.tippingComment?.comment,
    [memberTippingCommentHelper.tippingComment]
  );
  useEffect(() => {
    if (isOpen) {
      (async () => {
        const result = await new FrontOrderService(router).getTippingForm(projectId);
        if (result.isSuccess()) {
          const tippingForm: TippingFormOutput = result.value;
          setOrderRecipient(tippingForm.order_recipient);
          setPaymentSetting(tippingForm.payment);
        }
      })();
      memberTippingCommentHelper.loadIfNeeded();
    }
  }, [isOpen]);
  return [residence, initialComment, paymentSetting];
};
