import { useFormikContext } from "formik";
import { ChangeEvent, useCallback, useMemo } from "react";

const useBirthdayFieldHandler = (
  name: string,
  defaultValue?: string
): {
  years: number[];
  months: number[];
  days: number[];
  year: number;
  month: number;
  day: number;
  onYearChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onMonthChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onDayChange: (e: ChangeEvent<HTMLSelectElement>) => void;
} => {
  const formik = useFormikContext();
  const field = formik.getFieldProps<string>(name);
  const years = useMemo(() => buildArray(1920, new Date().getFullYear()), []);
  const months = useMemo(() => Array.from(new Array(12)).map((_v, i) => ++i), []);
  const { year, month, day, days } = useMemo(() => {
    const date = new Date(field.value || (defaultValue ?? ""));
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const days = buildArray(1, lastDay);
    return { year, month, day, days };
  }, [field.value]);
  const onYearChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>): void => {
      formik.setFieldValue(name, `${e.target.value}/${month}/${day}`);
    },
    [month, day]
  );
  const onMonthChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>): void => {
      // HACK:月が変わる時だけオーバーしたら嫌なので勝手に1日にしちゃう。
      formik.setFieldValue(name, `${year}/${e.target.value}/${1}`);
    },
    [year]
  );
  const onDayChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>): void => {
      formik.setFieldValue(name, `${year}/${month}/${e.target.value}`);
    },
    [year, month]
  );
  return { years, months, days, year, month, day, onYearChange, onMonthChange, onDayChange };
};
export default useBirthdayFieldHandler;

const buildArray = (min: number, max: number): number[] => {
  const options = [];
  for (let value = min; value <= max; value++) {
    options.push(value);
  }
  return options;
};
