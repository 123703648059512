import React, { FC, HTMLAttributes } from "react";
import AlarmIcon from "@material-ui/icons/Alarm";
import { useSupportStartAtFormat } from "../../../lib/hooks/useSupportStartAtFormat";
import styled from "styled-components";

export type Props = HTMLAttributes<HTMLDivElement> & {
  supportStartAt: string;
  textCentered?: boolean;
  isShopping?: boolean;
};
// キャッシュによる時間のズレが発生するため現在日時を使用する処理はサーバ側では処理しない
const StartCountDownArea: FC<Props> = ({ supportStartAt, textCentered, isShopping, ...props }) => {
  const supportStartAtFormat = useSupportStartAtFormat(supportStartAt);
  const startMessage = isShopping ? "販売開始" : "応援受付開始";
  return supportStartAtFormat ? (
    <Root {...props} textCentered={textCentered ?? false}>
      <div style={{ marginRight: "10px" }}>
        <AlarmIcon />
      </div>
      <div>
        <p>{supportStartAtFormat} より{startMessage}</p>
      </div>
    </Root>
  ) : (
    <></>
  );
};
export default StartCountDownArea;

const Root = styled.div<{ textCentered: boolean }>`
  margin-bottom: 20px;
  background-color: #e74c3c;
  color: #fff;
  padding: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: ${({ textCentered }) => (textCentered ? "center" : "flex-start")};
`;
