import React, { FC, useCallback } from "react";
import useCloudFrontImageUrl from "lib/hooks/useCloudFrontImageUrl";
import styled from "styled-components";

const ImageControl: FC<{ image?: string; onRemove: () => void; actionable: boolean }> = ({ image, onRemove, actionable }) => {
  const cfImageUrl = useCloudFrontImageUrl(image);
  const onCopyUrl = useCallback(async () => await writeClipboard(cfImageUrl), [cfImageUrl]);
  const onCopyImgTag = useCallback(async () => await writeClipboard(`<img src="${cfImageUrl}" loading="lazy" />`), [cfImageUrl]);
  return (
    <ImageControlRoot>
      <ImageBox>
        <img src={cfImageUrl} width={150} height={90} />
        {!actionable && (
          <RemoveButton type="button" onClick={onRemove}>
            削除
          </RemoveButton>
        )}
      </ImageBox>
      {actionable && (
        <ActionBox>
          <CopyButton type="button" onClick={onCopyImgTag} title="imgタグをコピー">
            &lt;img /&gt;
          </CopyButton>
          <CopyButton type="button" onClick={onCopyUrl} title="URLをコピー">
            https://
          </CopyButton>
        </ActionBox>
      )}
    </ImageControlRoot>
  );
};
export default ImageControl;

const writeClipboard = async (text: string): Promise<void> => {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text);
  } else {
    alert("このブラウザでは対応していません。システム管理者にお問い合わせください。");
  }
};

const ImageControlRoot = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ImageBox = styled.div`
  position: relative;
  line-height: 1;
`;
const ActionBox = styled.div`
  min-width: 70px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;
const CopyButton = styled.button`
  display: block;
  width: 100%;
  height: 44px;
`;

const RemoveButton = styled.button`
  position: absolute;
  right: 5px;
  bottom: 5px;
  cursor: pointer;
`;
