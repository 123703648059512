import SwiperCore, { Navigation, Pagination, Swiper } from "swiper";
import { RefObject, useEffect } from "react";

SwiperCore.use([Navigation, Pagination]);

const useContentSwiper = (ref: RefObject<HTMLElement>): SwiperLoader => {
  const loadSwiper = async () => {
    const imageHolder = ref.current;
    if (imageHolder) {
      new Swiper(`[${TARGET_AREA_DATA_KEY}='${imageHolder.getAttribute(TARGET_AREA_DATA_KEY)}'] .content-swiper`, {
        loop: true,
        pagination: { el: ".swiper-pagination", clickable: true },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        preloadImages: false,
        lazy: {
          loadPrevNext: true,
        }
      });
    }
  };
  useEffect(() => {
    const imageHolder = ref.current;
    if (!!imageHolder && !imageHolder.hasAttribute(TARGET_AREA_DATA_KEY)) {
      imageHolder.setAttribute(TARGET_AREA_DATA_KEY, randomId());
    }
  }, [ref.current]);
  return loadSwiper;
};
export default useContentSwiper;

const TARGET_AREA_DATA_KEY = "data-content-swiper-area-id";

function randomId() {
  return Math.random().toString(36).replace("0.", "");
}

type SwiperLoader = () => Promise<void>;
