import { DependencyList, useEffect, useState } from "react";

export const useAsyncMemo = <T>(
  fetch: () => Promise<T>,
  dependencyList: DependencyList
): T | undefined => {
  const [state, setState] = useState<T>();
  useEffect(() => {
    let cancel = false;
    (async () => {
      const newState = await fetch();
      if (!cancel) {
        setState(newState);
      }
    })();
    return () => {
      cancel = true;
    };
  }, dependencyList);
  return state;
};
