import { Tab, TabContent, TabList } from "../TabView";
import EasyLimitSimulator from "./EasyLimitSimulator";
import ComplexLimitSimulator from "./ComplexLimitSimulator";
import React, { FC, HTMLAttributes, useMemo, useState } from "react";
import styled from "styled-components";

export type Props = HTMLAttributes<HTMLDivElement>;
const Simulator: FC<Props> = ({ ...props }) => {
  const [simulatorType, setSimulatorType] = useState<"easy" | "complex">("easy");
  const isEasy = useMemo(() => simulatorType === "easy", [simulatorType]);
  const isComplex = useMemo(() => simulatorType === "complex", [simulatorType]);
  const toEasy = () => setSimulatorType("easy");
  const toComplex = () => setSimulatorType("complex");
  return (
    <Root {...props}>
      <TabList>
        <StyledTab active={isEasy} onClick={toEasy}>
          簡易版
        </StyledTab>
        <StyledTab active={isComplex} onClick={toComplex}>
          詳細版
        </StyledTab>
      </TabList>
      <TabContentWrapper>
        <StyledTabContent active={isEasy}>
          <EasyLimitSimulator />
        </StyledTabContent>
        <StyledTabContent active={isComplex}>
          <ComplexLimitSimulator />
        </StyledTabContent>
      </TabContentWrapper>
    </Root>
  );
};
export default Simulator;

const Root = styled.div``;
const StyledTab = styled(Tab)`
  background-color: ${({ active }) => (active ? "#232323" : "#C2C2C2")};
  color: ${({ active }) => (active ? "#FFF" : "#333333")};
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  font-size: 1.2em;
`;
const TabContentWrapper = styled.div`
  color: #1a1a1a;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;
const StyledTabContent = styled(TabContent)`
  border: 2px solid #232323;
  background-color: white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;
