import { BaseClientService, Error, ErrorV2, Result, ValidationErrorV2 } from "../BaseClientService";
import { NextRouter } from "next/dist/next-server/lib/router/router";
import { FormikValues } from "formik";
import { HopeOnestopDefault } from "../../../interfaces/front/business";

export type PutQuery = {
  nickname: string;
  gender: string;
  birthday: string;
  subscribes_mail_magazine: boolean;
  onestop_flag: HopeOnestopDefault;
};

export type PostQuery = {
  email: string;
  nickname: string;
  password: string;
  gender: string;
  birthday: string;
  terms_of_service?: boolean;
};

export type AssistRegisterQuery = FormikValues & {
  email: string;
  nickname: string;
};

export type TemporaryProfile = {
  email: string;
  nickname: string;
};

export interface LoginQuery {
  email: string;
  password: string;
}

export type ConfirmationQuery = {
  confirmation_token: string;
};

export class FrontMemberService extends BaseClientService {
  url: string;

  constructor() {
    super();
    this.url = this.API_BASE_URL + "/api/front/members";
  }

  async post(query: PostQuery): Promise<Result<{ id: string }, Error>> {
    const body = JSON.stringify(query);
    return super.doPost<{ id: string }, Error>(this.url, body);
  }

  async register(
    query: PostQuery,
    router: NextRouter,
    onSuccess: () => void,
    setAuthError: (isAuthError: boolean) => void,
    setValidationError: (error: ValidationErrorV2) => void
  ): Promise<void> {
    const body = JSON.stringify(query);
    const response = await fetch(this.url, {
      credentials: "include",
      method: "POST",
      headers: this.headers,
      body,
    });
    if (response.ok) {
      onSuccess();
    } else if (response.status == 401) {
      await setAuthError(true); // そうそう起こらない
    } else if (response.status === 400) {
      const error = (await response.json()) as ErrorV2;
      await setValidationError(error.validation_error || {});
    } else {
      await router.push("/error");
    }
  }

  async getTemporaryProfile(onSuccess: (profile: TemporaryProfile) => void, onFailure: () => void): Promise<void> {
    const response = await fetch(`${this.url}/temporary_profile`, {
      credentials: "include",
      method: "GET",
      headers: this.headers,
    });
    if (response.ok) {
      const profile = (await response.json()) as TemporaryProfile;
      onSuccess(profile);
    } else {
      onFailure();
    }
  }

  async assistRegister(
    query: AssistRegisterQuery,
    router: NextRouter,
    onSuccess: () => void,
    setAuthError: (isAuthError: boolean) => void,
    setValidationError: (error: ValidationErrorV2) => void
  ): Promise<void> {
    const body = JSON.stringify(query);
    const response = await fetch(`${this.url}/assist_register`, {
      credentials: "include",
      method: "POST",
      headers: this.headers,
      body,
    });
    if (response.ok) {
      onSuccess();
    } else if (response.status == 401) {
      await setAuthError(true); // そうそう起こらない
    } else if (response.status === 400) {
      const error = (await response.json()) as ErrorV2;
      await setValidationError(error.validation_error || {});
    } else {
      await router.push("/error");
    }
  }

  async login(
    query: LoginQuery,
    router: NextRouter,
    setAuthError: (isAuthError: boolean) => void,
    setValidationError: (error: ValidationErrorV2) => void
  ): Promise<void> {
    const body = JSON.stringify(query);
    const loginUrl = `${this.url}/login`;
    const response = await fetch(loginUrl, {
      credentials: "include",
      method: "POST",
      headers: this.headers,
      body,
    });
    if (response.ok) {
      await router.replace(router.query.original ? (router.query.original as string) : (process.env.LANDING_POINT as string));
      await window.scrollTo(0, 0);
    } else if (response.status == 401) {
      await setAuthError(true);
    } else {
      const error = (await response.json()) as ErrorV2;
      await setValidationError(error.validation_error || {});
    }
  }

  async logout(): Promise<Result<void, Error>> {
    const logoutUrl = `${this.url}/logout`;
    return super.doDelete<void, Error>(logoutUrl, "");
  }

  async confirm(query: ConfirmationQuery): Promise<Result<void, ErrorV2>> {
    const body = JSON.stringify(query);
    return super.doPost(`${this.url}/confirm`, body);
  }
}
