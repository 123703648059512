import React, { FC } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { UsedCreditCard } from "../../../../interfaces/front/business";

export const CreditCardReuseCard: FC<{ usedCreditCard: UsedCreditCard }> = ({
  usedCreditCard,
}) => (
  <Card variant="outlined">
    <CardHeader
      disableTypography
      title={<>以前使用したクレジットカードでお支払い</>}
      style={{ fontSize: "16px" }}
    />
    <CardContent>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ margin: "0 10px 10px 0" }}>
          <div style={{ margin: "0 0 10px", fontSize: "12px", color: "gray" }}>
            カード番号
          </div>
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            <img
              src={usedCreditCard.card_brand_image_url}
              width={"44px"}
              style={{ marginRight: "5px" }}
            />
            <div>{usedCreditCard.masked_card_number}</div>
          </div>
        </div>
        {/*<div style={{ margin: "0 10px 10px 0" }}>*/}
        {/*  <div style={{ margin: "0 0 10px", fontSize: "12px", color: "gray" }}>*/}
        {/*    有効期限*/}
        {/*  </div>*/}
        {/*  <div>2025/07</div>*/}
        {/*</div>*/}
      </div>
    </CardContent>
  </Card>
);
