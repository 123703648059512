import styled from "styled-components";

const FieldRow = styled.div`
  display: flex;
  align-items: flex-start;

  & + & {
    margin-top: 20px;
  }

  & > :not(:first-child) {
    margin-left: 20px;
  }
`;
export default FieldRow;
