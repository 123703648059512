import React, { FC, useCallback } from "react";
import { useActivation } from "lib/hooks/useActivation";
import FieldRow from "components/admin/project/layouts/FieldRow";
import styled from "styled-components";

const SwitchableFieldRow: FC<{ children: FC<{ fixMode: boolean; toggle: () => void }> }> = ({ children }) => {
  const [fixed, fix, normalize] = useActivation(false);
  const onToggle = useCallback(() => (fixed ? normalize() : fix()), [fixed]);
  return (
    <FieldRow>
      {fixed ? (
        <FixedFieldRow>{children({ fixMode: fixed, toggle: onToggle })}</FixedFieldRow>
      ) : (
        <FieldRow>{children({ fixMode: fixed, toggle: onToggle })}</FieldRow>
      )}
    </FieldRow>
  );
};
export default SwitchableFieldRow;

const FixedFieldRow = styled(FieldRow)`
  width: calc(100% - 288px);
  border: solid 1px #666666;
  background-color: white;
  padding: 10px;
  position: fixed;
  bottom: 20px;
  right: 24px;
  z-index: 10;
`;
