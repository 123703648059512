import styled from "@material-ui/core/styles/styled";

export const GroupLabel = styled("div")(({ theme }) => ({
  borderLeft: "6px solid #1a1a1a",
  padding: "5px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "18px"
  }
}));
