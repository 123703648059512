import { useEffect, useState } from "react";

export function useAmount(
  amount: string | number | undefined
): string | undefined {
  const [value, setValue] = useState<string>();
  useEffect(() => {
    if (amount !== undefined) {
      setValue(Number(amount).toLocaleString());
    }
  }, [amount]);
  return value;
}
