import { OrderCreateForm } from "interfaces/front/page/orderForm";
import { FormikHelpers } from "formik";
import { FrontOrderService } from "lib/service/client/FrontOrderService";
import { errorToast } from "lib/toast";
import { handleFailure } from "lib/hooks/useServiceFailureGuardData";
import { useCallback } from "react";
import { useRouter } from "next/router";
import { useTriggerNetworkError } from "lib/contexts/NetworkErrorContext";

const useOrderFormOnSubmit = (slug: string) => {
  const router = useRouter();
  const triggerNetworkError = useTriggerNetworkError();
  return useCallback(
    async (values: OrderCreateForm, { setSubmitting, setFieldValue }: Pick<FormikHelpers<OrderCreateForm>, "setSubmitting" | "setFieldValue">): Promise<void> => {
      try {
        const result = await new FrontOrderService(router).order({
          ...values,
          details: values.order_items.flatMap((cartItem) =>
            cartItem.details.map((detail) => ({
              course_id: cartItem.course_id,
              amount: cartItem.price,
              course_options: detail.course_options,
            }))
          ),
        });
        if (result.isSuccess()) {
          await router.push(`/project/${slug}/order/${result.value.order_code}/thanks`).then(() => window.scrollTo(0, 0));
          // TODO: 遷移スクロール対処は https://github.com/vercel/next.js/issues/3249#issuecomment-436568081 をやりたい
          if (!result.value.sent_mail)
            await errorToast("完了メールをお送りすることができませんでした。応援の詳細についてはお手数ですがマイページをご確認ください。");
        } else {
          await handleFailure(result, router);
          if (result.value.order_error?.code === "invalid_coupon") {
            await setFieldValue("discounts", []);
          }
          await setSubmitting(false);
        }
      } catch (e) {
        await triggerNetworkError();
      }
    },
    [slug]
  );
};
export default useOrderFormOnSubmit;
