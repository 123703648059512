import React, { forwardRef, HTMLAttributes } from "react";
import styled, { css } from "styled-components";

const SsMainButton = forwardRef<
  HTMLAnchorElement,
  HTMLAttributes<HTMLAnchorElement> & { shows?: boolean; disabled?: boolean; disabledEternal?: boolean }
>(({ shows = true, disabled = false, disabledEternal = false, children, ...props }, ref) => {
  if (!shows) return <></>;
  return (
    <Root {...props} ref={ref} $disabledEternal={disabledEternal}>
      <UpperBg>
        <UpperBgItem1 />
        <UpperBgItem2 />
        <UpperBgItem3 />
        <UpperBgItem4 />
        <UpperBgItem5 />
        <UpperBgItem6 $disabledEternal={disabledEternal} />
        <UpperBgItem7 />
        <UpperBgItem8 />
      </UpperBg>
      <TextArea $disabled={disabled} $disabledEternal={disabledEternal}>
        {children}
      </TextArea>
      <LowerBg>
        <LowerBgItem1 />
        <LowerBgItem2 />
        <LowerBgItem3 />
        <LowerBgItem4 />
        <LowerBgItem5 />
        <LowerBgItem6 $disabledEternal={disabledEternal} />
        <LowerBgItem7 />
        <LowerBgItem8 />
      </LowerBg>
    </Root>
  );
});
SsMainButton.displayName = "SsMainButton";
export default SsMainButton;

const TextArea = styled.div<{ $disabled: boolean; $disabledEternal: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  text-align: center;
  font-style: italic;
  color: ${({ $disabled, $disabledEternal }) => ($disabledEternal ? "#717171" : $disabled ? "#AAA" : "#000")};
`;
const UpperBg = styled.div`
  width: calc(100% - 6px);
  height: 50%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 6px;
`;
const UpperBgItem1 = styled.div`
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color: #00ff00;
  top: 2px;
  right: 2px;
`;
const UpperBgItem2 = styled.div`
  position: absolute;
  width: calc(100% - 4px);
  height: 100%;
  background-color: #ff0000;
  top: 0;
  left: 0;
`;
const UpperBgItem3 = styled.div`
  position: absolute;
  width: calc(100% - 6px);
  height: calc(100% - 2px);
  background-color: #ffff00;
  top: 2px;
  left: 2px;
`;
const UpperBgItem4 = styled.div`
  position: absolute;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background-color: #0000ff;
  top: 4px;
  right: 0;
`;
const UpperBgItem5 = styled.div`
  position: absolute;
  width: calc(100% - 6px);
  height: calc(100% - 4px);
  top: 4px;
  right: 2px;
  background-color: #00ffff;
`;
const UpperBgItem6 = styled.div<{ $disabledEternal: boolean }>`
  position: absolute;
  width: calc(100% - 8px);
  height: calc(100% - 4px);
  background-color: ${({ $disabledEternal }) => ($disabledEternal ? "#c3c3c3" : "#fff")};
  top: 4px;
  left: 4px;
`;
const UpperBgItem7 = styled.div`
  position: absolute;
  opacity: 0;
  width: 36px;
  height: 2px;
  background-color: #fff;
  top: 14px;
  left: 15px;
`;
const UpperBgItem8 = styled.div`
  position: absolute;
  opacity: 0;
  width: 36px;
  height: 3px;
  background-color: #ff0000;
  top: 18px;
  left: -4px;
`;
const LowerBg = styled.div`
  width: calc(100% - 6px);
  height: 50%;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
`;
const LowerBgItem1 = styled.div`
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color: #00ff00;
  bottom: 2px;
  left: 2px;
`;
const LowerBgItem2 = styled.div`
  position: absolute;
  width: calc(100% - 4px);
  height: 100%;
  background-color: #0000ff;
  bottom: 0;
  right: 0;
`;
const LowerBgItem3 = styled.div`
  position: absolute;
  width: calc(100% - 6px);
  height: calc(100% - 2px);
  bottom: 2px;
  right: 2px;
  background-color: #00ffff;
`;
const LowerBgItem4 = styled.div`
  position: absolute;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  bottom: 4px;
  left: 0;
  background-color: #ff0000;
`;
const LowerBgItem5 = styled.div`
  position: absolute;
  width: calc(100% - 6px);
  height: calc(100% - 4px);
  bottom: 4px;
  left: 2px;
  background-color: #ffff00;
`;
const LowerBgItem6 = styled.div<{ $disabledEternal: boolean }>`
  position: absolute;
  width: calc(100% - 8px);
  height: calc(100% - 4px);
  background-color: ${({ $disabledEternal }) => ($disabledEternal ? "#c3c3c3" : "#fff")};
  bottom: 4px;
  left: 4px;
`;
const LowerBgItem7 = styled.div`
  position: absolute;
  opacity: 0;
  width: 36px;
  height: 2px;
  background-color: #00ffff;
  top: 16px;
  right: 10px;
`;
const LowerBgItem8 = styled.div`
  position: absolute;
  opacity: 0;
  width: 36px;
  height: 3px;
  background-color: #fff;
  top: 21px;
  right: -3px;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
  }

  &:before {
    width: 2px;
    height: 3px;
    right: -4px;
    background-color: #0000ff;
  }

  &:after {
    width: 2px;
    height: 3px;
    right: -2px;
    background-color: #00ffff;
  }
`;
const Root = styled.a<{ $disabledEternal: boolean }>`
  display: block;
  width: 100%;
  height: 44px;
  //height: 100%;
  position: relative;
  cursor: ${({ $disabledEternal }) => ($disabledEternal ? "not-allowed" : "pointer")};
  pointer-events: ${({ $disabledEternal }) => ($disabledEternal ? "none" : "auto")};
  @media screen and (min-width: 961px) {
    ${({ $disabledEternal }) =>
      !$disabledEternal &&
      css`
        &:hover > ${TextArea} {
          animation: textFlash 2s infinite;
        }

        &:hover ${UpperBgItem1} {
          animation: gritchUpper01 1.2s 0s infinite step-start;
        }

        &:hover ${UpperBgItem2} {
          animation: gritchUpper02 1.2s 0s infinite step-start;
        }

        &:hover ${UpperBgItem3} {
          animation: gritchUpper03 1.2s 0s infinite step-start;
        }

        &:hover ${UpperBgItem4} {
          animation: gritchUpper04 1.2s 0s infinite step-start;
        }

        &:hover ${UpperBgItem5} {
          animation: gritchUpper05 1.2s 0s infinite step-start;
        }

        &:hover ${UpperBgItem6} {
          animation: gritchUpper06 1.2s 0s infinite step-start;
        }

        &:hover ${UpperBgItem7} {
          animation: gritchUpper07 1.2s 0s infinite step-start;
        }

        &:hover ${UpperBgItem8} {
          animation: gritchUpper08 1.2s 0s infinite step-start;
        }

        &:hover ${LowerBgItem1} {
          animation: gritchLower01 1.2s 0s infinite step-start;
        }

        &:hover ${LowerBgItem2} {
          animation: gritchLower02 1.2s 0s infinite step-start;
        }

        &:hover ${LowerBgItem3} {
          animation: gritchLower03 1.2s 0s infinite step-start;
        }

        &:hover ${LowerBgItem4} {
          animation: gritchLower04 1.2s 0s infinite step-start;
        }

        &:hover ${LowerBgItem5} {
          animation: gritchLower05 1.2s 0s infinite step-start;
        }

        &:hover ${LowerBgItem6} {
          animation: gritchLower06 1.2s 0s infinite step-start;
        }

        &:hover ${LowerBgItem7} {
          animation: gritchLower07 1.2s 0s infinite step-start;
        }

        &:hover ${LowerBgItem8} {
          animation: gritchLower08 1.2s 0s infinite step-start;
        }
      `}
  }
`;
