import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { usePreviewMode } from "lib/contexts/PreviewModeContext";
import SsMainButton from "components/front/layout/SsMainButton";
import { useFormikContext } from "formik";

const OrderRealizeButtonArea: FC = () => {
  const formik = useFormikContext();
  const isPreviewMode = usePreviewMode();
  const buttonText = useMemo(() => {
    if (isPreviewMode) return "プレビューで応援することはできません";
    return formik.isValid ? "応援を確定する" : "不正な項目があります";
  }, [formik.isValid, isPreviewMode]);
  const onClickSubmit = () => {
    if (!formik.isSubmitting) formik.handleSubmit();
  };
  return (
    <Root>
      <StyledMainButton disabled={formik.isSubmitting || !formik.isValid} disabledEternal={isPreviewMode} onClick={onClickSubmit}>
        {buttonText}
      </StyledMainButton>
    </Root>
  );
};
export default OrderRealizeButtonArea;

const Root = styled.div`
  height: 44px;
  margin: 20px auto;
  width: 100%;
`;
const StyledMainButton = styled(SsMainButton)<{ disabled: boolean }>`
  margin: 0 auto;
  max-width: 400px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;
