import React, { useEffect, useState } from "react";
import { TabItem } from "./layout/TabItem";

export type MypageTabType = "profile" | "message" | "support";
export const PROFILE_TAB: MypageTabType = "profile";
export const MESSAGE_TAB: MypageTabType = "message";
export const SUPPORT_TAB: MypageTabType = "support";
export const MYPAGE_TABS: MypageTabType[] = ["profile", "message", "support"];

export const ProfileEditTab: React.FC<{
  selectedTab: MypageTabType;
}> = ({ selectedTab }: { selectedTab: MypageTabType }) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    setActive(selectedTab === PROFILE_TAB);
  }, [selectedTab]);
  return <TabItem isActive={active} target={PROFILE_TAB} label="会員情報修正" />;
};

export const SupportTab: React.FC<{
  selectedTab: MypageTabType;
}> = ({ selectedTab }) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    setActive(selectedTab === SUPPORT_TAB);
  }, [selectedTab]);
  return <TabItem isActive={active} target={SUPPORT_TAB} label="応援一覧" />;
};

export const MessageTab: React.FC<{
  selectedTab: MypageTabType;
}> = ({ selectedTab }) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    setActive(selectedTab === MESSAGE_TAB);
  }, [selectedTab]);
  return <TabItem isActive={active} target={MESSAGE_TAB} label="メッセージ" />;
};

export const getMypageScreenName = (tab: string): string => {
  switch (tab) {
    case PROFILE_TAB:
      return "会員情報";
    case MESSAGE_TAB:
      return "メッセージ";
    case SUPPORT_TAB:
      return "応援一覧";
    default:
      return "メッセージ";
  }
};
