import React, { FC } from "react";

const ErrorBlock: FC = ({ children }) => {
  return (
    <div className="error error_02" style={{ margin: "14px 0" }}>
      <p>{children}</p>
    </div>
  );
};
export default ErrorBlock;
