import { TwitterHashTags } from "interfaces/front/business";
import * as React from "react";
import { CSSProperties, FC, useMemo } from "react";
import useTwitterShareButton from "lib/hooks/front/useTwitterShareButton";
import { GonzoHashTags } from "lib/hooks/front/custom/gonzo/mergeGonzoTweetHashTag";

export const TwitterShareButton: FC<{
  projectCode: string;
  twitterHashTags: TwitterHashTags & GonzoHashTags;
  tweetText: string;
  style?: CSSProperties;
}> = ({ projectCode, twitterHashTags, tweetText, style }) => {
  const urlText = `https://animefund.com/project/${projectCode}\n`;
  const twitterHashtags = useMemo(() => {
    const hashtag1 = twitterHashTags.twitter_hashtag1 || "";
    const hashtag2 = twitterHashTags.twitter_hashtag2 || "";
    const hashtag3 = twitterHashTags.gonzoHashtag || "";
    return [hashtag1, hashtag2, hashtag3].filter((hashtag) => hashtag != "");
  }, [twitterHashTags]);
  const options = useMemo(() => ({
    size: "large",
    lang: "ja",
    hashtags: twitterHashtags.join(","),
    // TODO: いつか全体の文字数制限も含めてどうするか検討する
    text: tweetText,
  }), [twitterHashtags, tweetText])
  const shareButtonRef = useTwitterShareButton(urlText, options);
  return <div ref={shareButtonRef} style={{ height: "28px", ...(style || {}) }} />;
};

