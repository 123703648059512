import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";

// Atom
const YoutubeFrame: FC<HTMLAttributes<HTMLIFrameElement> & { id: string }> = ({ id, ...props }) => {
  return (
    <Root
      src={`https://www.youtube.com/embed/${id}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      {...props}
    />
  );
};
export default YoutubeFrame;

const Root = styled.iframe`
  width: 100%;
`;
