import React, { createContext, FC, useContext } from "react";

export const PreviewModeContextProvider: FC = ({ children }) => {
  return <PreviewModeContext.Provider value={true}>{children}</PreviewModeContext.Provider>;
};
type Context = boolean;

const PreviewModeContext = createContext<Context>(false);
export const usePreviewMode = (): boolean => {
  return useContext(PreviewModeContext);
}
