import styled from "styled-components";
import ResolutionImg from "./image/ResolutionImg";
import React, { FC, HTMLAttributes } from "react";

const Headline: FC<HTMLAttributes<HTMLDivElement> & { image: { pc: string; sp: string; position: "right" | "left" } }> = ({
  image: { pc, sp, position },
  children,
  ...props
}) => {
  return (
    <Root {...props} imagePos={position}>
      <Text>{children}</Text>
      <Image pc={pc} sp={sp} position={position} loading="lazy" />
    </Root>
  );
};
export default Headline;

const Root = styled.div<{ imagePos: "right" | "left" }>`
  display: flex;
  justify-content: center;
  flex-direction: ${({ imagePos }) => (imagePos === "left" ? "row-reverse" : "row")};
  align-items: flex-end;
`;
const Text = styled.h3`
  text-align: center;
  line-height: 1.6em;
  @media screen and (max-width: 960px) {
    font-size: 1.2857em;
  }
  @media screen and (min-width: 961px) {
    font-size: 1.5em;
  }
`;
const Image = styled(ResolutionImg)<{ position: "right" | "left" }>`
  margin: ${({ position }) => (position === "left" ? "0 5px 0 0" : "0 0 0 5px")};
  @media screen and (max-width: 960px) {
    width: 80px;
  }
  @media screen and (min-width: 961px) {
    width: 100px;
  }
`;
