import React, { FC, HTMLAttributes } from "react";
import { useFormikContext } from "formik";
import SelectButton, { SelectGroup } from "./SelectButton";
import styled from "styled-components";
import { FamilyType } from "../types";

const FamilySelect: FC<HTMLAttributes<HTMLDivElement> & { name: string }> = ({ name }) => {
  const formik = useFormikContext();
  const field = formik.getFieldProps(name);
  return (
    <SelectGroup>
      {FAMILY_TYPES.map((familyType, index) => (
        <StyledSelectButton
          key={index}
          selected={familyType === field.value}
          onClick={async () => {
            formik.setFieldValue(name, familyType);
            await formik.submitForm();
          }}
        >
          {familyType}
        </StyledSelectButton>
      ))}
    </SelectGroup>
  );
};
export default FamilySelect;

export const FAMILY_TYPES: FamilyType[] = ["独身", "既婚(配偶者控除なし)", "既婚(配偶者控除あり)"];

const StyledSelectButton = styled(SelectButton)`
  width: 100%;
  margin: 3px 0;
`;
