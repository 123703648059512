import { FastField, FieldProps } from "formik";
import React, { FC } from "react";
import { BASE_COLOR } from "components/front/aniale/Styles";
import { FormPaymentMethod } from "interfaces/front/business";
import styled from "styled-components";
import { PaymentSetting } from "interfaces/front/page/anialeForm";

export const PaymentMethodSelectField: FC<{
  name: string;
  payment: PaymentSetting;
}> = ({ name, payment }) => {
  return (
    <FastField name={name}>
      {({ field, form: { setFieldTouched, setFieldValue, isSubmitting }, meta }: FieldProps<string>): React.ReactNode => {
        const onSelect = async (method: FormPaymentMethod) => {
          await setFieldTouched(field.name, true);
          await setFieldValue(field.name, method);
        };
        return (
          <div>
            {payment.can_use_credit_card_reuse && payment.used_credit_card && (
              <PaymentMethodItem
                $disabled={isSubmitting}
                $selected={field.value === "credit_card_reuse"}
                onClick={() => onSelect("credit_card_reuse")}
              >
                <span style={{ display: "inline-block" }}>以前使用したクレジットカードでお支払い</span>
                <UsedCardArea>
                  <UsedCardImage src={payment.used_credit_card.card_brand_image_url} />
                  <UsedCardNumber>{payment.used_credit_card.masked_card_number}</UsedCardNumber>
                </UsedCardArea>
              </PaymentMethodItem>
            )}
            {payment.can_use_credit_card && (
              <PaymentMethodItem $selected={field.value === "credit_card"} $disabled={isSubmitting} onClick={() => onSelect("credit_card")}>
                <span>クレジットカードでお支払い</span>
              </PaymentMethodItem>
            )}
            {payment.can_use_cvs && (
              <PaymentMethodItem $selected={field.value === "cvs"} $disabled={isSubmitting} onClick={() => onSelect("cvs")}>
                <span>コンビニでお支払い</span>
              </PaymentMethodItem>
            )}
            {meta.touched && meta.error && <ErrorText>{meta.error}</ErrorText>}
          </div>
        );
      }}
    </FastField>
  );
};

const UsedCardArea = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
const UsedCardImage = styled.img`
  margin-right: 5px;
  width: 44px;
`;
const UsedCardNumber = styled.span`
  font-size: 14px;
`;
const PaymentMethodItem = styled.div<{ $selected: boolean; $disabled: boolean }>`
  font-size: 14px;
  border: solid 1px ${({ $selected }) => ($selected ? BASE_COLOR : "#CCC")};
  color: ${({ $selected }) => ($selected ? BASE_COLOR : "black")};
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  cursor: pointer;
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "inherit")};
`;
const ErrorText = styled.div`
  margin: 5px 0;
  color: #d70000;
  font-size: 12px;
`;
