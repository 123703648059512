import { DeliveryIcon } from "components/layouts/icons/delivery";
import { SupporterIcon } from "components/layouts/icons/supporter";
import { Aniale, Delivery, DeliveryProperty, DonationCourse, ImageUrl, isDonation, isReward } from "interfaces/front/business";
import { useAmount } from "lib/hooks/useAmount";
import React, { FC, HTMLAttributes, useRef } from "react";
import SeeMore from "components/front/motion/SeeMore";
import styled, { css } from "styled-components";
import useUndeliverableText from "lib/hooks/front/useUndeliverableText";

export type Props2 = HTMLAttributes<HTMLDivElement> & {
  course:
    | Omit<RewardCourse, "course_options" | "course_returns">
    | Omit<DonationCourse | Aniale, "tipping_setting" | "tipping_amount_conditions">;
  quantity: number;
  isShopping: boolean;
};
const OrderCourseArea: FC<Props2> = ({ course, quantity, isShopping, ...props }) => {
  if (isReward(course)) {
    return <OrderRewardCourseArea course={course} quantity={quantity} isShopping={isShopping} {...props} />;
  } else if (isDonation(course)) {
    return <OrderDonationCourseArea course={course} {...props} />;
  } else {
    return <></>;
  }
};
export default OrderCourseArea;

export type Props = HTMLAttributes<HTMLDivElement> & { course: Omit<RewardCourse, "course_options" | "course_returns">; quantity: number; isShopping: boolean; };
export const OrderRewardCourseArea: FC<Props> = ({ course, quantity, isShopping, ...props }) => {
  const formatCoursePrice = useAmount(course.price);
  const courseContent = useRef<HTMLParagraphElement>(null);
  const undeliverableText = useUndeliverableText(course.delivery_property);
  return (
    <CourseContent {...props} className={props.className}>
      <CourseImage>
        <img src={course.course_image.url} alt={course.name} />
      </CourseImage>
      <CourseText>
        <CourseName>{course.name}</CourseName>
        <CourseDescription>
        <p ref={courseContent} dangerouslySetInnerHTML={{ __html: course.content.replace(/\n/g, "<br />") }} />
        </CourseDescription>
        <CourseSubDescription>
          {course.delivery_flag && (
            <CourseDelivery>
              {undeliverableText && <UndeliverableTextArea>{undeliverableText}</UndeliverableTextArea>}
              <DeliveryNoteArea>
                <StyledDeliveryIcon />
                {course.delivery_note}
              </DeliveryNoteArea>
            </CourseDelivery>
          )}
          {!!course.order_count && !isShopping && (
            <CourseOrderCount>
              <CourseOrderCountIcon>
                <SupporterIcon />
              </CourseOrderCountIcon>
              <span>{course.order_count}人に応援されています</span>
            </CourseOrderCount>
          )}
        </CourseSubDescription>
        <CoursePrice>
          <span style={{ fontSize: "20px" }}>{formatCoursePrice}</span>
          <span>円</span>
          <span style={{ margin: "0 10px" }}>×</span>
          <span style={{ fontSize: "20px" }}>{quantity}</span>
          <span>個</span>
        </CoursePrice>
      </CourseText>
    </CourseContent>
  );
};

type DonationCourseProps = HTMLAttributes<HTMLDivElement> & {
  course: Omit<DonationCourse | Aniale, "tipping_setting" | "tipping_amount_conditions">;
};
const OrderDonationCourseArea: FC<DonationCourseProps> = ({ course, ...props }) => {
  return (
    <CourseContent {...props}>
      <CourseImage>
        <img src={course.course_image.url} alt={course.name} />
      </CourseImage>
      <CourseText>
        <CourseName>{course.name}</CourseName>
        {!!course.content && (
          <CourseDescription>
            <SeeMore threshold={40} color="white">
              <p dangerouslySetInnerHTML={{ __html: course.content.replace(/\n/g, "<br />") }} />
            </SeeMore>
          </CourseDescription>
        )}
        <CourseSubDescription>
          {!!course.order_count && (
            <CourseOrderCount>
              <CourseOrderCountIcon>
                <SupporterIcon />
              </CourseOrderCountIcon>
              <span>{course.order_count}人に応援されています</span>
            </CourseOrderCount>
          )}
        </CourseSubDescription>
      </CourseText>
    </CourseContent>
  );
};

const CourseContent = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const CourseImage = styled.div`
  & img {
    @media screen and (max-width: 960px) {
      width: 100px;
    }
    @media screen and (min-width: 961px) and (max-width: 1280px) {
      width: 240px;
    }
    @media screen and (min-width: 1281px) {
      width: 260px;
    }
    @media screen and (min-width: 1921px) {
      width: 300px;
    }
  }
`;
const CourseText = styled.div`
  margin-left: 10px;
`;
const CourseName = styled.div`
  margin-bottom: 10px;
  @media screen and (max-width: 960px) {
    font-size: 16px;
  }
  @media screen and (min-width: 961px) {
    font-size: 18px;
  }
  @media screen and (min-width: 1921px) {
    font-size: 20px;
  }
`;
const CourseDescription = styled.div`
  margin-bottom: 10px;
  line-height: 1.428em;
  @media screen and (max-width: 960px) {
    font-size: 12px;
  }
  @media screen and (min-width: 961px) {
    font-size: 13px;
  }
  @media (min-width: 1921px) {
    font-size: 14px;
  },
`;
const CourseSubDescription = styled.div`
  margin-top: 20px;
`;
const fontSizeCss = css`
  @media screen and (max-width: 960px) {
    font-size: 12px;
  }
  @media screen and (min-width: 961px) {
    font-size: 13px;
  }
  @media screen and (min-width: 1921px) {
    font-size: 14px;
  }
`;
const CourseDelivery = styled.div`
  margin-bottom: 10px;
  ${fontSizeCss};
`;
const UndeliverableTextArea = styled.div`
  margin: 10px 0;
`;
const DeliveryNoteArea = styled.div``;
const StyledDeliveryIcon = styled(DeliveryIcon)`
  display: inline-block;
  width: 12px;
  margin-right: 5px;
`;
const CourseOrderCount = styled.div`
  margin-bottom: 10px;
  ${fontSizeCss};
`;
const CourseOrderCountIcon = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
`;
const CoursePrice = styled.div`
  margin-top: 30px;
`;

type RewardCourse = {
  type: "reward";
  name: string;
  content: string;
  price: number;
  course_image: ImageUrl;
  order_count: number | null;
  delivery_property?: DeliveryProperty;
} & Delivery
