import { Address, OrderRecipient, Residence } from "interfaces/front/business";
import { useMemo } from "react";

export const useResidenceOrAddress = (
  isHometownTax: boolean,
  original: OrderRecipient | null | undefined
): [Address, null] | [null, Residence] | [null, null] | [undefined, undefined] => {
  return useMemo(() => {
    if (original === undefined) return [undefined, undefined];
    if (original === null) return [null, null];
    if (isHometownTax) {
      return [
        null,
        {
          name_sei: original.name_sei,
          name_mei: original.name_mei,
          kana_sei: original.kana_sei,
          kana_mei: original.kana_mei,
          phone_number: original.phone_number,
          zip_code: original.zip_code,
          prefectures: original.prefectures,
          city: original.city,
          house_number: original.house_number,
          building_name: original.building_name,
          gender: original.gender!!,
          birthday: original.birthday!!,
        },
      ];
    } else {
      return [
        {
          name_sei: original.name_sei,
          name_mei: original.name_mei,
          kana_sei: original.kana_sei,
          kana_mei: original.kana_mei,
          phone_number: original.phone_number,
          zip_code: original.zip_code,
          prefectures: original.prefectures,
          city: original.city,
          house_number: original.house_number,
          building_name: original.building_name,
        },
        null,
      ];
    }
  }, [isHometownTax, original]);
};
