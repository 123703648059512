import React, { FC, ReactNode } from "react";
import { Field, FieldProps } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { StyledLeftText2 } from "../project/layout/styled";

type Props = {
  name: string;
  label: React.ReactNode;
  disabled?: boolean;
};

export const CheckboxField: FC<Props> = ({ name, label, disabled }: Props) => (
  <Field name={name}>
    {({ field, form }: FieldProps<boolean>): ReactNode => (
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={field.value}
            onChange={form.handleChange}
            name={name}
            disabled={disabled}
          />
        }
        label={
          <StyledLeftText2 style={{ fontSize: "14px", fontWeight: 600 }}>
            {label}
          </StyledLeftText2>
        }
      />
    )}
  </Field>
);
