import React, { FC, HTMLAttributes, ReactNode } from "react";
import styled, { css } from "styled-components";

export type Props = HTMLAttributes<HTMLDivElement> & { color: Color; label: ReactNode };
const SsHeadline: FC<Props> = ({ color, label, ...props }) => {
  return (
    <Root {...props}>
      <Box $color={color}>
        <Label $color={color}>{label}</Label>
        <Decoration $color={color}>
          <DecorationItem1 $color={color} />
          <DecorationItem2 $color={color} />
          <DecorationItem3 $color={color} />
        </Decoration>
      </Box>
    </Root>
  );
};
export default SsHeadline;

const Root = styled.div`
  width: 100%;
  position: relative;

  @media screen and (min-width: 961px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 960px) {
    margin-bottom: 30px;
    overflow: hidden;
  }
`;
const Box = styled.div<{ $color: Color }>`
  position: relative;
  display: flex;
  align-items: flex-end;
  padding-bottom: 4px;

  &:after {
    position: relative;
    background-color: ${({ $color }) => ($color === "white" ? "#fff" : "#1a1a1a")};
    content: "";
    height: 2px;
    width: calc(100% + 14px);
    flex: 1;
    left: 8px;

    @media screen and (max-width: 960px) {
      bottom: 13px;
      min-width: 40px;
    }
    @media screen and (min-width: 961px) {
      bottom: 15px;
      min-width: 80px;
    }
  }
`;
const Label = styled.h3<{ $color: Color }>`
  line-height: 1.2;
  color: ${({ $color }) => ($color === "white" ? "#fff" : "#1a1a1a")};
  border-bottom: 2px solid ${({ $color }) => ($color === "white" ? "#fff" : "#1a1a1a")};
  width: auto;
  padding-bottom: 6px;
  display: inline-block;
  font-style: italic;

  @media screen and (max-width: 960px) {
    font-size: 18px;
    padding-left: 12px;
    padding-right: 6px;
    max-width: calc(100% - 60px);
  }
  @media screen and (min-width: 961px) {
    font-size: 26px;
    padding-left: 10px;
    padding-right: 10px;
    max-width: calc(100% - 100px);
  }
`;
const Decoration = styled.div<{ $color: Color }>`
  background-color: ${({ $color }) => ($color === "white" ? "#fff" : "#1a1a1a")};
  position: relative;
  transform: skewX(140deg);
  transform-origin: bottom left;
  bottom: 0;
  left: -3px;
  width: 3px;

  @media screen and (max-width: 960px) {
    height: 15px;
  }
  @media screen and (min-width: 961px) {
    height: 17px;
  }
`;

const decorationItem = css<{ $color: Color }>`
  background-color: ${({ $color }) => ($color === "white" ? "#fff" : "#1a1a1a")};
  position: absolute;
  bottom: 0;

  @media screen and (max-width: 960px) {
    width: 5px;
    height: 10px;
  }
  @media screen and (min-width: 961px) {
    width: 8px;
    height: 10px;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;

    @media screen and (max-width: 960px) {
      width: 1px;
    }

    @media screen and (min-width: 961px) {
      width: 2px;
    }
  }
`;
const DecorationItem1 = styled.div<{ $color: Color }>`
  ${decorationItem};
  left: 9px;

  &:after {
    background-color: #ff0000;
  }
`;
const DecorationItem2 = styled.div<{ $color: Color }>`
  ${decorationItem};
  @media screen and (max-width: 960px) {
    left: 18px;
  }
  @media screen and (min-width: 961px) {
    left: 23px;
  }

  &:after {
    background-color: #0000ff;
  }
`;
const DecorationItem3 = styled.div<{ $color: Color }>`
  ${decorationItem};
  @media screen and (max-width: 960px) {
    left: 27px;
  }
  @media screen and (min-width: 961px) {
    left: 37px;
  }

  &:after {
    background-color: #00ff00;
  }
`;

type Color = "white" | "black";
type Size = "normal" | "big";
