import React, { CSSProperties, FC, HTMLAttributes, useMemo } from "react";
import { Transition } from "react-transition-group";

const UpFade: FC<HTMLAttributes<HTMLDivElement> & { in: boolean }> = ({ in: inProps, children, ...props }) => {
  const styles: Record<string, CSSProperties> = useMemo(
    () => ({
      default: {
        transition: `opacity 160ms ease-in-out, top 160ms ease-in-out`,
        opacity: 0,
        position: "relative",
        top: "20px",
        width: "0",
        height: "0",
      },
      entering: { opacity: 1, visibility: "visible", top: "0", width: "inherit", height: "inherit" },
      entered: { opacity: 1, visibility: "visible", top: "0", width: "inherit", height: "inherit" },
      exiting: { opacity: 0, visibility: "visible", top: "20px", width: "inherit", height: "inherit" },
      exited: { opacity: 0, visibility: "hidden", top: "20px", width: "0", height: "0" },
    }),
    []
  );
  return (
    <Transition in={inProps} timeout={160}>
      {(state) => {
        return (
          <div
            {...props}
            style={{
              ...styles.default,
              ...styles[state],
            }}
          >
            {children}
          </div>
        );
      }}
    </Transition>
  );
};
export default UpFade;
