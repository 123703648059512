import { RecruitMethod, Store } from "interfaces/front/business";

export const PROJECT_RECRUIT_METHODS: {
  value: RecruitMethod;
  label: string;
}[] = [
  { value: "all_in", label: "All-in" },
  { value: "all_or_nothing", label: "All-or-Nothing" },
];

export const PROJECT_BUSINESS_TYPES = [
  { value: "normal", label: "普通" },
  { value: "cooperation", label: "共同" },
  { value: "hometown_tax", label: "ふるさと納税" },
  { value: "corp_hometown_tax", label: "企業版ふるさと納税" },
  { value: "shopping", label: "通販" }
];

export const SERVICE_TYPES = [
  { value: "crowd_funding", label: "クラウドファンディング" },
  { value: "hometown_tax", label: "ふるさと納税" },
  { value: "corp_hometown_tax", label: "企業版ふるさと納税" },
  { value: "shopping", label: "通販" }
];

export const DISPLAY_TARGETS = [
  { value: "everyone", label: "全員に表示" },
  { value: "nobody", label: "全員に非表示" },
  { value: "participant", label: "関係者のみに表示" },
];

export const COURSE_LIST_DESIGNS = [
  { value: "not_display", label: "表示しない" },
  { value: "one_column", label: "画像とテキストを1列で表示" },
  { value: "two_column", label: "画像とテキストを2列で表示(PCのみ)" },
]

export const COURSE_OPTION_INPUT_TYPES = [
  { value: "text", label: "テキスト" },
  // { value: "checkbox", label: "チェックボックス" },
  // { value: "radio", label: "ラジオボタン" },
  { value: "selectbox", label: "セレクトボックス" },
];

export const GENDER = {
  male: "男",
  female: "女",
  unanswered: "未回答",
};

export const PROJECT_WORKFLOW_STATUSES = [
  { value: "draft", label: "下書き" },
  { value: "submitted", label: "入稿依頼済み" },
  { value: "rejected", label: "差し戻し" },
  { value: "approved", label: "承認済み" },
  { value: "modified", label: "変更依頼済み" },
];

export const CVS_STORES: Store[] = [
  { name: "seven_eleven", label: "セブンイレブン" },
  { name: "family_mart", label: "ファミリーマート" },
  { name: "lawson", label: "ローソン" },
  { name: "seico_mart", label: "セイコーマート" },
  { name: "mini_stop", label: "ミニストップ" },
];

// 全角のひらがな、カタカナ、漢字、英字のみ有効（例外として長音と「々」も有効）
export const FULLWIDTH_PATTERN = /^[\u4E00-\u9FAF\u3040-\u3096\u30A1-\u30FA\u31F0-\u31FFＡ-ｚー々]+$/; // カタカナのみ有効
export const KATAKANA_PATTERN = /^([ァ-ヴー・])+$/;
