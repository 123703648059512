import React, { FC, HTMLAttributes } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { BASE_COLOR2, BORDER_RADIUS } from "../theme";
import Radio from "./Radio";

const FamilyRadio: FC<HTMLAttributes<HTMLDivElement> & { name: string; label: string; options: string[] }> = ({
  name,
  label,
  options,
  ...props
}) => {
  const formik = useFormikContext();
  const field = formik.getFieldProps(name);
  return (
    <Root {...props}>
      <Label>{label}</Label>
      <FieldSet>
        {options.map((option, index) => (
          <Row key={index}>
            <Radio {...field} value={option} checked={option == field.value} />
            <Text>{option}</Text>
          </Row>
        ))}
      </FieldSet>
    </Root>
  );
};
export default FamilyRadio;

const Root = styled.div`
  background-color: ${BASE_COLOR2};
  ${BORDER_RADIUS};
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 960px) {
    padding: 5px 10px;
  }
  @media screen and (min-width: 961px) {
    padding: 10px 20px;
  }
`;
const Label = styled.span``;
const FieldSet = styled.div`
  @media screen and (min-width: 961px) {
    min-width: 160px;
    display: flex;
    justify-content: space-between;
  }
`;
const Row = styled.label`
  display: inline-flex;
  align-items: center;
  @media screen and (max-width: 960px) {
    margin: 0 5px;
  }
  @media screen and (min-width: 961px) {
    margin: 0;
  }
`;
const Text = styled.span`
  margin-left: 2px;
`;
