import { useMemo } from "react";
import { DeliveryProperty } from "interfaces/front/business";

const useUndeliverableText = (deliveryProperty?: DeliveryProperty) => {
  return useMemo(() => {
    if (!deliveryProperty) return null;
    const areas = [];
    if (deliveryProperty.ng_hokkaido) areas.push("北海道");
    if (deliveryProperty.ng_okinawa) areas.push("沖縄県");
    if (deliveryProperty.ng_isolated_island) areas.push("離島");
    return areas.length > 0 ? `※${areas.join("・")}への配送はできません。` : null;
  }, [deliveryProperty]);
};
export default useUndeliverableText;
