import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin: 10px auto auto;
  @media screen and (min-width: 1281px) {
    max-width: 1080px;
  }
  @media screen and (min-width: 1921px) {
    max-width: 1280px;
  }
`;

export const Confirm = styled.div`
  & > *:not(:first-child) {
    margin-top: 20px;
  }
`;
