import React, { FC } from "react";
import styled from "styled-components";
import SwiperCss from "components/front/layout/SwiperCss";

const CourseImageSwiper: FC<{ imageUrls: string[]; loadFirst?: boolean }> = ({ imageUrls, loadFirst = false }) =>
  imageUrls.length > 1 ? (
    <StyledSwiper className="swiper-container content-swiper">
      <div className="swiper-wrapper">
        {imageUrls.map((imageUrl, index) => (
          <div className="swiper-slide" key={index}>
            {index == 0 && loadFirst ? (
              <img src={imageUrl} alt="" loading="lazy" className="swiper-lazy" />
            ) : (
              <img data-src={imageUrl} alt="" className="swiper-lazy" />
            )}
          </div>
        ))}
      </div>
      <div className="swiper-pagination" />
      <div className="swiper-button-prev" />
      <div className="swiper-button-next" />
    </StyledSwiper>
  ) : (
    <>
      {imageUrls.map((imageUrl, index) => (
        <img key={index} src={imageUrl} loading="lazy" alt="" />
      ))}
    </>
  );
export default CourseImageSwiper;

const StyledSwiper = styled.div`
  ${SwiperCss};
`;
