import { Field, FieldProps } from "formik";
import React, { FC } from "react";
import { TextareaAutosize } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styled from "styled-components";

export const AaFrontTextField: FC<{
  name: string;
  rows?: number | string;
}> = ({ name, ...props }) => {
  const classes = useStyles();
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps<string>): React.ReactNode => {
        return (
          <>
            <div className={classes.textInputArea}>
              <TextareaAutosize rowsMax={10} {...field} {...props} disabled={form.isSubmitting} />
            </div>
            {meta.touched && meta.error && <ErrorText>{meta.error}</ErrorText>}
          </>
        );
      }}
    </Field>
  );
};

const useStyles = makeStyles(() => ({
  textInputArea: {
    border: "solid 1px #CCC",
    borderRadius: "5px",
    padding: "2px",
    boxShadow: "0 2px 2px -2px rgba(0,0,0,0.7) inset",
    "& > textarea": {
      margin: "5px 0",
      padding: "5px",
      resize: "none",
      width: "100%",
    },
  }
}))

const ErrorText = styled.div`
  margin: 5px 0;
  color: #d70000;
  font-size: 12px;  
`;
