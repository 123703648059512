import React, { FC, useMemo } from "react";
import { TokenResponse } from "./CreditCardDialog";
import styled from "styled-components";

export type Props = {
  card: TokenResponse;
};
const CreditCardTokenCard: FC<Props> = ({ card }) => {
  const ccExpiration = useMemo(() => card.ccExpiration.slice(0, 4) + "/" + card.ccExpiration.slice(4), [card.ccExpiration]);
  return (
    <Root>
      <Title>クレジットカードでお支払い</Title>
      <Content>
        <FieldArea>
          <FieldLabel>カード番号</FieldLabel>
          <FieldValue>{card.maskedCcNumber}</FieldValue>
        </FieldArea>
        <FieldArea>
          <FieldLabel>有効期限</FieldLabel>
          <FieldValue>{ccExpiration}</FieldValue>
        </FieldArea>
      </Content>
    </Root>
  );
};
export default CreditCardTokenCard;

const Root = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 16px;
`;
const Title = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
`;
const Content = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
`;
const FieldArea = styled.div`
  :not(:first-child) {
    margin-left: 16px;
  }
`;
const FieldLabel = styled.div`
  margin-bottom: 10px;
  color: gray;
  font-size: 12px;
`;
const FieldValue = styled.div`
  margin-top: 10px;
`;
