import { useCallback, useState } from "react";
import { FrontOrderFormService } from "lib/service/client/page/FrontOrderFormService";
import { errorToast } from "lib/toast";

const useDeliverable = (courseIds: number[]): DeliverableHook => {
  const [undeliverableText, setUndeliverableText] = useState<string | null>();
  const resetUndeliverable = useCallback(async (): Promise<void> => setUndeliverableText(undefined), [setUndeliverableText]);
  const checkDeliverable = useCallback(
    async (recipient: Recipient): Promise<boolean> => {
      const result = await new FrontOrderFormService().getDeliverable({
        course_ids: courseIds,
        recipient: { zip_code: recipient.zip_code, prefecture: recipient.prefectures, city: recipient.city },
      });
      if (result.isSuccess()) {
        setUndeliverableText(result.value.undeliverable_text)
        return !result.value.undeliverable_text;
      } else {
        await errorToast("お届け先の確認ができませんでした。");
        return false;
      }
    },
    [courseIds]
  );
  return { undeliverableText, resetUndeliverable, checkDeliverable };
};
export default useDeliverable;

type DeliverableHook = {
  undeliverableText: string | null | undefined;
  resetUndeliverable: () => Promise<void>;
  checkDeliverable: (recipient: Recipient) => Promise<boolean>;
};

type Recipient = {
  zip_code: string;
  prefectures: string;
  city: string;
};
