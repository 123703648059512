import { ValidationErrorV2 } from "../../lib/service/BaseClientService";
import { FC } from "react";

type Props = {
  validationError: ValidationErrorV2;
}

export const FrontFormErrorArea: FC<Props> = (props: Props) => {
  const errorMessage = props.validationError["form"];
  if (!errorMessage) return (<></>)
  return (
    <div className="error error_02" style={{margin: "20px 0"}}>
      <p>{errorMessage}</p>
    </div>
  )
}
