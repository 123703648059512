import { Field } from "formik";
import React, { FC } from "react";
import { AaTextField } from "./aniale/AaTextField";
import styled from "styled-components";

export const TextField = ({
  label,
  name,
  placeholder,
  fullWidth,
}: {
  label: string;
  name: string;
  placeholder: string;
  fullWidth?: boolean;
}) => (
  <Field
    component={AaTextField}
    label={label}
    name={name}
    placeholder={placeholder}
    variant="outlined"
    size="small"
    margin="normal"
    fullWidth={fullWidth}
  />
);

export const TwoColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & > * {
    flex-basis: 48%;
  }
`;
