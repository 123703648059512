import { AdminBaseService } from "lib/service/client/AdminBaseService";
import { ErrorV2, Result } from "lib/service/BaseClientService";

export class AdminIntegrationRedhourseService extends AdminBaseService {
  constructor(adminType: string) {
    super(adminType);
    this.url = `${this.url}/page/integration/redhourse`;
  }

  async getTargetProjects(): Promise<Result<RedhourseTargetProjectsOutput, ErrorV2>> {
    return super.doSearch<RedhourseTargetProjectsOutput, ErrorV2>(`${this.url}/target_projects`);
  }

  async getSettings(projectId: number): Promise<Result<RedhourseSettingsOutput, ErrorV2>> {
    return super.doGet<RedhourseSettingsOutput, ErrorV2>(`${this.url}/${projectId}`, "settings");
  }

  async postProductMapping(projectId: number, input: ProductMappingPostInput): Promise<Result<void, ErrorV2>> {
    const body = JSON.stringify(input)
    return super.doPost(`${this.url}/${projectId}/product_mapping`, body)
  }
}

export type RedhourseTargetProjectsOutput = {
  id: number;
  title: string;
}[]
export type RedhourseSettingsOutput = {
  product_mapping_groups: ProductMappingGroup[]
}
export type ProductMappingGroup = {
  course_id: number;
  course_name: string;
  questions: string[];
  product_mappings: ProductMapping[]
}
export type ProductMapping = {
  mapping_key: string[];
  redhourse_management_code: string;
}
export type ProductMappingPostInput = {
  course_id: number;
  course_name: string;
  questions: string[];
  product_mappings: ProductMapping[]
}
