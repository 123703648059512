import React, { CSSProperties, FC, HTMLAttributes, useEffect, useMemo, useRef, useState } from "react";
import { Transition } from "react-transition-group";

const BottomSlide: FC<HTMLAttributes<HTMLDivElement> & { in: boolean }> = ({ in: inProps, children, ...props }) => {
  const container = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState("0px");
  const styles: Record<string, CSSProperties> = useMemo(
    () => ({
      default: { transition: `max-height 160ms ease-in-out`, maxHeight: "0", height: "auto", overflow: "hidden" },
      entering: { maxHeight: containerHeight },
      entered: { maxHeight: containerHeight },
      exiting: { maxHeight: "0px" },
      exited: { maxHeight: "0px" },
    }),
    [containerHeight]
  );
  useEffect(() => {
    // unmountや非表示にで閉じてしまう対策
    if (container.current?.clientHeight !== 0) {
      setContainerHeight(`${container.current?.clientHeight || 0}px`);
    }
  }, [container.current?.clientHeight]);
  return (
    <Transition in={inProps} timeout={160}>
      {(state) => {
        return (
          <div
            {...props}
            style={{
              ...styles.default,
              ...styles[state],
            }}
          >
            <div ref={container}>{children}</div>
          </div>
        );
      }}
    </Transition>
  );
};
export default BottomSlide;
