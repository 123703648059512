import { CreateCSSProperties } from "@material-ui/core/styles/withStyles";

// SuperSuperさん特有のスタイルを集約していく

export const ssText: CreateCSSProperties = {
  width: "100%",
};
export const ssCenterText: CreateCSSProperties = {
  transformOrigin: "center",
  width: "100%",
};
export const ssPriceText: CreateCSSProperties = {
  letterSpacing: ".1em",
};
