import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import React, { FC, useMemo } from "react";
import { Address, CorporateAddress, Residence, ResidentGender } from "interfaces/front/business";
import { AaCard } from "../../layout/aniale/AaCard";
import styled from "styled-components";

export const AddressCard: FC<{
  address: Address;
}> = ({ address }) => (
  <Card variant="outlined" style={{ margin: "10px 0" }}>
    <CardContent>
      <PlaceArea
        zipCode={address.zip_code}
        prefectures={address.prefectures}
        city={address.city}
        houseNumber={address.house_number}
        buildingName={address.building_name}
      />
      <NameArea nameSei={address.name_sei} nameMei={address.name_mei} />
    </CardContent>
  </Card>
);

export const ResidenceCard: FC<{
  residence: Residence;
}> = ({ residence }) => (
  <Card variant="outlined" style={{ margin: "10px 0" }}>
    <CardContent>
      <PlaceArea
        zipCode={residence.zip_code}
        prefectures={residence.prefectures}
        city={residence.city}
        houseNumber={residence.house_number}
        buildingName={residence.building_name}
      />
      <NameArea nameSei={residence.name_sei} nameMei={residence.name_mei} />
      <GenderAndBirthdayArea
        gender={residence.gender}
        birthday={residence.birthday}
      />
    </CardContent>
  </Card>
);

export const CorporateAddressCard: FC<{
  address: CorporateAddress;
}> = ({address}) => (
  <Card variant="outlined" style={{ margin: "10px 0" }}>
    <CardContent>
      <PlaceArea
        zipCode={address.zip_code}
        prefectures={address.prefectures}
        city={address.city}
        houseNumber={address.house_number}
        buildingName={address.building_name}
      />
      <Row>{address.corporate_name} {address.corporate_department}</Row>
      <Row>{address.name_sei} {address.name_mei}</Row>
    </CardContent>
  </Card>
)

export const AaResidenceCard: FC<{
  residence: Residence;
  onEdit: () => void;
}> = ({ residence, onEdit }) => (
  <AaCard variant="elevation" style={{ margin: "10px 0" }}>
    <CardContent>
      <PlaceArea
        zipCode={residence.zip_code}
        prefectures={residence.prefectures}
        city={residence.city}
        houseNumber={residence.house_number}
        buildingName={residence.building_name}
      />
      <NameArea nameSei={residence.name_sei} nameMei={residence.name_mei} />
      <GenderAndBirthdayArea
        gender={residence.gender}
        birthday={residence.birthday}
      />
    </CardContent>
    <CardActions>
      <Button
        onClick={onEdit}
        style={{marginLeft: "auto"}}
        variant="outlined"
        color="default"
      >
        編集
      </Button>
    </CardActions>
  </AaCard>
)

const PlaceArea: FC<{
  zipCode: string;
  prefectures: string;
  city: string;
  houseNumber: string;
  buildingName: string;
}> = ({ zipCode, prefectures, city, houseNumber, buildingName }) => (
  <div style={{ marginBottom: "20px" }}>
    <div style={{ marginBottom: "8px", fontSize: "12px" }}>〒{zipCode}</div>
    <div>
      {prefectures}
      {city}
      {houseNumber}
      {buildingName}
    </div>
  </div>
);
const NameArea: FC<{
  nameSei: string;
  nameMei: string;
}> = ({ nameSei, nameMei }) => (
  <div>
    {nameSei} {nameMei}
  </div>
);

const GenderAndBirthdayArea: FC<{
  gender: ResidentGender;
  birthday: string;
}> = ({ gender, birthday }) => {
  const genderLabel = useGenderLabel(gender);
  const birthdayLabel = useBirthday(birthday);
  return (
    <div style={{ marginTop: "8px", fontSize: "12px" }}>
      {birthdayLabel} {genderLabel}
    </div>
  );
};

const Row = styled.div`
  margin: 8px 0;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const useGenderLabel = (gender: ResidentGender): string => {
  return useMemo(() => {
    switch (gender) {
      case "male":
        return "男";
      case "female":
        return "女";
      default:
        return "未設定";
    }
  }, [gender]);
};

const useBirthday = (birthday: string): string => {
  return useMemo(() => {
    return birthday ? new Date(birthday).toLocaleDateString() : "";
  }, [birthday]);
};
