import React, { FC, HTMLAttributes } from "react";
import RateMeter from "components/layouts/RateMeter";
import styled from "styled-components";

export type GoalAmountAreaProps = { amount: string };
export const GoalAmountArea: FC<GoalAmountAreaProps> = ({ amount }: GoalAmountAreaProps) => (
  <div className="target target--white target--visual">
    <span className="target_name">目標金額</span>
    <span className="target_data">{amount}</span>
    <span className="target_unit">円</span>
  </div>
);

export type RatingProps = HTMLAttributes<HTMLDivElement> & { rate?: number | null };
export const Rating: FC<RatingProps> = ({ rate, ...props }: RatingProps) => (
  <RatingRoot {...props} className={`rate rate--visual ${props.className}`} data-rate={rate ?? "0"}>
    <RateName>達成率</RateName>
    <StyledRateMeter rate={rate} />
    <Percent>
      <PercentData>{rate}</PercentData>
      <PercentUnit>%</PercentUnit>
    </Percent>
  </RatingRoot>
);

const RatingRoot = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  
  @media screen and (max-width: 960px) {
    padding-left: 10px;
    margin-bottom: 5px;
    width: 100%;
  }
  @media screen and (min-width: 961px) {
    padding-left: 20px;
    margin-bottom: 10px;
  }
`;
const RateName = styled.span`
  width: auto;
  white-space: nowrap;
  @media screen and (max-width: 960px) {
    font-size: 10px;
    padding-right: 14px;
  }
  @media screen and (min-width: 961px) {
    font-size: 14px;
    padding-right: 10px;
  }
`;
const StyledRateMeter = styled(RateMeter)`
  color: #fff;
  background-color: #fff;
  transform: skewX(-10deg);

  @media screen and (max-width: 960px) {
    width: calc(100% - 122px);
    height: 18px;
  }
  @media screen and (min-width: 961px) {
    width: 146px;
    height: 24px;
  }
`;
const Percent = styled.span`
  padding-left: 8px;
`;
const PercentData = styled.span`
  font-style: italic;
  font-weight: 800;
  padding-right: 2px;
  letter-spacing: 0.1em;
  line-height: 16px;
  font-size: 20px;
`;
const PercentUnit = styled.span`
  font-style: italic;
  font-size: 12px;
  font-weight: 800;
`;
