import React, { FC, HTMLAttributes } from "react";
import { FormControlLabel, FormHelperText, Radio } from "@material-ui/core";
import { ErrorMessage, FastField } from "formik";
import { RadioGroup } from "formik-material-ui";
import { Box, Label, Root } from "components/front/order/recipient/fields/FieldParts";

type Props = HTMLAttributes<HTMLDivElement> & { name: string; label: string };
const GenderField: FC<Props> = ({ name, label, ...props }) => {
  return (
    <FastField component={RadioGroup} name={name} {...props}>
      <Root>
        <Label>{label}</Label>
        <Box>
          {GENDER_OPTIONS.map((option, index) => (
            <FormControlLabel key={index} value={option.value} control={<Radio color="primary" />} label={option.label} />
          ))}
        </Box>
        <ErrorMessage name={name}>{(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}</ErrorMessage>
      </Root>
    </FastField>
  );
};
export default GenderField;

const GENDER_OPTIONS = [
  { value: "male", label: "男" },
  { value: "female", label: "女" },
];
