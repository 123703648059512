import { ThunkAction } from "redux-thunk";
import { RootState } from "modules/index";
import { Action } from "@reduxjs/toolkit";
import { onOpenDeductionFlowDetail, onToggleDeductionFlowDetail } from "modules/pages/lp/animefurusato/index";

const navigateDeductionFlowDetail = (): ThunkAction<void, RootState, null, Action<string>> => async (dispatch, getState) => {
  const state = getState();
  const deductionFlowDetail = document.getElementById(state.pages.lp.animefurusato.deductionStepId);
  await deductionFlowDetail?.scrollIntoView({ behavior: "smooth" });
  dispatch(onOpenDeductionFlowDetail());
};

export const AnimefurusatoOperations = {
  toggleDeductionFlowDetail: onToggleDeductionFlowDetail,
  openDeductionFlowDetail: onOpenDeductionFlowDetail,
  navigateDeductionFlowDetail,
};
