import React, { FC, HTMLAttributes } from "react";
import { CheckboxField } from "components/front/layout/CheckboxField";
import styled from "styled-components";

const WritesCommentField: FC<HTMLAttributes<HTMLDivElement> & { name: string; label: string }> = ({ name, label, ...props }) => {
  return (
    <Root {...props}>
      <CheckboxField name={name} label={label} />
      <Note>
        <BreakableText>※応援コメントは応援確定後に</BreakableText>
        <BreakableText>入力することができます</BreakableText>
      </Note>
    </Root>
  );
};
export default WritesCommentField;

const Root = styled.div`
  text-align: center;
`;
const Note = styled.div`
  line-height: 1.25em;
  @media screen and (max-width: 960px) {
    font-size: 12px;
  }
  @media screen and (min-width: 961px) {
    font-size: 14px;
  }
`;
const BreakableText = styled.span`
  display: inline-block;
`;
