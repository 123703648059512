import React, { FC, HTMLAttributes, useMemo } from "react";
import { FastField, FieldProps } from "formik";
import styled from "styled-components";

type Props = HTMLAttributes<HTMLDivElement> & {
  name: string;
  label: string;
  options: OptionType[];
  disabled?: boolean;
  fullWidth?: boolean;
};
const SelectField: FC<Props> = ({ name, label, options, fullWidth = false, disabled, ...props }) => {
  return (
    <FastField name={name}>
      {({ field }: FieldProps<string>) => (
        <Root $fullWidth={fullWidth} {...props}>
          <Label htmlFor={props.id}>{label}</Label>
          <Select {...field} disabled={disabled}>
            {options.map((option, index) => (
              <option key={index} value={option.value}>{option.label}</option>
            ))}
          </Select>
        </Root>
      )}
    </FastField>
  );
};
export default SelectField;

const Root = styled.div<{ $fullWidth: boolean }>`
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "inherit")};
`;
const Label = styled.label`
  display: block;
`;
const Select = styled.select`
  padding: 5px;
  min-width: 200px;
`;

type OptionType = {
  value: string;
  label: string;
};
