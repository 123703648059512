import React, { FC } from "react";
import { CvsInput } from "./CvsDialog";
import styled from "styled-components";

export type Props = {
  cvs: CvsInput;
};
const CvsCard: FC<Props> = ({ cvs }) => {
  return (
    <Root>
      <Title>コンビニでお支払い</Title>
      <Content>
        <StoreArea>
          <IconArea>
            <img alt={cvs.store.label} src={`/img/cvs/${cvs.store.name}.png`} width="50px" />
          </IconArea>
          <IconTextArea>{cvs.store.label}</IconTextArea>
        </StoreArea>
        <PhoneArea>
          <PhoneLabel>電話番号</PhoneLabel>
          <PhoneValue>{cvs.phone_number}</PhoneValue>
        </PhoneArea>
      </Content>
    </Root>
  );
};
export default CvsCard;

const Root = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 16px;
`;
const Title = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
`;
const Content = styled.div`
  margin-top: 16px;
`;
const StoreArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
const IconArea = styled.div``;
const IconTextArea = styled.div`
  margin: 0 20px;
`;
const PhoneArea = styled.div`
  margin-top: 16px;
`;
const PhoneLabel = styled.div`
  font-size: 12px;
  color: gray;
  margin-bottom: 10px;
`;
const PhoneValue = styled.div`
  margin-top: 10px;
`;
