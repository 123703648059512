import React, { FC } from "react";
import { Dialog } from "@material-ui/core";
import { Loading } from "../../layout/Loading";

const AnialeFormLoading: FC<{ shouldShow: boolean }> = ({ shouldShow }) => {
  return (
    <Dialog open={shouldShow} fullWidth>
      <div style={{ padding: "20px", textAlign: "center" }}>
        <Loading />
      </div>
    </Dialog>
  );
};
export default AnialeFormLoading;
