import { useMemo } from "react";
import { EndingCountdownExpression } from "../project_util";

export const useRemainingText = (
  remaining: EndingCountdownExpression | null | undefined
): [string, string | undefined] => {
  const remainingText = useMemo(() => {
    if (remaining === undefined) {
      return "計算中";
    } else if (remaining === null) {
      return "終了";
    } else {
      return remaining.data.toString();
    }
  }, [remaining]);
  const remainingUnit = useMemo(
    () => (remaining ? remaining.unit : undefined),
    [remaining]
  );
  return [remainingText, remainingUnit];
};
