
type Props = {
  label: string;
}

// HACK: 拡張するか検討
// <!-- 項目タイトル -->
// <div class="item_title item_title--required">
//   <p>
//     こちらのリターンはシャツ1枚・Sサイズでございます。<br />
//     よろしければ「はい」をご選択ください。
//   </p>	
// </div>

// <div class="item_title item_title--terms">
//   <p>
//     事前に必ずご一読ください<br />
//     <span>(詳細は<a href="/terms">利用規約</a>をご確認願います)</span>
//   </p>
// </div>

export const ItemTitle: React.FC<Props> = ({ label }: Props) => {
  return (
    <div className="item_title item_title--required">
      <p>{label} </p>
    </div>
  );
}
