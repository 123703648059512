import React, { FC, HTMLAttributes } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SsHeadline from "../../layout/SsHeadline";

const OrderFormSection: FC<HTMLAttributes<HTMLDivElement> & { title: string }> = ({ title, children, ...props }) => {
  const classes = useStyles();
  return (
    <div {...props} className={classes.root}>
      <div className={classes.headline}>
        <SsHeadline color="white" label={title} />
      </div>
      <div className={classes.cardArea}>
        {React.Children.map(children, (child) => child && <div className={classes.card}>{child}</div>)}
      </div>
    </div>
  );
};
export default OrderFormSection;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 3% 40px",
  },
  headline: {
    margin: "10px 0 0",
  },
  cardArea: {},
  card: {
    margin: "10px 0",
    background: "white",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      "& > *": {
        width: "800px",
        marginRight: "auto",
        marginLeft: "auto",
      },
    },
  },
}));
