import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";

const Annotation: FC<HTMLAttributes<HTMLUListElement> & { texts: string[] }> = ({ texts, ...props }) => (
  <Root {...props}>
    {texts.map((text, index) => (
      <ListItem key={index}>{text}</ListItem>
    ))}
  </Root>
);
export default Annotation;

const Root = styled.ul`
  padding: 10px;
  line-height: 1.6em;
`;
const ListItem = styled.li`
  list-style: outside;

  &::marker {
    content: "・";
  }
`;
