import React, { FC, HTMLAttributes, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import FamilyRadio from "./FamilyRadio";
import BottomSlide from "../../../../motion/BottomSlide";
import SpouseAgeSelect from "./SpouseAgeSelect";
import styled from "styled-components";
import AmountField from "./AmountField";

const SpouseField: FC<HTMLAttributes<HTMLDivElement>> = ({ ...props }) => {
  const formik = useFormikContext();
  const field = formik.getFieldProps("support.spouse_flag");
  const existsSpouse = useMemo(() => field.value === "あり", [field.value]);
  useEffect(() => {
    if (existsSpouse) {
      formik.setFieldValue("family.spouse", defaultSpouse);
    } else {
      formik.setFieldValue("family.spouse", emptySpouse);
    }
  }, [existsSpouse]);
  return (
    <Root {...props}>
      <StyledFamilyRadio name={field.name} label="配偶者" options={["なし", "あり"]} />
      <BottomSlide in={existsSpouse}>
        <FieldSet>
          <StyledSpouseAgeSelect label="配偶者の年齢" options={["69歳以下", "70歳以上"]} name="family.spouse.age" />
          <StyledAmountInput label="配偶者の給与所得" name="family.spouse.income" />
        </FieldSet>
      </BottomSlide>
    </Root>
  );
};
export default SpouseField;

const Root = styled.div``;
const FieldSet = styled.div`
  padding: 5px 0;
`;
const StyledFamilyRadio = styled(FamilyRadio)`
  //margin-bottom: 5px;
`;
const StyledAmountInput = styled(AmountField)`
  @media screen and (max-width: 960px) {
    margin-top: 5px;
  }
  @media screen and (min-width: 961px) {
    margin-top: 5px;
    margin-left: 20px;
  }
`;
const StyledSpouseAgeSelect = styled(SpouseAgeSelect)`
  //margin-bottom: 5px;
`;

const defaultSpouse = {
  age: "69歳以下",
  income: "",
};
const emptySpouse = {
  age: "",
  income: "",
};
