import styled from "styled-components";
import { BASE_COLOR1, BORDER_RADIUS, MAIN_COLOR1, MAIN_COLOR2 } from "../theme";

export const SelectGroup = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
`;
const SelectButton = styled.li<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? MAIN_COLOR2 : "#1a1a1a")};
  background-color: ${({ selected }) => (selected ? MAIN_COLOR1 : "#FFF")};
  border: 1px solid ${({ selected }) => (selected ? MAIN_COLOR2 : BASE_COLOR1)};
  ${BORDER_RADIUS};
  text-align: center;
  cursor: pointer;
  @media screen and (max-width: 960px) {
    padding: 10px 5px;
  }
  @media screen and (min-width: 961px) {
    padding: 15px 10px;
  }
`;
export default SelectButton;
