import RateMeter from "components/layouts/RateMeter";
import React from "react";
import { useRating } from "lib/hooks/useRating";
import styled from "styled-components";

type Props = {
  goal: number | null;
  support: number | null;
};

export const Rating: React.FC<Props> = ({ goal, support }) => {
  const rate = useRating(goal, support);
  return rate !== undefined && rate !== null ? (
    <Root>
      <StyledRateMeter rate={rate} />
    </Root>
  ) : (
    <></>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  background-color: #e5e5e5;
  
  width: 100%;
  height: 14px;
`;
const StyledRateMeter = styled(RateMeter)`
  background-color: #e5e5e5;
  width: 100%;
  height: 100%;
`;
