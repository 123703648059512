import React, { FC, HTMLAttributes, useMemo } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { BASE_COLOR1, BORDER_RADIUS, MAIN_COLOR2 } from "../theme";

const Counter: FC<HTMLAttributes<HTMLDivElement> & { name: string; label: string }> = ({ name, label, ...props }) => {
  const formik = useFormikContext();
  const field = formik.getFieldProps(name);
  const moreThanMin = useMemo(() => field.value > 0, [field.value]);
  const decrease = () => moreThanMin && formik.setFieldValue(field.name, field.value - 1);
  const lessThanMax = useMemo(() => field.value < 30, [field.value]);
  const increase = () => lessThanMax && formik.setFieldValue(field.name, field.value + 1);
  return (
    <Root {...props}>
      <Label>{label}</Label>
      <Control>
        <Minus active={moreThanMin} focusable="false" viewBox="0 0 24 24" aria-hidden="true" onClick={decrease}>
          <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
        </Minus>
        <ValueText active={moreThanMin}>{field.value}人</ValueText>
        <Plus active={lessThanMax} focusable="false" viewBox="0 0 24 24" aria-hidden="true" onClick={increase}>
          <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
        </Plus>
      </Control>
    </Root>
  );
};
export default Counter;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${BASE_COLOR1};
  ${BORDER_RADIUS};
  @media screen and (max-width: 960px) {
    padding: 8px 10px;
  }
  @media screen and (min-width: 961px) {
    padding: 12px 20px;
  }
`;
const Label = styled.span``;
const Control = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 960px) {
    min-width: 80px;
  }
  @media screen and (min-width: 961px) {
    min-width: 120px;
  }
`;
const IconButton = styled.svg<{ active: boolean }>`
  touch-action: manipulation;
  cursor: ${({ active }) => (active ? "pointer" : "not-allowed")};
  fill: ${({ active }) => (active ? "#BBB" : "#EEE")};
  @media screen and (max-width: 960px) {
    width: 1.5em;
    height: 1.5em;
  }
  @media screen and (min-width: 961px) {
    width: 1.6em;
    height: 1.6em;
  }
`;
const Minus = styled(IconButton)``;
const Plus = styled(IconButton)``;
const ValueText = styled.div<{ active: boolean }>`
  text-align: center;
  color: ${({ active }) => (active ? MAIN_COLOR2 : "#1a1a1a")};
`;
