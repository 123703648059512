import { makeStyles, Theme } from "@material-ui/core";
import Link from "next/link";
import React from "react";

export const TabItem: React.FC<{ isActive: boolean; label: string; target: string }> = ({ isActive, target, label }) => {
  const classes = useStyles({ isActive });
  return (
    <div className={classes.tab}>
      <Link href={`?tab=${target}`}>
        <a>
          <svg className={classes.group} viewBox="0 0 100 30" preserveAspectRatio="none">
            <polygon points="0,0 0,30 100,30 100,10 90,0" strokeWidth="2" stroke="white" />
            <text x="50" y="15" textAnchor="middle" dominantBaseline="central">
              {label}
            </text>
          </svg>
        </a>
      </Link>
    </div>
  );
};

const useStyles = makeStyles<Theme, { isActive: boolean }>((theme) => ({
  tab: {
    "&:not(:last-child)": {
      marginRight: "10px",
    },
  },
  group: ({ isActive }) => ({
    width: "100px",
    height: "30px",
    cursor: "pointer",
    "& > polygon": {
      fill: isActive ? "white" : "black",
    },
    "& > text": {
      pointerEvents: "none",
      fontSize: "12px",
      fontStyle: "italic",
      fill: isActive ? "black" : "white",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
    "&:hover": {
      "& > polygon": {
        fill: "white",
      },
      "& > text": {
        fill: "black",
      },
    },
    [theme.breakpoints.up("lg")]: {
      width: "107px",
      height: "32px",
    },
    ["@media (min-width:1921px)"]: {
      width: "120px",
      height: "36px",
    },
  }),
}));
