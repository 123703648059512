import React, { FC } from "react";
import styled from "styled-components";

export const CourseDescription: FC<{ course: ContentHolder }> = ({ course }) => {
  return <Root dangerouslySetInnerHTML={{ __html: course.content.replace(/\n/g, "<br />") }} />;
};

interface ContentHolder {
  content: string;
}

const Root = styled.p`
  color: #fff;
  display: block;
  line-height: 1.6em;

  @media screen and (max-width: 960px) {
    font-size: 13px;
  }

  @media screen and (min-width: 961px) {
    font-size: 14px;
    line-height: 1.6em;
  }
`;
