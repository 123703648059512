import React, { FC, HTMLAttributes } from "react";
import { useFormikContext } from "formik";
import SelectButton, { SelectGroup } from "./SelectButton";
import styled from "styled-components";

const SpouseAgeSelect: FC<HTMLAttributes<HTMLDivElement> & { label: string; name: string; options: string[] }> = ({
  name,
  label,
  options,
  ...props
}) => {
  const formik = useFormikContext();
  const field = formik.getFieldProps(name);
  return (
    <Root {...props}>
      <Label>{label}</Label>
      <StyledSelectGroup>
        {options.map((age, index) => (
          <StyledSelectButton
            key={index}
            selected={age === field.value}
            onClick={() => {
              formik.setFieldValue(name, age);
            }}
          >
            {age}
          </StyledSelectButton>
        ))}
      </StyledSelectGroup>
    </Root>
  );
};
export default SpouseAgeSelect;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Label = styled.label`
  @media screen and (min-width: 961px) {
    margin-left: 20px;
  }
`;
const StyledSelectGroup = styled(SelectGroup)`
  @media screen and (max-width: 960px) {
    min-width: 180px;
  }
  @media screen and (min-width: 961px) {
    min-width: 356px;
  }
`;

const StyledSelectButton = styled(SelectButton)`
  width: calc(50% - 3px);
`;
