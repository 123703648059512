import React, { FC } from "react";
import Link from "next/link";

import { SUPPORT_TAB } from "../../mypage/MypageTabs";
import styled from "styled-components";

export const NetworkError: FC<{ type: "tipping" | "support" }> = ({ type }) => {
  const actionName = type === "tipping" ? "アニエール" : "応援";
  return (
    <>
      <Title>通信エラーが発生しました</Title>
      <Description>
        {actionName}が完了している可能性がありますので、
        <Link href={`/me?tab=${SUPPORT_TAB}`}>マイページ</Link>
        にてお確かめください。
      </Description>
      <Description>
        完了していなかった場合は、お手数ですが再度{actionName}
        をお願いいたします。
      </Description>
    </>
  );
};

const Title = styled.div`
  font-size: 20px;
  margin: 30px auto;
  text-align: center;
`;
const Description = styled.div`
  margin: 10px 0;
  & a {
    color: blue;
    text-decoration: underline;
  }
`;
