import styled from "@material-ui/core/styles/styled";
import { EndingCountdownExpression } from "lib/project_util";
import React, { FC, HTMLAttributes, useMemo } from "react";
import { OrderCountIcon, PriceIcon, RemainingDaysIcon, StyledLeftText, Unit, Value, WhiteLabel } from "../layout/styled";
import { ssPriceText } from "../../layout/SsStyles";

export const SupportAmountLabel: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => (
  <WhiteLabel {...props}>
    <PriceIcon />
    <StyledLeftText>{children}</StyledLeftText>
  </WhiteLabel>
);

export const OrderCountLabel: FC = (props) => (
  <WhiteLabel>
    <OrderCountIcon />
    <StyledLeftText>{props.children}</StyledLeftText>
  </WhiteLabel>
);

export const RemainingDaysLabel: FC = (props) => (
  <WhiteLabel>
    <RemainingDaysIcon />
    <StyledLeftText>{props.children}</StyledLeftText>
  </WhiteLabel>
);

export const StyledBigValue = styled(Value)(({ theme }) => ({
  ...ssPriceText,
  [theme.breakpoints.up("md")]: {
    lineHeight: "24px",
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    lineHeight: "20px",
    fontSize: "26px",
  },
}));
export const DefaultValue = styled(Value)(({ theme }) => ({
  ...ssPriceText,
  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
}));
export const StyledSmallValue = styled(Value)(({ theme }) => ({
  ...ssPriceText,
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
}));
export const StyledSmallValue2 = styled(Value)(({ theme }) => ({
  ...ssPriceText,
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "10px",
  },
}));

export const StyledValue = styled(Value)(({ theme }) => ({
  ...ssPriceText,
  [theme.breakpoints.down("sm")]: {
    lineHeight: "18px",
    fontSize: "22px",
  },
  [theme.breakpoints.up("md")]: {
    lineHeight: "20px",
    fontSize: "24px",
  },
  [theme.breakpoints.up("lg")]: {
    lineHeight: "22px",
    fontSize: "26px",
  },
}));
export const StyledUnit = styled(Unit)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
  },
}));

export type RemainingDaysValueProps = {
  remaining: EndingCountdownExpression | null;
};
export const RemainingDaysValue: FC<RemainingDaysValueProps> = ({ remaining }) => {
  const remainingValue = useMemo(() => (remaining === null ? "終了" : remaining.data), [remaining]);
  return (
    <>
      <StyledValue>{remainingValue}</StyledValue>
      {remaining && <StyledUnit>{remaining.unit}</StyledUnit>}
    </>
  );
};
