import { Button, Dialog, DialogActions } from "@material-ui/core";
import { useActivation } from "lib/hooks/useActivation";
import { MemberTippingCommentHelper } from "lib/hooks/useMemberTippingComment";
import { TippingOutput } from "lib/service/client/FrontOrderService";
import Link from "next/link";
import React, { FC, useEffect } from "react";
import { StyledCenterText } from "../../project/layout/styled";
import { BlockText, ThanksDialogContent } from "../Styles";
import SwiperCore, { Navigation } from "swiper";
import AvatarSwiper from "../AvatarSwiper";
import { useRealtimeTippingCommentContext } from "lib/hoc/withRealtimeTippingComment";
import styled from "styled-components";
import { Aniale } from "interfaces/front/page/project";

SwiperCore.use([Navigation]);

type Props = {
  isOpen: boolean;
  close: () => void;
  result?: TippingOutput & { amount: number };
  aniale: Aniale;
  memberTippingCommentHelper: MemberTippingCommentHelper;
  projectImageUrl: string;
};

export const AnialeThanksDialog: FC<Props> = (props) => {
  const [closeActivated, activateClose, deactivateClose] = useActivation(false);
  const { setTippingComment } = useRealtimeTippingCommentContext();
  useEffect(() => {
    const closeButtonTimeout = setTimeout(() => {
      if (props.isOpen) {
        activateClose();
      }
    }, 2000);
    if (!props.isOpen) {
      deactivateClose();
    }
    return () => {
      clearTimeout(closeButtonTimeout);
    };
  }, [props.isOpen]);
  return (
    <Dialog onClose={() => closeActivated && props.close()} open={props.isOpen} fullWidth scroll="body">
      <div>
        <HeroImage src={props.projectImageUrl} alt="アニエールプロジェクト画像" />
      </div>
      <ThanksDialogContent style={{ textAlign: "center" }}>
        <TitleArea>
          <Title>アニエールいただき</Title>
          <Title>ありがとうございます！</Title>
        </TitleArea>
        {props.memberTippingCommentHelper.tippingComment?.commenter_donation_amount_reserved && (
          <div>
            <StyledCenterText style={{ fontSize: "12px" }}>
              アニエール総額 : {props.memberTippingCommentHelper.tippingComment?.commenter_donation_amount_reserved.toLocaleString()}円
            </StyledCenterText>
          </div>
        )}
      </ThanksDialogContent>
      {props.result?.presented_avatars && props.result.presented_avatars.length > 0 && (
        <ThanksDialogContent style={{ textAlign: "center" }}>
          <div>
            <AvatarSwiper avatars={props.result.presented_avatars} style={{ margin: "0 auto" }} />
          </div>
          <MessageArea>
            <div>
              <Message>お気持ちとして、</Message>
              <Message>サービス内で利用できる</Message>
            </div>
            <div>
              <Message>新しいプロフィールアイコンを</Message>
              <Message>ご提供いたします。</Message>
            </div>
          </MessageArea>
          <MypageLinkText />
        </ThanksDialogContent>
      )}
      {props.result && !props.result.sent_mail && (
        <ThanksDialogContent>
          <div>
            完了メールをお送りすることができませんでした。 アニエールの詳細についてはお手数ですが
            <Link href={"/me"}>マイページ</Link>をご確認ください。
          </div>
        </ThanksDialogContent>
      )}
      <DialogActions>
        {closeActivated ? (
          <Button
            type="button"
            color="primary"
            variant="outlined"
            fullWidth
            style={{ borderColor: "#888", color: "#888" }}
            onClick={() => {
              props.close();
              if (props.memberTippingCommentHelper.tippingComment) {
                setTippingComment({
                  id: props.memberTippingCommentHelper.tippingComment.id,
                  comment: props.memberTippingCommentHelper.tippingComment.comment,
                  tipping_amount: props.result?.amount || 0,
                  commenter_nickname: props.memberTippingCommentHelper.tippingComment.commenter_nickname,
                });
              }
            }}
          >
            閉じる
          </Button>
        ) : (
          <div style={{ height: "36px" }} />
        )}
      </DialogActions>
    </Dialog>
  );
};

const MypageLinkText: FC = () => {
  return (
    <div style={{ fontSize: "11px" }}>
      <BlockText>
        <Link href={"/me"}>マイページ</Link>で
      </BlockText>
      <BlockText>アイコンを</BlockText>
      <BlockText>変更することができます。</BlockText>
    </div>
  );
};

const TitleArea = styled.div`
  transform-origin: center;
`;
const Title = styled.span`
  line-height: 1.25em;
  display: inline-block;
  font-size: 16px;
`;
const MessageArea = styled.div``;
const Message = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 1.25em;
`;
const HeroImage = styled.img`
  width: 100%;
  height: 100%;
`;
