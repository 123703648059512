import React, { FC, HTMLAttributes } from "react";
import { useFormikContext } from "formik";
import SelectButton, { SelectGroup } from "./SelectButton";
import styled from "styled-components";

const IncomeSelect: FC<HTMLAttributes<HTMLDivElement> & { name: string }> = ({ name }) => {
  const formik = useFormikContext();
  const field = formik.getFieldProps(name);
  return (
    <SelectGroup>
      {INCOME_CLASSES.map((incomeClass, index) => (
        <StyledSelectButton
          key={index}
          selected={incomeClass === field.value}
          onClick={async () => {
            formik.setFieldValue(name, incomeClass);
            await formik.submitForm();
          }}
        >
          {incomeClass}円
        </StyledSelectButton>
      ))}
    </SelectGroup>
  );
};
export default IncomeSelect;

export const INCOME_CLASSES: string[] = [
  "200万",
  "250万",
  "300万",
  "350万",
  "400万",
  "450万",
  "500万",
  "550万",
  "600万",
  "700万",
  "800万",
  "900万",
  "1000万",
  "1500万",
  "2000万",
];

const StyledSelectButton = styled(SelectButton)`
  width: calc(100% / 3 - 3px);
  margin: 3px 0;
`;
