import React from "react";

type Props = {
  text: string;
  type: "button" | "submit";
  disabled?: boolean;
};

// TODO: OnClickの挙動の引数作ってない。テンプレートの「ログイン系のボタン」って言われてるものをパクってるよ
export const RegisterButton1: React.FC<Props> = (props: Props) => (
  <div className="form_submit">
    <div className="link link--submit">
      <div className="link_upper">
        <div className="link_upper--item" />
        <div className="link_upper--item" />
        <div className="link_upper--item" />
        <div className="link_upper--item" />
        <div className="link_upper--item" />
        <div className="link_upper--item" />
        <div className="link_upper_gritch link_upper_gritch_01" />
        <div className="link_upper_gritch link_upper_gritch_02" />
      </div>
      <div className="link_text">
        <input
          value={props.text}
          type={props.type}
          disabled={props.disabled}
          style={{ color: props.disabled ? "#AAA" : "#000" }}
        />
      </div>
      <div className="link_lower">
        <div className="link_lower--item" />
        <div className="link_lower--item" />
        <div className="link_lower--item" />
        <div className="link_lower--item" />
        <div className="link_lower--item" />
        <div className="link_lower--item" />
        <div className="link_lower_gritch link_lower_gritch_01" />
        <div className="link_lower_gritch link_lower_gritch_02" />
      </div>
    </div>
  </div>
);
