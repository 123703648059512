import { NextRouter } from "next/router";
import { UrlObject } from "url";

export const goProjectPage = async (projectCode: string, router: NextRouter): Promise<void> => {
  await router.push("/project/[slug]", `/project/${projectCode}`);
};
export const goErrorPage = async (router: NextRouter): Promise<void> => {
  await router.replace("/error");
  await window.scrollTo(0, 0);
};
export const goLoginPage = async (router: NextRouter, doRedirect = false, doReplace = true): Promise<void> => {
  const url: UrlObject = { pathname: "/login" };
  if (doRedirect) {
    url.query = { original: router.asPath };
    doReplace ? await router.replace(url) : await router.push(url);
  } else {
    await router.push(url);
  }
  await window.scrollTo(0, 0);
};
