import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";

type Props = HTMLAttributes<HTMLDivElement>;
const ErrorBlock: FC<Props> = ({ children, ...props }) => (
  <Root {...props}>
    <Text>{children}</Text>
  </Root>
);
export default ErrorBlock;

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff0000;
  @media screen and (max-width: 960px) {
    padding: 10px;
  }
  @media screen and (min-width: 961px) {
    padding: 20px;
  }
`;
const Text = styled.p`
  color: #fff;
  text-align: center;
  line-height: 1.45em;
`;
