import { useRemainingDays } from "../../../../lib/hooks/useRemainingDays";
import React, { FC, HTMLAttributes } from "react";
import { useRemainingText } from "../../../../lib/hooks/useRemainingText";

// 必ず動的インポートでssrをfalseにすること
export type Props = HTMLAttributes<HTMLSpanElement> & { supportEndAt: string };
const RemainingText: FC<Props> = ({ supportEndAt, ...props }) => {
  const remaining = useRemainingDays(supportEndAt);
  const [remainingText, remainingUnit] = useRemainingText(remaining);
  return (
    <>
      <span {...props} style={{ marginRight: "4px" }}>
        {remainingText}
      </span>
      {remainingUnit}
    </>
  );
};
export default RemainingText;
