import { BaseClientService, ErrorV2, Result } from "../BaseClientService";

export type ComplexSimulatorParameter = {
  income: string;
  family: {
    spouse: {
      age: "69歳以下" | "70歳以上" | "";
      income: string;
    };
    dependants: {
      lte_15: number;
      between_16_and_18: number;
      between_19_and_22: number;
      between_23_and_69: number;
      gte_70_relatives: number;
      gte_70_others: number;
    };
    widow: "" | "ひとり親(男)" | "ひとり親(女)" | "寡婦";
    handicapped: {
      general: number;
      special_separated: number;
      special_together: number;
    };
  };
  conditions: {
    social_insurance: string;
    life_insurance: string;
    medical_insurance: string;
    earthquake_insurance: string;
    residential_loan: string;
    small_enterprise_relief: string;
  };
  support: {
    spouse_flag: string;
    dependants_flag: string;
    widow_flag: string;
    handicapped_flag: string;
  };
};

export type ComplexSimulatorResult = {
  donation_limit_amount: number;
};

export class FrontHometownTaxService extends BaseClientService {
  url: string;

  constructor() {
    super();
    this.url = this.API_BASE_URL + "/api/front/hometown_tax";
  }

  async calculateLimit(query: ComplexSimulatorParameter): Promise<Result<ComplexSimulatorResult, ErrorV2>> {
    const body = JSON.stringify(query);
    return super.doPost<ComplexSimulatorResult, ErrorV2>(`${this.url}/calculate_limit`, body);
  }
}
