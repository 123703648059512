import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import { CourseAnchor } from "lib/hooks/useRewardCourseToc";
import { useActivation } from "lib/hooks/useActivation";

type Props = HTMLAttributes<HTMLDivElement> & {
  anchors: CourseAnchor[];
  color: "black" | "white";
};
const RewardCourseToc: FC<Props> = ({ anchors, color, ...props }) => {
  const [opened, open, close] = useActivation(false);
  const toggleOpened = () => {
    opened ? close() : open();
  };
  const scrollAndClose = (scroll: () => void) => () => {
    scroll();
    close();
  };
  return anchors.length > 0 ? (
    <Root {...props}>
      <Button onClick={toggleOpened} $color={color}>
        気になるコースに飛ぶ{opened ? " ▲ " : " ▼ "}
      </Button>
      <AnchorArea $opened={opened} $color={color}>
        {anchors.map((anchor, index) => (
          <Anchor key={index} onClick={scrollAndClose(anchor.handler)}>
            {anchor.label}
          </Anchor>
        ))}
      </AnchorArea>
    </Root>
  ) : (
    <></>
  );
};
export default RewardCourseToc;

const Root = styled.div`
  position: relative;
  @media screen and (max-width: 960px) {
    font-size: 12px;
  }
  @media screen and (min-width: 961px) {
    font-size: 14px;
  }
`;

const Button = styled.button<{ $show?: boolean; $color: "white" | "black" }>`
  display: inline-block;
  border: 1px solid ${({ $color }) => ($color == "black" ? "white" : "black")};
  background-color: ${({ $color }) => $color};
  color: ${({ $color }) => ($color == "black" ? "white" : "black")};
  border-radius: 20px;
  padding: 0.4em 10px;
  line-height: 1;
`;

const Anchor = styled.div`
  text-align: left;
  display: block;
  line-height: 1.1em;
  padding: 0.8em;
  cursor: pointer;
`;

const AnchorArea = styled.div<{ $opened?: boolean; $color: "white" | "black" }>`
  display: ${({ $opened }) => ($opened ? "block" : "none")};
  position: absolute;
  top: 2.6em;
  right: 0;
  z-index: 3;
  background-color: ${({ $color }) => ($color == "black" ? "rgba(0,0,0,0.8)" : "rgba(255,255,255,0.9)")};
  border: 1px solid ${({ $color }) => ($color == "black" ? "white" : "black")};
  border-radius: 1em;
  padding: 0.4em;
  transition: height 0.2s;

  @media screen and (max-width: 960px) {
    min-width: 60vw;
    max-width: 90vw;
  }
  @media screen and (min-width: 961px) {
    min-width: 500px;
    max-width: 100%;
  }

  & > ${Anchor} {
    color: ${({ $color }) => ($color == "black" ? "white" : "black")};
  }
`;
