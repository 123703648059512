import { Ref, useEffect, useRef } from "react";
import useTwitterWidget from "lib/hooks/front/useTwitterWidget";

const useTwitterShareButton = (url: string, options: object): Ref<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement>(null);
  const [loaded, getTwitterWidget] = useTwitterWidget();
  useEffect(() => {
    let isCanceled = false;
    if (ref.current && loaded) {
      const twitterWidget = getTwitterWidget();
      ref.current.querySelectorAll("*").forEach((child) => {
        if (child.hasAttribute("twdiv")) child.remove();
      });
      (async function () {
        if (!ref?.current) return;
        const childEl = document.createElement("div");
        childEl.setAttribute("twdiv", "yes");
        ref.current.appendChild(childEl);
        try {
          const resultMaybe = await twitterWidget?.createShareButton(url, childEl, { ...options }); // twitterのAPIが引数を変化させちゃう可能性があるらしいのでoptionsをcloneしてる
          if (!isCanceled && !resultMaybe)
            console.error("Twitter could not create widget. If it is a Timeline or Tweet, ensure the screenName/tweetId exists.");
        } catch (e) {
          console.error(e);
          return;
        }
        if (ref && ref.current && isCanceled && childEl) {
          childEl.remove();
        }
      })();
    }
    return () => {
      isCanceled = true;
    };
  }, [url, options, loaded]);
  return ref;
};
export default useTwitterShareButton;
