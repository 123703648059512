import React, { FC } from "react";
import styled from "styled-components";
import useBirthdayFieldHandler from "lib/hooks/front/useBirthdayFieldHandler";

export type Props = {
  label: string;
  name: string;
  version?: 1 | 2;
};

export const BirthdayField: FC<Props> = ({ label, name, version = 1 }: Props) => {
  const { years, months, days, year, month, day, onYearChange, onMonthChange, onDayChange } = useBirthdayFieldHandler(name, "1990-01-01");
  return (
    <div className="form--item">
      {version === 2 ? <LabelArea>{label}</LabelArea> : <p className="form--item_name form--item_birthday">{label}</p>}
      <div className="form--item_select">
        <div className="form--item_select_year">
          <Select value={year} onChange={onYearChange}>
            {years.map((y, idx) => (
              <option value={y} key={idx}>
                {y}
              </option>
            ))}
          </Select>
          <Unit>年</Unit>
        </div>
        <div className="form--item_select_month">
          <Select value={month} onChange={onMonthChange}>
            {months.map((m, idx) => (
              <option value={m} key={idx}>
                {m}
              </option>
            ))}
          </Select>
          <Unit>月</Unit>
        </div>
        <div className="form--item_select_day">
          <Select value={day} onChange={onDayChange}>
            {days.map((d, idx) => (
              <option value={d} key={idx}>
                {d}
              </option>
            ))}
          </Select>
          <Unit>日</Unit>
        </div>
      </div>
    </div>
  );
};

const LabelArea = styled.p`
  width: 100%;
  border-left: 6px solid #1a1a1a;
  padding: 5px 5px 5px 10px;
  margin-bottom: 8px;
`;
const Select = styled.select`
  color: black;
  margin: auto;
  height: min-content;
`;
const Unit = styled.span`
  padding-right: 6px;
`;
