import styled from "styled-components";
import React, { FC, HTMLAttributes } from "react";

export const TabList = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Tab = styled.div<{ active: boolean }>`
  border-top-right-radius: 16px;
  padding: 10px 5px;
  flex-basis: 50%;
  text-align: center;
  line-height: 1.428em;
  cursor: pointer;

  :first-child {
    margin-right: 1px;
  }
`;

export const TabContent = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? "block" : "none")};
  @media screen and (max-width: 960px) {
    padding: 10px;
  }
  @media screen and (min-width: 961px) {
    padding: 30px;
  }
`;

export const Box: FC<HTMLAttributes<HTMLDivElement> & { subject: string; color: string }> = ({ subject, color, children, ...props }) => {
  return (
    <BoxContainer color={color} {...props}>
      <BoxHeadline color={color}>{subject}</BoxHeadline>
      <Content>{children}</Content>
    </BoxContainer>
  );
};
const BoxContainer = styled.div<{ color: string }>`
  position: relative;
  border: 2px solid ${({ color }) => color};
  border-radius: 16px;
  display: flex;
  justify-content: center;
`;
const BoxHeadline = styled.h6<{ color: string }>`
  margin: 0 auto;
  border-radius: 20px;
  background-color: ${({ color }) => color};
  padding: 5px 20px;
  position: absolute;
  top: calc(-0.5em - 5px);
  color: #fff;
  @media screen and (min-width: 961px) {
    min-width: 200px;
    text-align: center;
  }
`;
const Content = styled.div`
  padding: calc(0.5em + 5px + 10px) 10px 10px;
  width: 100%;
`;
