import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { useFormikContext } from "formik";
import { CartItem, OrderItemInput } from "interfaces/front/page/orderForm";
import OrderCourseArea from "components/front/order/OrderCourseArea";
import { Project } from "interfaces/front/business";
import { CourseOptionArea } from "components/front/order/CourseOptionArea";

export type Props = {
  name: string;
  project: Project;
  cartItems: CartItem[];
  isShopping?: boolean;
};
const OrderItemField: FC<Props> = ({ name, project, cartItems, isShopping }) => {
  const formik = useFormikContext();
  const field = formik.getFieldProps<OrderItemInput>(name);
  const cartItem = useMemo(() => cartItems.find((cartItem) => cartItem.course.id == field.value.course_id)!!, [field.value]);
  const course = useMemo(() => cartItem.course, [cartItem]);
  const hasCourseOption = useMemo(() => course.course_options.length > 0, [course]);
  const hasMultipleDetails = useMemo(() => field.value.details.length > 1, [field.value]);
  return (
    <Root>
      {course.stock_flag && (
        <Notation>
          あと<strong>{course.remained_quantity}個</strong>購入できます
        </Notation>
      )}
      <OrderCourseArea course={course} quantity={cartItem.quantity} isShopping={isShopping ?? false} />
      {hasCourseOption && (
        <>
          <hr />
          <DetailsArea>
            {field.value.details.map((detail, index) => (
              <DetailArea key={index}>
                {hasMultipleDetails && <div>{index + 1}個目のオプション</div>}
                <div>
                  {detail.course_options.map((_, optionIndex) => (
                    <CourseOptionArea
                      key={optionIndex}
                      name={`${name}.details[${index}].course_options[${optionIndex}]`}
                      options={course.course_options}
                      supportEndAt={project.support_end_at}
                    />
                  ))}
                </div>
              </DetailArea>
            ))}
          </DetailsArea>
        </>
      )}
    </Root>
  );
};
export default OrderItemField;

const Root = styled.div``;
const Notation = styled.div`
  padding: 10px;
  background-color: #ccc;

  & strong {
    color: #e74c3c;
  }
`;
const DetailsArea = styled.div``;
const DetailArea = styled.div`
  margin-top: 20px;
`;
