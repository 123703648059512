import React, { FC, InputHTMLAttributes } from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import { BASE_COLOR1, BORDER_RADIUS, MAIN_COLOR2 } from "../theme";

const AmountInput: FC<InputHTMLAttributes<HTMLInputElement> & { unit: string }> = ({ unit, ...props }) => (
  <Root>
    <RawInput {...props} type="tel" thousandSeparator={true} />
    <Suffix>{unit}</Suffix>
  </Root>
);
export default AmountInput;

const Root = styled.div`
  border: 1px solid ${BASE_COLOR1};
  ${BORDER_RADIUS};
  display: flex;
  justify-content: flex-end;
  &:focus-within {
    border: 1px solid ${MAIN_COLOR2};
  }
  @media screen and (max-width: 960px) {
    align-items: center;
    padding: 5px 10px;
  }
  @media screen and (min-width: 961px) {
    align-items: baseline;
    padding: 7px 20px;
  }
`;
const RawInput = styled(NumberFormat)`
  padding: 1px;
  border: none;
  text-align: ${(props) => (props.type === "number" ? "right" : "left")};
  width: 100%;
`;
const Suffix = styled.span``;
