import styled from "@material-ui/core/styles/styled";

// 右下が三角に欠けてるデザインを作るための素材
export const WhiteTriangleLacking = styled("div")(() => ({
  "&:before": {
    content: "''",
    width: "5px",
    height: "calc(100% - 5px)",
    position: "absolute",
    top: "0",
    right: "-5px",
    backgroundColor: "#fff",
  },
  "&:after": {
    content: "''",
    borderStyle: "solid",
    borderWidth: "5px 5px 0 0",
    borderColor: "#fff transparent transparent transparent",
    position: "absolute",
    bottom: "0",
    right: "-5px",
  },
}));
