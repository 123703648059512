import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import { imagePath } from "../image/util";

const FieldBox: FC<HTMLAttributes<HTMLDivElement> & { subject: string }> = ({ subject, children, ...props }) => {
  return (
    <Container {...props}>
      <Headline>{subject}</Headline>
      <Content>{children}</Content>
    </Container>
  );
};
export default FieldBox;

const Container = styled.div`
  margin-top: calc(0.5em + 5px);
  position: relative;
  border: 2px solid #000;
  border-radius: 16px;
  display: flex;
  justify-content: center;
`;
const Headline = styled.h6`
  text-align: center;
  border-radius: 20px;
  padding: 6px 0;
  position: absolute;
  top: calc(-0.5em - 5px);
  color: #fff;
  @media screen and (max-width: 960px) {
    background: url(${imagePath("/calculation/fieldset-legend-sp.png")}) no-repeat;
    background-size: 200px 26px;
    width: 200px;
    height: 26px;
  }
  @media screen and (min-width: 961px) and (max-width: 1440px) {
    background: url(${imagePath("/calculation/fieldset-legend-pc.png")}) no-repeat;
    background-size: 220px 28px;
    width: 220px;
    height: 28px;
  }
  @media screen and (min-width: 1441px) {
    background: url(${imagePath("/calculation/fieldset-legend-pc.png")}) no-repeat;
    width: 240px;
    height: 31px;
    background-size: 240px 31px;
  }
`;
const Content = styled.div`
  width: 100%;
  @media screen and (max-width: 960px) {
    padding: calc(0.5em + 5px + 10px) 10px 10px;
  }
  @media screen and (min-width: 961px) {
    padding: calc(0.5em + 5px + 20px) 20px 20px;
  }
`;
