import { WithStyles } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import React, { FC, forwardRef, HTMLAttributes, Ref, useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import useContentSwiper from "lib/hooks/useContentSwiper";

export type Props = {
  threshold: number;
  noClose?: boolean;
  moreText?: string;
  color?: Color;
  Button?: typeof SeeMoreButton;
};
const SeeMore: FC<Props & HTMLAttributes<HTMLDivElement> & Partial<WithStyles<ClassKey>>> = ({ ...props }) => {
  const [shows, setShows] = useState(false);
  const holder = useRef<HTMLDivElement>(null);
  const loadSwiper = useContentSwiper(holder);
  const onToggleShows = () => {
    setShows(!shows);
    loadSwiper().catch(console.error);
  };
  return <SeeMoreCore ref={holder} shows={shows} onToggleShows={onToggleShows} {...props} />;
};
export default SeeMore;

export const SeeMoreCore = forwardRef<
  HTMLDivElement,
  Props & HTMLAttributes<HTMLDivElement> & { shows: boolean; onToggleShows: () => void }
>(({ threshold, noClose = false, moreText, children, shows, onToggleShows, color = "black", Button = SeeMoreButton, ...props }, ref) => {
  const [contentText, shouldUseSeeMore] = useShouldHide(threshold);
  const collapseOpened = useMemo(() => shows || !shouldUseSeeMore, [shows, shouldUseSeeMore]);
  const showsButton = useMemo(() => shouldUseSeeMore && !(noClose && shows), [shows, noClose, shouldUseSeeMore]);
  return (
    <>
      <Root ref={ref} {...props} $threshold={threshold}>
        <Collapse in={collapseOpened} timeout={300} collapsedHeight={threshold}>
          <Content ref={contentText} $threshold={threshold}>
            {children}
          </Content>
        </Collapse>
        <Shade $color={color} $threshold={threshold} $shows={collapseOpened} />
      </Root>
      {showsButton && (
        <Button {...props} $color={color} onClick={onToggleShows}>
          {collapseOpened ? "閉じる" : moreText ?? "もっと見る"}
        </Button>
      )}
    </>
  );
});

const useShouldHide = (threshold: number): [Ref<HTMLDivElement>, boolean] => {
  const contentText = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>();
  useEffect(() => setHeight(contentText.current?.offsetHeight), []);
  const shouldUseSeeMore = useMemo<boolean>(() => (height ? height > threshold : true), [height]);
  return [contentText, shouldUseSeeMore];
};

export type ClassKey = "button";

const Root = styled.div<{ $threshold: number }>`
  position: relative;
  min-height: ${({ $threshold }) => $threshold}px;
`;
const colorPattern: Record<Color, number> = { black: 16, gray: 60, white: 255 };
const Shade = styled.div<{ $threshold: number; $shows: boolean; $color: Color }>`
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ $threshold }) => $threshold}px;
  display: ${({ $shows }) => ($shows ? "none" : "block")};
  position: absolute;
  ${({ $color }) => {
    const digit = colorPattern[$color];
    return css`
      background: linear-gradient(
        to bottom,
        rgba(${digit}, ${digit}, ${digit}, 0) 0%,
        rgba(${digit}, ${digit}, ${digit}, 0) 70%,
        rgba(${digit}, ${digit}, ${digit}, 0.9) 95%,
        rgba(${digit}, ${digit}, ${digit}, 1) 100%
      );
    `;
  }}
  z-index: 2;
`;
const Content = styled.div<{ $threshold: number }>`
  min-height: ${({ $threshold }) => $threshold}px;
`;
export const SeeMoreButton = styled.p<{ $color: Color }>`
  margin-top: 10px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  color: ${({ $color }) => ($color === "white" ? "black" : "white")};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  @media screen and (max-width: 960px) {
    font-size: 12px;
  }
  @media screen and (min-width: 961px) {
    font-size: 14px;
  }
`;

type Color = "white" | "black" | "gray";
