import { PaymentTransactionStatus } from "interfaces/admin/business";
import { CvsDetail, CvsType, FamilyMartDetail, OtherCvsDetail, SevenElevenDetail } from "interfaces/front/business";
import { CVS_STORES } from "./constants";
import { OrderErrorDetail } from "./service/BaseClientService";

export const toStringPaymentStatus = (status: PaymentTransactionStatus): string => {
  switch (status) {
    case "requested":
      return "未入金";
    case "captured":
      return "入金済み";
    case "canceled":
      return "キャンセル済み";
  }
};

export const toStringOrderError = (orderErrorDetail: OrderErrorDetail): string => {
  switch (orderErrorDetail.code) {
    case "stock_out":
      return "在庫切れになったため応援することができませんでした";
    case "stock_not_enough":
      return "在庫が足りなかったため応援することができませんでした";
    case "had_ended":
    case "course_expired":
      return "応援期間が終了したため応援できませんでした";
    case "not_start_yet":
    case "course_not_available":
      return "応援期間外のため応援できませんでした";
    case "undeliverable":
      return "対象のお届け先に配送不可能なため応援できませんでした。";
    case "payment":
      const creditMessage =
        "【クレジットカードの場合】\nカード番号間違い、セキュリティコード間違い、限度額オーバーの可能性がございます。\n" +
        "再度お確かめください。\n" +
        "また、上記で解決しない場合「カード会社が不正な高額利用」の疑いで\n" +
        "お支払い出来ないようにしている可能性がございます。\n" +
        "その場合はカード会社へお電話し確認頂けますと幸いです。";
      return `指定された入力情報で決済処理を行うことができませんでした。お手数ですが他のカードや別の決済方法をご選択ください。\n\n${creditMessage}`;
    case "invalid_coupon":
      return "対象のクーポンが利用不可能なため応援できませんでした。"
    case "duplicated_course":
      return "選択されたコースは1会員1個までとなります。"
    default:
      return "応援の処理でエラーが発生しました"; // TODO: どのような文言にするか
  }
};

export const toStringCvsStore = (cvs_type: CvsType): string => {
  return CVS_STORES.find((store) => store.name === cvs_type)?.label || "不明";
};

export interface CvsAdditionalInfo {
  label: string;
  value: string;
  isLink: boolean;
}

export const getCvsInfos = (cvsDetail: CvsDetail, withStore: boolean): CvsAdditionalInfo[] => {
  const store = withStore
    ? [
        {
          label: "コンビニ",
          value: toStringCvsStore(cvsDetail.cvs_type),
          isLink: false,
        },
      ]
    : [];
  switch (cvsDetail.cvs_type) {
    case "seven_eleven":
      return store.concat([
        {
          label: "払込票番号",
          value: (cvsDetail as SevenElevenDetail).slip_number,
          isLink: false,
        },
        {
          label: "払込票URL",
          value: (cvsDetail as SevenElevenDetail).slip_url,
          isLink: true,
        },
      ]);
    case "family_mart":
      return store.concat([
        {
          label: "企業コード",
          value: (cvsDetail as FamilyMartDetail).company_code,
          isLink: false,
        },
        {
          label: "注文番号",
          value: (cvsDetail as FamilyMartDetail).order_number,
          isLink: false,
        },
      ]);
    default:
      return store.concat([
        {
          label: "受付番号",
          value: (cvsDetail as OtherCvsDetail).receipt_number,
          isLink: false,
        },
        {
          label: "電話番号",
          value: (cvsDetail as OtherCvsDetail).phone_number,
          isLink: false,
        },
      ]);
  }
};
