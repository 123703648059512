import React, { FC, useContext } from "react";
import { FieldProps } from "formik";
import { OrderCreateInput } from "lib/service/client/FrontOrderService";
import EnhancedEcommerceContext from "lib/contexts/EnhancedEcommerceContext";
import { useActivation } from "lib/hooks/useActivation";
import { CorporateAddress } from "interfaces/front/business";
import GrayBlock from "components/front/order/literal/GrayBlock";
import { CorporateAddressCard } from "components/front/order/recipient/RecipientCard";
import { SsDialogOpenButton } from "components/front/layout/SsDialogOpenButton";
import styled from "styled-components";
import ErrorBlock from "components/front/order/literal/ErrorBlock";
import dynamic from "next/dynamic";
import { Props as CorporateAddressDialogProps } from "components/front/recipient/CorporateAddressDialog";

const CorporateAddressDialog = dynamic<CorporateAddressDialogProps>(() => import("components/front/recipient/CorporateAddressDialog"));

export type Props = FieldProps<OrderCreateInput> & { courseIds: number[] };
const CorporateAddressField: FC<Props> = ({ field, form: { values, errors, setFieldValue }, courseIds }) => {
  const { triggerRecipientEvent } = useContext(EnhancedEcommerceContext.OrderForm);
  const [isOpen, open, close] = useActivation(false);
  const recipient = values[field.name];
  const openDialog = () => {
    open();
    triggerRecipientEvent().catch(console.error);
  };
  const onInput = (inputAddress?: CorporateAddress): void => {
    if (inputAddress) {
      setFieldValue(field.name, inputAddress);
    }
    close();
  };
  return (
    <>
      <GrayBlock>お届け先は注文確定するまで保存されません。</GrayBlock>
      <div>
        {errors[field.name] ? (
          <StyledErrorBlock>お届け先を入力してください</StyledErrorBlock>
        ) : (
          <CorporateAddressCard address={recipient} />
        )}
        <SsDialogOpenButton label="お届け先を入力する" onClick={openDialog} margin="20px auto" />
        <CorporateAddressDialog isOpen={isOpen} recipient={recipient} closeHandle={onInput} courseIds={courseIds} />
      </div>
    </>
  );
}
export default CorporateAddressField;

const StyledErrorBlock = styled(ErrorBlock)`
  margin: 14px 0;
`;
