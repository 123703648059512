import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { Form, Formik } from "formik";
import { CorporateAddress } from "interfaces/front/business";
import React, { FC, useCallback, useMemo } from "react";
import { FrontDialogTitle } from "../Form/FrontDialogTitle";
import { TextField, TwoColumn } from "../layout/DialogFields";
import { SearchAddressButton } from "../order/recipient/SearchAddressButton";
import useDeliverable from "lib/hooks/front/useDeliverable";
import styled from "styled-components";
import { corporateAddressSchema } from "lib/validation/recipientSchemas";

export type Props = {
  isOpen: boolean;
  closeHandle: (recipient?: CorporateAddress) => void;
  recipient: CorporateAddress | null;
  submitLabel?: string;
  courseIds: number[];
};

const CorporateAddressDialog: FC<Props> = ({ recipient, closeHandle, isOpen, submitLabel, courseIds }) => {
  const { undeliverableText, initialValues, validationSchema, onClose, onSubmit } = useAddressDialogStates(
    recipient,
    courseIds,
    closeHandle
  );
  return (
    <Dialog fullWidth onClose={onClose} open={isOpen} scroll="body">
      <Formik<CorporateAddress> initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, setFieldValue, isSubmitting, dirty }): React.ReactNode => (
          <Form>
            <FrontDialogTitle title="お届け先の編集" onClose={(): void => closeHandle(undefined)} />
            <DialogContent>
              <TextField fullWidth label="会社名" name="corporate_name" placeholder="アニメファンド株式会社" />
              <TextField fullWidth label="部署名" name="corporate_department" placeholder="営業部" />
              <TwoColumn>
                <TextField label="担当者名(姓)" name="name_sei" placeholder="秋葉" />
                <TextField label="担当者名(名)" name="name_mei" placeholder="太郎" />
              </TwoColumn>
              <TwoColumn>
                <TextField label="カナ(姓)" name="kana_sei" placeholder="アキバ" />
                <TextField label="カナ(名)" name="kana_mei" placeholder="タロウ" />
              </TwoColumn>
              <TextField fullWidth label="電話番号(ハイフンなし)" name="phone_number" placeholder="09012345678" />
              <TextField fullWidth label="郵便番号(ハイフンなし)" name="zip_code" placeholder="1010021" />
              <SearchAddressButton values={values} setFieldValue={setFieldValue} />
              <TextField fullWidth label="都道府県" name="prefectures" placeholder="東京都" />
              <TextField fullWidth label="市区町村" name="city" placeholder="千代田区" />
              <TextField fullWidth label="以降の住所" name="house_number" placeholder="外神田〇〇〇番地◯丁目" />
              <TextField fullWidth label="建物名" name="building_name" placeholder="〇〇マンション 101" />
              {undeliverableText && <ErrorMessage>{undeliverableText}</ErrorMessage>}
            </DialogContent>
            <DialogActions>
              <Button disabled={isSubmitting || !dirty} variant={"contained"} type="submit" color="primary" fullWidth>
                {submitLabel ? submitLabel : "決定"}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
export default CorporateAddressDialog;

const useAddressDialogStates = (
  recipient: CorporateAddress | null,
  courseIds: number[],
  closeHandle: (recipient?: CorporateAddress) => void
) => {
  const { undeliverableText, resetUndeliverable, checkDeliverable } = useDeliverable(courseIds);
  const initialValues = recipient ?? {
    name_sei: "",
    name_mei: "",
    kana_sei: "",
    kana_mei: "",
    phone_number: "",
    zip_code: "",
    prefectures: "",
    city: "",
    house_number: "",
    building_name: "",
    corporate_name: "",
    corporate_department: "",
  };
  const validationSchema = useMemo(() => corporateAddressSchema, []);
  const onSubmit = useCallback(
    async (values: CorporateAddress) => {
      const deliverable = await checkDeliverable(values);
      if (deliverable) {
        closeHandle(values);
        resetUndeliverable();
      }
    },
    [closeHandle, checkDeliverable]
  );
  const onClose = useCallback(() => {
    closeHandle(undefined);
    resetUndeliverable();
  }, [closeHandle, resetUndeliverable]);
  return { undeliverableText, initialValues, validationSchema, onSubmit, onClose };
};

const ErrorMessage = styled.div`
  color: red;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 14px;
`;
