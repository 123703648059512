import React, { FC, HTMLAttributes } from "react";
import { useFormikContext } from "formik";
import SelectButton, { SelectGroup } from "./SelectButton";
import styled from "styled-components";

const WidowSelect: FC<HTMLAttributes<HTMLDivElement> & { name: string; options: { label: string; value: string }[] }> = ({
  name,
  options,
  ...props
}) => {
  const formik = useFormikContext();
  const field = formik.getFieldProps(name);
  return (
    <Root {...props}>
      <StyledSelectGroup>
        {options.map((option, index) => (
          <StyledSelectButton
            key={index}
            selected={option.value === field.value}
            onClick={() => {
              formik.setFieldValue(name, option.value);
            }}
          >
            {option.label}
          </StyledSelectButton>
        ))}
      </StyledSelectGroup>
    </Root>
  );
};
export default WidowSelect;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledSelectButton = styled(SelectButton)`
  width: calc(100% / 3 - 3px);
`;
const StyledSelectGroup = styled(SelectGroup)`
  width: 100%;
`;
