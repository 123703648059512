import React, { FC, useMemo } from "react";
import { Formik } from "formik";
import StyledForm from "components/admin/project/layouts/StyledForm";
import FieldRow from "components/admin/project/layouts/FieldRow";
import DateField from "components/admin/layouts/form/DateField";
import SelectField from "components/admin/project/fields/SelectField";
import styled from "styled-components";
import TextareaField from "components/admin/project/fields/TextareaField";
import { EditableNews, NewNews } from "interfaces/admin/page/corporate_news";
import ActionArea from "components/admin/project/layouts/ActionArea";
import { FormikHelpers } from "formik/dist/types";
import { date, object, string } from "yup";
import { AdminCorporateNewsEditService } from "lib/service/client/page/AdminCorporateNewsEditService";
import { useAdminAuthContext } from "lib/hoc/withAdminAuth";
import { errorToast, infoToast } from "lib/toast";
import { useRouter } from "next/router";

export type Props = { news: EditableNews | NewNews, onSave?: () => void };
const NewsEditSection: FC<Props> = ({ news, onSave }) => {
  const account = useAdminAuthContext();
  const router = useRouter();
  const isEdit = useMemo(() => !!news?.id, [news]);
  const handleEdit = async (values: EditNewsForm, helper: FormikHelpers<EditNewsForm>) => {
    if (!account) return;
    const result = await new AdminCorporateNewsEditService(account.administrator_type).putNews(values);
    if (result.isSuccess()) {
      infoToast("ニュースを保存しました。");
      if (onSave) await onSave();
    } else {
      errorToast("ニュースの保存に失敗しました。");
    }
    await helper.setSubmitting(false);
  };
  const handleDelete = async () => {
    if (!confirm("このニュースを削除します。よろしいでしょうか。")) return;
    if (!account || !news.id) return;
    const result = await new AdminCorporateNewsEditService(account.administrator_type).deleteNews(news.id);
    if (result.isSuccess()) {
      await router.replace("/admin/operation/corporate_news");
      infoToast("ニュースを削除しました。");
    } else {
      errorToast("ニュースの削除に失敗しました。");
    }
  };
  return (
    <Root>
      {isEdit && (
        <DistantArea>
          <DeleteButton type="button" onClick={handleDelete}>
            削除
          </DeleteButton>
        </DistantArea>
      )}
      <Formik<EditNewsForm> initialValues={news} validationSchema={validationSchema} onSubmit={handleEdit}>
        {({ isSubmitting }) => (
          <StyledForm action="">
            <FieldRow>
              <DateField name="announce_at" label="日付" />
              <SelectField name="category" label="カテゴリ" options={CATEGORY_OPTIONS} />
            </FieldRow>
            <FieldRow>
              <TextareaField name="content" label="内容" fullWidth />
            </FieldRow>
            <ActionArea>
              <button disabled={isSubmitting} type="submit">
                保存
              </button>
            </ActionArea>
          </StyledForm>
        )}
      </Formik>
    </Root>
  );
};
export default NewsEditSection;

const validationSchema = object().shape({
  announce_at: date().required("必須項目です"),
  category: string().required("必須項目です"),
  content: string().required("必須項目です").max(5000, "5000文字以内で入力してください。"),
});

const CATEGORY_OPTIONS = [
  {
    value: "notification",
    label: "お知らせ",
  },
  {
    value: "press_release",
    label: "プレスリリース",
  },
];

const Root = styled.div`
  position: relative;
`;
const DistantArea = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  margin-right: auto;
`;

type EditNewsForm = {
  id: number | null;
  announce_at: string;
  category: "notification" | "press_release";
  content: string;
};
