import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import UpFade from "../motion/UpFade";
import useSmoothAnchor from "../../../lib/hooks/useSmoothAnchor";
import styled from "styled-components";

export type Props = Record<string, never>;
const BackToTop: FC<HTMLAttributes<HTMLDivElement> & Props> = ({ ...props }) => {
  const [shown, setShown] = useState<boolean>(false);
  useEffect(() => {
    let lastScrollTop = 0;
    const onScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setShown(lastScrollTop > scrollTop && scrollTop > 70);
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };
    document.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);
  useSmoothAnchor("page_top");
  return (
    <Root {...props}>
      <UpFade in={shown}>
        <Button href="#page_top">
          <KeyboardArrowUpIcon style={{ color: "white" }} />
        </Button>
      </UpFade>
    </Root>
  );
};
export default BackToTop;

const Root = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 20px;
  right: 20px;
  @media screen and (min-width: 961px) {
    display: none;
  }
`;
const Button = styled.a`
  display: inline-block;
  border: 1px solid #fff;
  background-color: black;
  border-radius: 20px;
  padding: 3px 4px;
`;
