import React, { FC, useState } from "react";
import { Formik } from "formik";
import FieldBox from "./FieldBox";
import Result from "./Result";
import Annotation from "./Annotation";
import styled from "styled-components";
import IncomeSelect, { INCOME_CLASSES } from "./form/IncomeSelect";
import FamilySelect, { FAMILY_TYPES } from "./form/FamilySelect";
import { useAmount2 } from "lib/hooks/useAmount2";
import { calcDonationLimit, EasySimulatorParameter } from "lib/hooks/useEasyDonationLimit";

const EasyLimitSimulator: FC = () => {
  const [donationLimit, setDonationLimit] = useState<number>(calcDonationLimit(INCOME_CLASSES[0], FAMILY_TYPES[0]));
  const donationLimitFormat = useAmount2(donationLimit);
  return (
    <Formik<EasySimulatorParameter>
      initialValues={{
        income: INCOME_CLASSES[0],
        family: FAMILY_TYPES[0],
      }}
      onSubmit={(values) => {
        const result = calcDonationLimit(values.income, values.family);
        setDonationLimit(result);
      }}
    >
      {({ values }) => (
        <>
          <FieldGroup>
            <StyledFieldBox subject="年収を選択">
              <IncomeSelect name="income" />
            </StyledFieldBox>
            <StyledFieldBox subject="家族構成を選択">
              <FamilySelect name="family" />
              <Note>配偶者の給与収入が201万円以上の方は「配偶者控除なし」になります。</Note>
            </StyledFieldBox>
          </FieldGroup>
          <StyledResult amount={donationLimitFormat} ambiguous={values.family.includes("既婚")} />
          <StyledAnnotation
            texts={[
              "表示している金額は、住宅ローン控除や医療費控除等、他の控除を受けていない給与所得者のケースとなります。" +
                "年金収入のみの方や事業者の方、住宅ローン控除や医療費控除等、他の控除を受けている給与所得者の方の控除額上限は表とは異なりますのでご注意ください。",
              "社会保険料控除額について、給与収入の15%と仮定しています。",
              "表示している金額はあくまで目安です。具体的な計算については「詳細版」のシミュレータをご使用ください。",
            ]}
          />
        </>
      )}
    </Formik>
  );
};
export default EasyLimitSimulator;

const FieldGroup = styled.div`
  @media screen and (min-width: 961px) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
`;
const StyledFieldBox = styled(FieldBox)`
  margin-bottom: 26px;
  @media screen and (min-width: 961px) {
    flex-basis: calc(50% - 7.5px);
    flex-grow: 0;
  }
`;
const StyledAnnotation = styled(Annotation)`
  margin: 15px auto 0;
  max-width: 720px;
  font-size: 0.8em;
`;
const Note = styled.div`
  margin-top: 5px;
  font-size: 0.7em;
  text-align: center;
  width: 100%;
  line-height: 1.428em;
`;
const StyledResult = styled(Result)`
  @media screen and (min-width: 961px) {
    margin-top: 20px;
  }
`;
