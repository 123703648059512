import React, { FC, useMemo } from "react";
import styled from "styled-components";

const BankInfoArea: FC<{ bankDetail: BankDetail }> = ({ bankDetail }) => {
  const infos = useMemo(
    () => [
      { label: "銀行名", value: bankDetail.bank },
      { label: "支店名", value: bankDetail.branch },
      { label: "口座種別", value: bankDetail.type },
      { label: "口座番号", value: bankDetail.number },
      { label: "口座名義", value: bankDetail.holder },
    ],
    [bankDetail]
  );
  return (
    <Root>
      <span>銀行振込にてお支払いを完了して下さい。</span>
      <span>お支払いの際に以下の情報が必要となります。</span>
      <StyledTable>
        <tbody>
          {infos.map((info, index) => (
            <tr key={index}>
              <RowHead>{info.label}</RowHead>
              <RowBody>{info.value}</RowBody>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </Root>
  );
};
export default BankInfoArea;

const Root = styled.div`
  margin: 20px auto;
  max-width: 440px;
  line-height: 1.428em;
  @media screen and (min-width: 1281px) {
    max-width: 800px;
  }
`;
const StyledTable = styled.table`
  margin: 20px 0;
  width: 100%;
  border: solid 1px #ddd;
`;

const RowHead = styled.td`
  padding: 10px;
  border: solid 1px #ddd;
  background-color: #eee;
  width: 100px;
  @media screen and (min-width: 1281px) {
    width: 200px;
  }
`;

const RowBody = styled.td`
  padding: 10px;
  border: solid 1px #ddd;
  line-break: anywhere;
`;

interface BankDetail {
  bank: string;
  branch: string;
  type: string;
  number: string;
  holder: string;
}
