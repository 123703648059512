import { residenceSchema } from "lib/validation/recipientSchemas";
import * as Yup from "yup";
import { object, ObjectSchema, string } from "yup";
import { OnestopDeliverable } from "interfaces/front/business";
import { onestopFlagSchema } from "./onestopFlagSchema";

export const anialeFormSchema = (isHometownTax: boolean, onestopDeliverable: OnestopDeliverable): ObjectSchema => {
  return object().shape({
    amount: Yup.number()
      .typeError("数値を入力してください")
      .required("必須項目です")
      .min(500, "500円以上にしてください")
      .max(1000000, "100万円以内にしてください"),
    comment: Yup.string().max(100, "100文字以下にしてください").required("必須項目です"),
    onestopFlag: onestopFlagSchema(isHometownTax, onestopDeliverable ?? false),
    recipient: isHometownTax ? residenceSchema : Yup.object().notRequired(),
    payment: object().when("amount", {
      is: (value: number) => value > 300_000,
      then: object().shape({
        method: string().notOneOf(["cvs"], "合計金額が30万円以上の場合はコンビニでお支払いすることはできません。"),
      }),
      otherwise: object(),
    }),
  });
};
