import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { BASE_COLOR1, BORDER_RADIUS } from "./theme";
import UpFade from "../../../motion/UpFade";

const HelpDialog: FC<{ open: boolean; close: () => void; taxLabel?: string; helpImage?: string }> = ({
  open,
  close,
  taxLabel,
  helpImage,
}) => {
  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "scroll";
  }, [open]);
  return (
    <UpFade in={open}>
      <Root>
        <Box>
          <Text>源泉徴収票の「{taxLabel}」に該当します。</Text>
          {helpImage && <Image src={`/img/lp/hometown_tax/${helpImage}`} width="100%" />}
          <CloseButton onClick={close}>閉じる</CloseButton>
        </Box>
      </Root>
    </UpFade>
  );
};
export default HelpDialog;

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
`;
const Box = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  ${BORDER_RADIUS};
  min-width: 320px;
  max-width: 700px;
  width: 90%;
  padding: 10px 10px 5px 10px;
`;
const Text = styled.div`
  margin: 10px 0;
`;
const Image = styled.img`
  margin: 20px 0;
`;

const CloseButton = styled.button`
  margin: 10px 0;
  padding: 10px;
  text-align: center;
  border: 1px solid ${BASE_COLOR1};
  ${BORDER_RADIUS};
  width: 100%;
`;
