import React, { FC } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Field, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { useScript } from "lib/hooks/useScript";
import { AaTextField } from "../../layout/aniale/AaTextField";
import { SbpaymentConfig } from "interfaces/front/business";


declare global {
  interface Window {
    com_sbps_system: any;
  }
}

type CardTokenInput = {
  cc_number: string;
  expire_year: string;
  expire_month: string;
  security_code: string;
};

const NOW = new Date();

const Schema = Yup.object().shape({
  cc_number: Yup.string()
    .max(16, "16文字以下にしてください")
    .matches(/^\d{14,16}$/, "14~16桁の数値を入力してください")
    .required("必須項目です"),
  expire_year: Yup.string()
    .matches(/^\d{4}$/, "正しい年を入力してください")
    .required("必須項目です"),
  expire_month: Yup.string()
    .matches(/^\d{2}$/, "正しい月を入力してください")
    .required("必須項目です")
    // `this`コンテキストを使用するためにアロー関数を使用していない。
    .test("futureMonth", "有効期限が過去の月です。", function test(_) {
      return !(this.parent.expire_year === NOW.getFullYear().toString() && Number(this.parent.expire_month) < NOW.getMonth() + 1);
    }),
  security_code: Yup.string()
    .min(3, "3~4桁の数値を入力してください")
    .max(4, "3~4桁の数値を入力してください")
    .matches(/^\d{3,4}$/, "3~4桁の数値を入力してください")
    .required("必須項目です"),
});

const initialValues: CardTokenInput = {
  cc_number: "",
  expire_year: "",
  expire_month: "",
  security_code: "",
};

export interface TokenResponse {
  token: string;
  tokenKey: string;
  maskedCcNumber: string;
  ccExpiration: string;
}

const generateToken = (input: CardTokenInput, sbpaymentConfig: SbpaymentConfig): Promise<TokenResponse> => {
  return new Promise<TokenResponse>((resolve, reject) => {
    window.com_sbps_system.generateToken(
      {
        merchantId: sbpaymentConfig.merchant_id,
        serviceId: sbpaymentConfig.service_id,
        ccNumber: input.cc_number,
        ccExpiration: input.expire_year + input.expire_month,
        securityCode: input.security_code,
      },
      (response: any) => {
        if (response.result === "OK") {
          resolve(response.tokenResponse as TokenResponse);
        } else {
          reject(response.errorCode);
        }
      }
    );
  });
};

const CARD_IMAGES = [
  "/img/card/credit-card_9.png",
  "/img/card/credit-card_22.png",
  "/img/card/credit-card_19.png",
  "/img/card/credit-card_28.png",
];

export const CreditCardDialog: FC<{
  isOpen: boolean;
  close: () => void;
  onGetToken: (tokenResponse: TokenResponse) => void;
  onCancel: () => void;
  injectionAmount?: number;
  submitLabel?: string;
  sbpaymentConfig: SbpaymentConfig;
}> = ({ isOpen, close, onGetToken, onCancel, injectionAmount, submitLabel = "決定", sbpaymentConfig }) => {
  useScript(process.env.STB_TOKEN_SCRIPT_URL as string);
  const onSubmit = async (formikValues: CardTokenInput, helper: FormikHelpers<CardTokenInput>): Promise<void> => {
    const tokenResponse = await generateToken(formikValues, sbpaymentConfig);
    await onGetToken(tokenResponse);
    await close();
    await helper.resetForm();
    await helper.setSubmitting(false);
  };
  return (
    <Dialog onClose={onCancel} open={isOpen} fullWidth scroll="body">
      <Formik<CardTokenInput> initialValues={initialValues} onSubmit={onSubmit} validationSchema={Schema}>
        {({ isSubmitting, handleSubmit }): React.ReactNode => (
          <>
            <DialogTitle disableTypography>
              <span>クレジットカードの入力</span>
              <IconButton
                onClick={onCancel}
                style={{
                  position: "absolute",
                  right: "6px",
                  top: "0",
                  color: "gray",
                }}
              >
                <Close viewBox={"0 0 24 24"} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {injectionAmount && (
                <div>
                  <div style={{ fontSize: "12px" }}>決済金額</div>
                  <div style={{ margin: "10px 0" }}>{injectionAmount} 円</div>
                </div>
              )}
              <div>
                <div style={{ fontSize: "12px" }}>利用可能なクレジットカード</div>
                <div style={{ margin: "10px 0" }}>
                  {CARD_IMAGES.map((imageUrl, idx) => (
                    <img key={idx} src={imageUrl} width={40} style={{ marginRight: "10px" }} />
                  ))}
                </div>
              </div>
              <div style={{ margin: "20px 0" }}>
                <div style={{ fontSize: "12px" }}>カード番号</div>
                <Field
                  fullWidth
                  id="cc_number"
                  component={AaTextField}
                  variant="outlined"
                  name="cc_number"
                  type="text"
                  inputProps={{ maxLength: 16 }}
                  size="small"
                  style={{ margin: "10px 0" }}
                />
              </div>
              <div style={{ margin: "20px 0" }}>
                <div style={{ fontSize: "12px" }}>有効期限</div>
                <Grid container alignItems={"center"} style={{ margin: "10px 0" }}>
                  <Grid item style={{ marginRight: "8px" }}>
                    <Field
                      component={TextField}
                      variant="outlined"
                      select
                      size="small"
                      name="expire_month"
                      type="text"
                      style={{ minWidth: "60px" }}
                    >
                      {[...Array(12)].map((_, i) => (
                        <MenuItem key={i + 1} value={("00" + (i + 1)).slice(-2)}>
                          {i + 1}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item style={{ marginRight: "8px" }}>
                    <span style={{ fontSize: "12px" }}>月</span>
                  </Grid>
                  <Grid item style={{ marginRight: "8px" }}>
                    <Field
                      component={TextField}
                      variant="outlined"
                      select
                      size="small"
                      name="expire_year"
                      type="text"
                      style={{ minWidth: "80px" }}
                    >
                      {[...Array(20)].map((_, i) => (
                        <MenuItem key={i + 2020} value={i + 2020}>
                          {i + 2020}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item style={{ marginRight: "8px" }}>
                    <span style={{ fontSize: "12px" }}>年</span>
                  </Grid>
                </Grid>
              </div>
              <div style={{ margin: "20px 0" }}>
                <div style={{ fontSize: "12px" }}>セキュリティコード</div>
                <Field
                  fullWidth
                  component={AaTextField}
                  variant="outlined"
                  name="security_code"
                  type="text"
                  inputProps={{ maxLength: 4 }}
                  size="small"
                  style={{ margin: "10px 0" }}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
                type="submit"
                color="primary"
                variant={"contained"}
                fullWidth
                style={{ margin: "0 16px 16px" }}
              >
                {submitLabel}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
