import { PaymentSetting } from "interfaces/front/page/orderForm";
import { useMemo } from "react";

const useInitialPayment = (payment: PaymentSetting) =>
  useMemo(
    () =>
      payment.can_use_credit_card_reuse && payment.used_credit_card ? { method: "credit_card", credit_card: { reuse: {} } } : undefined,
    [payment]
  );
export default useInitialPayment;
