import styled from "styled-components";

export const Card = styled.div`
  display: block;
  transition: 0.2s;
  cursor: pointer;
  @media screen and (min-width: 961px) {
    &:hover {
      filter: drop-shadow(3px 3px 0 lime);
    }
  }
  @media screen and (max-width: 960px) {
    display: flex;
    align-items: stretch;
    width: 100%;
  }
`;
export const TextBackground = styled.div`
  height: calc(100% - 16px);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  background-color: #1a1a1a;

  &:before {
    background-color: #1a1a1a;
    content: "";
    position: absolute;
    left: 0;
    bottom: -16px;
    width: calc(100% - 16px);
    height: 16px;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -16px;
    right: 0;
    border-style: solid;
    border-width: 16px 16px 0 0;
    border-color: #1a1a1a transparent transparent transparent;
  }
`;
export const ImageArea = styled.div`
  @media screen and (max-width: 960px) {
    max-width: 200px;
    flex: 1 1;
    height: 100%;
  }
`;
export const Thumbnail = styled.div<{ imageUrl: string; hasGoal: boolean }>`
  width: 100%;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  background-position: center;
  position: relative;
  @media screen and (max-width: 960px) {
    height: ${({ hasGoal }) => (hasGoal ? "76px" : "90px")};
  }
  @media screen and (min-width: 961px) {
    height: 162px;
  }
  @media screen and (min-width: 1281px) {
    height: 202px;
  }
  @media screen and (min-width: 1921px) {
    height: 220px;
  }
`;
export const ItemTextArea = styled.div`
  z-index: 2;
  color: #fff;
  padding: 10px;
  position: relative;

  @media screen and (max-width: 960px) {
    flex: 1 1;
    min-width: 220px;
    height: 90px;
  }
`;
export const DetailLiteral = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5em;
  font-style: italic;
  @media screen and (max-width: 960px) {
    font-size: 12px;
    -webkit-line-clamp: 1;
  }
  @media screen and (min-width: 961px) {
    font-size: 14px;
    -webkit-line-clamp: 2;
  }
  @media screen and (min-width: 1281px) {
    font-size: 16px;
    -webkit-line-clamp: 2;
  }
`;
export const DetailLiteralSub = styled.div<{ showsSupportAmount: boolean }>`
  visibility: ${({ showsSupportAmount }) => (showsSupportAmount ? "visible" : "hidden")};
  line-height: 1.5em;
  @media screen and (max-width: 960px) {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 10px;
  }
  @media screen and (min-width: 961px) {
    margin-top: 16px;
    font-size: 11px;
  }
  @media screen and (min-width: 1281px) {
    font-size: 12px;
  }
`;
export const BadgeArea = styled.div`
  width: 47px;
  height: 47px;
  background-image: url(/img/message_bg.png);
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 4px;
  padding-top: 7px;
  text-align: right;
  z-index: 3;
`;
export const BadgeText = styled.p`
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  font-size: 12px;
  color: #fff;
`;
