import { CatalogImage } from "interfaces/admin/business";
import { Error, ErrorV2, Result } from "lib/service/BaseClientService";
import { AdminBaseService } from "lib/service/client/AdminBaseService";

export class AdminReturnImageService extends AdminBaseService {
  constructor(adminType: string, projectId: string) {
    super(adminType);
    this.url = `${this.url}/projects/${projectId}/return_images`;
  }

  async list(): Promise<Result<ReturnImageListOutput, Error>> {
    return super.doSearch<ReturnImageListOutput, Error>(this.url, {});
  }

  async put(input: ReturnImagePutInput): Promise<Result<ReturnImagePutOutput, ErrorV2>> {
    const body = JSON.stringify(input);
    return super.doPut<ReturnImagePutOutput, ErrorV2>(this.url, "", body);
  }
}

export type ReturnImageListOutput = CatalogImage[];
export type ReturnImagePutInput = {
  return_images: { id: number | null; url: string }[];
};
export type ReturnImagePutOutput = CatalogImage[];
