import React, { FC, InputHTMLAttributes } from "react";
import styled from "styled-components";
import { MAIN_COLOR2 } from "../theme";

const Radio: FC<InputHTMLAttributes<HTMLInputElement>> = ({ ...props }) => {
  return (
    <Root>
      <RawInput type="radio" {...props} />
      <Mark1 focusable="false" viewBox="0 0 24 24">
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
      </Mark1>
      <Mark2 focusable="false" viewBox="0 0 24 24">
        <path d="M8.465 8.465C9.37 7.56 10.62 7 12 7C14.76 7 17 9.24 17 12C17 13.38 16.44 14.63 15.535 15.535C14.63 16.44 13.38 17 12 17C9.24 17 7 14.76 7 12C7 10.62 7.56 9.37 8.465 8.465Z" />
      </Mark2>
    </Root>
  );
};
export default Radio;

const Root = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  position: relative;
  touch-action: manipulation;
`;

const RawInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
`;

const Mark1 = styled.svg`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  fill: #666;

  ${RawInput}:checked ~ & {
    fill: ${MAIN_COLOR2};
  }
`;
const Mark2 = styled.svg`
  position: absolute;
  left: 0;
  width: 0;
  height: 0;

  ${RawInput}:checked ~ & {
    width: 100%;
    height: 100%;
    fill: ${MAIN_COLOR2};
  }
`;
