import { AnialeCreditCardParameter, AnialeCreditCardReuseParameter, AnialeCvsParameter } from "../service/client/FrontOrderService";

/** 再利用の際のクレジット決済パラメータ */
export const CREDIT_REUSE_PARAMETER: AnialeCreditCardReuseParameter = {
  method: "credit_card_reuse",
};
/** クレジット決済の空パラメータ */
export const CREDIT_EMPTY_PARAMETER: AnialeCreditCardParameter = {
  method: "credit_card",
  onetime_token: "",
  onetime_token_key: "",
};

export const CVS_EMPTY_PARAMETER: AnialeCvsParameter = { method: "cvs", cvs_type: "family_mart", phone_number: "" };
