import { ClickAwayListener, Tooltip, withStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useActivation } from "lib/hooks/useActivation";
import { StyledLeftText } from "../layout/styled";
import styled from "styled-components";

const StyledTooltip = withStyles((theme) => ({
  popper: {
    zIndex: 60,
  },
  tooltip: {
    fontFamily: "'YuGothic Bold', 'Yu Gothic Bold', 'Roboto', sans-serif",
    fontWeight: 600,
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 296,
    fontSize: "12px",
    border: "1px solid #dadde9",
    display: "block",
    transform: "none",
    padding: "8px",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
  },
  arrow: {
    color: "#f5f5f9",
  },
}))(Tooltip);

export const AnialeHelpTooltip: FC<{
  clickable: boolean;
  children: FC<{ onClick: () => void }>;
}> = ({ clickable, children }) => {
  const [tooltipOpen, open, close] = useActivation(false);
  const onClick = () => {
    clickable && open();
  };
  return (
    <ClickAwayListener onClickAway={close}>
      <StyledTooltip
        PopperProps={{
          disablePortal: true,
        }}
        arrow
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="top"
        open={tooltipOpen}
        onClose={close}
        title={
          <React.Fragment>
            <div>
              <HelpTitle>
                <img src="/img/icons/aniale.png" width="18px" />
                <span>アニエールとは？</span>
              </HelpTitle>
              <HelpDescription>
                <StyledLeftText>
                  好きなアニメにエール(応援)を
                  <span style={{ letterSpacing: "-3px" }}>―――</span>。
                </StyledLeftText>
                <StyledLeftText>
                  アニエールは、アニメ作品やそのクリエイター、アニメの聖地となる自治体など、アニメを支える皆様を直接応援することができるサービスです。
                </StyledLeftText>
                <StyledLeftText>
                  応援者様は任意の金額分のアニエールを送ることができ、決済手数料・サービス利用料を差し引いた額が製作側やクリエイター、自治体に還元されます。
                </StyledLeftText>
                <StyledLeftText>
                  応援者様はプロジェクトページにお名前が掲載されるほか、応援総額に応じて限定アイコンを入手することができます。
                </StyledLeftText>
              </HelpDescription>
            </div>
          </React.Fragment>
        }
      >
        <div>{children({ onClick })}</div>
      </StyledTooltip>
    </ClickAwayListener>
  );
};

const HelpTitle = styled.h4`
  font-family: "YuGothic Bold", "Yu Gothic Bold", "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 10px 0;
  display: inline-flex;
  align-items: center;
  @media screen and (min-width: 961px) {
    font-size: 18px;
  }
`;
const HelpDescription = styled.div`
  font-family: "YuGothic Bold", "Yu Gothic Bold", "Roboto", sans-serif;
  line-height: 1.4em;

  & > * {
    margin: 4px 0;
  }
`;
