import React, { FC } from "react";
import styled from "styled-components";

const BankCard: FC = () => {
  return (
    <Root>
      <Title>銀行振込でお支払い</Title>
      <Content>
        <Attention>※支払先の口座情報は、応援完了後に表示されます。</Attention>
        <Attention>※支払先の口座情報は、応援後のマイページにも表示されます。</Attention>
      </Content>
    </Root>
  )
}
export default BankCard;

const Root = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 16px;
`;

const Title = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
`;

const Content = styled.div`
  margin-top: 16px;
`;

const Attention = styled.div`
  font-size: 14px;
  line-height: 1.428em;
`;
