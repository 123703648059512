import React, { FC, HTMLAttributes, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import BottomSlide from "components/front/motion/BottomSlide";
import FamilyRadio from "./FamilyRadio";
import Counter from "./Counter";

const HandicappedField: FC<HTMLAttributes<HTMLDivElement>> = ({ ...props }) => {
  const formik = useFormikContext();
  const field = formik.getFieldProps("support.handicapped_flag");
  const existsHandicapped = useMemo(() => field.value === "あり", [field.value]);
  useEffect(() => {
    formik.setFieldValue("family.handicapped", defaultHandicapped);
  }, [existsHandicapped]);
  return (
    <Root {...props}>
      <StyledFamilyRadio label="障害者" name={field.name} options={["なし", "あり"]} />
      <BottomSlide in={existsHandicapped}>
        <FieldSet>
          <StyledCounter label="一般障害者" name="family.handicapped.general" />
          <StyledCounter label="特別障害者(別居または本人)" name="family.handicapped.special_separated" />
          <StyledCounter label="同居特別障害者" name="family.handicapped.special_together" />
        </FieldSet>
      </BottomSlide>
    </Root>
  );
};
export default HandicappedField;

const defaultHandicapped = {
  general: 0,
  special_separated: 0,
  special_together: 0,
};

const Root = styled.div``;
const StyledFamilyRadio = styled(FamilyRadio)`
  margin-bottom: 5px;
`;
const FieldSet = styled.div`
  padding: 5px 0;
`;
const StyledCounter = styled(Counter)`
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
`;
