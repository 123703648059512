import React, { FC } from "react";
import { format } from "date-fns";
import { Project } from "interfaces/front/business";
import styled from "styled-components";
import useServiceType from "lib/hooks/front/useServiceType";

type Props = Pick<Project, "recruit_method" | "support_end_at" | "goal_amount_flag" | "service_type">;
export const RuleLiteral: FC<Props> = ({ recruit_method, support_end_at, goal_amount_flag, service_type }) => {
  const { isCorpHometownTax } = useServiceType(service_type);
  const supportEndAt = format(new Date(support_end_at), "yyyy/MM/dd");
  return goal_amount_flag ? (
    <Root>
      {recruit_method === "all_in" &&
        (isCorpHometownTax ? (
          <Literal>プロジェクト終了日までに応援と支払いを完了した時点で、寄附が成立いたします。</Literal>
        ) : (
          <Literal>
            <span style={{ display: "inline-block" }}>❇︎こちらはAll-in 型のプロジェクトです。</span>
            <span style={{ display: "inline-block" }}>
              目標金額の達成にかかわらずプロジェクト終了日{supportEndAt}までに支払いを完了した時点で、応援購入が成立します。
            </span>
          </Literal>
        ))}
      {recruit_method === "all_or_nothing" && (
        <>
          <Literal>
            <span style={{ display: "inline-block" }}>❇︎こちらはAll-or-Nothing型のプロジェクトです。</span>
          </Literal>
          <Literal>
            <span style={{ display: "inline-block" }}>プロジェクト終了期間までに目標金額に到達した場合、</span>
            <span style={{ display: "inline-block" }}>プロジェクトが成立となります。</span>
          </Literal>
          <Literal>
            <span style={{ display: "inline-block" }}>目標金額が達成できなかった場合には</span>
            <span style={{ display: "inline-block" }}>プロジェクトは不成立となり、</span>
            <span style={{ display: "inline-block" }}>応援いただいた皆様に返金を行います。</span>
          </Literal>
          <Literal>
            <span style={{ display: "inline-block" }}>※返金発生時には別途ご連絡差し上げます。</span>
          </Literal>
        </>
      )}
    </Root>
  ) : (
    <></>
  );
};

const Root = styled.div`
  width: 100%;
  background-color: #e5e5e5;
  @media screen and (min-width: 961px) {
    padding: 20px;
  }
  @media screen and (max-width: 960px) {
    padding: 10px;
  }
`;

const Literal = styled.p`
  transform-origin: center;
  color: #1a1a1a;
  text-align: center;
  line-height: 1.45em;
  @media screen and (min-width: 961px) {
    font-size: 14px;
  }
  @media screen and (max-width: 960px) {
    font-size: 12px;
  }
`;
