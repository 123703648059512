import React, { useEffect, useMemo } from "react";
import { ItemTitle } from "../layout/ItemTitle";
import { isOnamaeOption } from "lib/sydm2";
import { CourseOption } from "interfaces/front/business";
import { format } from "date-fns";
import { AaTextField } from "../layout/aniale/AaTextField";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { FormHelperText, MenuItem } from "@material-ui/core";
import { Select } from "formik-material-ui";
import FormControl from "@material-ui/core/FormControl";
import styled from "styled-components";
import { CourseOptionInput } from "interfaces/front/page/orderForm";

type Props = {
  name: string;
  options: CourseOption[];
  supportEndAt: string;
};

export const CourseOptionArea: React.FC<Props> = ({ name, options, supportEndAt }: Props) => {
  const formik = useFormikContext();
  const field = formik.getFieldProps<CourseOptionInput>(name);
  const option = useMemo(() => options.find((option) => option.id === field.value.id)!!, [field.value.id]);
  const supportEndAtFormat = useMemo(() => format(new Date(supportEndAt), "MM/dd"), [supportEndAt]);
  const isOnamae = useMemo(() => isOnamaeOption(option.question), [option.question]);
  const selectionInputs = useMemo(() => {
    return option.selection
      ? option.selection.map((selection) => ({
          label: selection,
          value: selection,
        }))
      : [];
  }, [option.selection]);
  useEffect(() => {
    if (isOnamae) formik.setFieldTouched(`${name}.value`, true);
  }, [isOnamae]);
  return (
    <div>
      <div style={{ margin: "14px 0" }}>
        <ItemTitle label={option.question} />
      </div>
      {/* chromeによる自動入力を防ぐためのダミー */}
      <input type="text" name="dummy_field" style={{ display: "none" }} autoComplete="none" />
      {option.input_type === "text" && (
        <Field component={AaTextField} name={`${name}.value`} variant="outlined" size="small" fullWidth={true} autoComplete="none"/>
      )}
      {option.input_type === "selectbox" && (
        <FormControl size="small" fullWidth={true}>
          <Field component={Select} name={`${name}.value`} size="small" variant="outlined" fullWidth={true} displayEmpty>
            <MenuItem value={""} disabled>
              選択してください
            </MenuItem>
            {selectionInputs.map(({ label, value }, index) => (
              <MenuItem value={value} key={index}>
                {label}
              </MenuItem>
            ))}
          </Field>
          <ErrorMessage name={`${name}.value`}>
            {(errorMessage) => (
              <FormHelperText variant="outlined" error>
                {errorMessage}
              </FormHelperText>
            )}
          </ErrorMessage>
        </FormControl>
      )}
      {isOnamae && (
        <Annotation>
          <p>＊本項目は応援後もマイページにて変更可能です。</p>
          <p>（変更期限: クラファン掲載終了日 {supportEndAtFormat} まで）</p>
        </Annotation>
      )}
    </div>
  );
};

const Annotation = styled.div`
  margin-top: 6px;
  background-color: #e5e5e5;
  text-align: center;
  width: 100%;
  @media screen and (min-width: 961px) {
    font-size: 14px;
    line-height: 1.428em;
    padding: 20px;
  }
  @media screen and (max-width: 960px) {
    font-size: 12px;
    line-height: 1.25em;
    padding: 10px;
  }
`;
