import { Field, FieldProps } from "formik"
import React from "react";

type Props = {
  label?: string;
  name: string;
  isRequired?: boolean;
  isSecure?: boolean;
}

export const InputField: React.FC<Props> = ({name, label = '', isRequired = false, isSecure = false}: Props) => {
  return (
    <Field name={name}>
      {({field, meta}: FieldProps<string>): React.ReactNode => (
        <div className="form--item">
          {(!!label) && (
            <p className="form--item_name">
              {label}
              {isRequired && (<span className="form_template_title--required">*</span>)}
            </p>
          )}
          <div className="form--item_input form--item_input--black">
            <div className="form--item_input_bg" />
            <input type={isSecure ? 'password' : 'text'} {...field} />
          </div>
          {/* TODO: エラー位置確認 */}
          {meta.touched && meta.error && (
            <p className="form_template_area_block--error">{meta.error}</p>
          )}
        </div>
      )}
    </Field>
  );
}
