import React, { FC, useMemo } from "react";
import { ssCenterText } from "./SsStyles";
import styled from "@material-ui/core/styles/styled";
import { DividableLabel } from "./DividableLabel";

type Props = {
  label: string | string[];
  onClick: () => void;
  margin?: string;
  small?: boolean;
};
export const SsDialogOpenButton: FC<Props> = ({
  label,
  onClick,
  margin,
  small = false,
}) => {
  const style = small
    ? {}
    : { width: "100%", maxWidth: "400px", height: "44px" };
  const fontSize = small ? "12px" : "14px";
  const labels = useMemo(() => Array.isArray(label) ? label : [label], [label]);
  return (
    <div
      className="form_button"
      style={{
        ...style,
        cursor: "pointer",
        margin: margin,
        textAlign: "center"
      }}
    >
      <a onClick={onClick}>
        <div className="form_button_bg" />
        <Label className="form_button_text" style={{ fontSize: fontSize }}>
          <DividableLabel texts={labels}/>
        </Label>
      </a>
    </div>
  );
};

const Label = styled("p")(() => ({
  ...ssCenterText,
  lineHeight: "1.1em"
}))
