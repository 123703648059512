import { useMemo } from "react";

/**
 * キャッシュ応援金額がある場合、実際の応援金額取得までの間はキャッシュ応援金額により応援金額表示可否判断を行う。
 * 応援金額表示可否判断は応援金額がnullであるか否かにより判別する。
 * @param supportAmount 応援金額。nullは非表示、undefinedは応援金額取得前状態として扱われる。
 * @param cachedSupportAmount キャッシュされた応援金額。nullは非表示、undefinedはキャッシュなしとして扱われる。
 * @returns 応援金額表示可否
 */
const useShowsSupportAmount = <T>(supportAmount?: T | null, cachedSupportAmount?: T | null): supportAmount is T | undefined => {
  // return false;
  return useMemo(() => {
    if (cachedSupportAmount !== undefined) {
      if (supportAmount !== undefined) {
        return supportAmount !== null;
      } else {
        return cachedSupportAmount !== null;
      }
    } else {
      return supportAmount !== null;
    }
  }, [supportAmount, cachedSupportAmount]);
};
export default useShowsSupportAmount;
