import React, { ComponentType, FC } from "react";
import { useNetworkError, NetworkErrorContextProvider } from "lib/contexts/NetworkErrorContext";
import { NetworkError } from "components/front/order/error/NetworkError";
import styled from "styled-components";

export const withOrderNetworkError = <Props extends Record<string, unknown>>(
  ComposedComponent: ComponentType<Props>
): ComponentType<Props> => {
  return (props): JSX.Element => {
    return (
      <NetworkErrorContextProvider>
        <OrderNetworkErrorHandler>
          <ComposedComponent {...props} />
        </OrderNetworkErrorHandler>
      </NetworkErrorContextProvider>
    );
  };
};

const OrderNetworkErrorHandler: FC = ({ children, ...props }) => {
  const networkError = useNetworkError();
  return networkError ? (
    <Root>
      <Box>
        <NetworkError type="support" />
      </Box>
    </Root>
  ) : (
    <>{children}</>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin: auto;
  @media screen and (min-width: 961px) {
    max-width: 1280px;
  }
`;
const Box = styled.div`
  background-color: white;
  @media screen and (min-width: 961px) {
    padding: 20px 60px;
    margin: 60px 10px 60px;
  }
  @media screen and (max-width: 960px) {
    padding: 20px;
    margin: 30px 10px 30px;
  }
`;
