import { useMemo } from "react";
import { CartItem, OrderItemInput } from "interfaces/front/page/orderForm";
import { isOnamaeOption } from "lib/sydm2";
import { Member } from "interfaces/front/business";

const useOrderItems = (cartItems: CartItem[], member: Member | null | undefined) => {
  return useMemo<OrderItemInput[]>(
    () =>
      cartItems.map((cartItem) => ({
        course_id: cartItem.course.id,
        price: cartItem.price,
        details: [...Array(cartItem.quantity)].map(() => ({
          course_options: cartItem.course.course_options.map((option) => ({
            id: option.id,
            question: option.question,
            value: isOnamaeOption(option.question) ? member?.nickname || "" : "",
          })),
        })),
      })),
    [cartItems]
  );
};
export default useOrderItems;
