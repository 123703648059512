import { FC } from "react";

type Props = {
  isAuthError: boolean;
  message?: string;
}

export const AuthErrorMessage: FC<Props> = (props: Props) => {
  if (!props.isAuthError) return (<></>)
  return (
    <div className="error error_02" style={{margin: "20px 0"}}>
      <p>{props.message || 'ユーザー名またはパスワードが不正です。'}</p>
    </div>
  )
}
