import React, { createContext, FC, Provider, useCallback, useContext, useEffect, useReducer } from "react";
import { doNothing } from "lib/util";

export const NetworkErrorContextProvider: FC = ({ children }) => {
  const context = useNetworkErrorReducer();
  return <NetworkErrorContext.Provider value={context}>{children}</NetworkErrorContext.Provider>;
};
export const useTriggerNetworkError = () => {
  const context = useContext(NetworkErrorContext);
  return useCallback(() => {
    context.dispatch({ type: "triggerNetworkError" });
  }, [context]);
};
export const useNetworkError = (): boolean => {
  const context = useContext(NetworkErrorContext);
  return context.state.isNetworkError;
};

type State = { isNetworkError: boolean };
type Context = {
  state: State;
  dispatch: React.Dispatch<Action>;
};
const initialState: State = {
  isNetworkError: false,
};
type Action = {
  type: "reset" | "triggerNetworkError";
};
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "reset":
      return { ...state, isNetworkError: false };
    case "triggerNetworkError":
      return { ...state, isNetworkError: true };
  }
};

const NetworkErrorContext = createContext<Context>({
  state: initialState,
  dispatch: doNothing,
});
const useNetworkErrorReducer = (): Context => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    dispatch({ type: "reset" });
  }, []);
  return { state, dispatch };
};
