import { useMemo } from "react";

export const useRating = (goalAmount: number | null, supportAmount?: number | null): number | null | undefined => {
  return useMemo(() => {
    if (goalAmount !== null && supportAmount !== null) {
      if (supportAmount !== undefined) {
        return Math.floor((supportAmount * 100) / goalAmount);
      } else {
        return undefined;
      }
    } else {
      return null;
    }
  }, [goalAmount, supportAmount]);
};
