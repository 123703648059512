import { useMemo } from "react";
import { differenceInSeconds } from "date-fns";
import { EndingCountdownExpression } from "../project_util";

export const useRemainingDays = (supportEndAt: string): EndingCountdownExpression | null => {
  return useMemo(() => {
    const remainedSeconds = differenceInSeconds(new Date(supportEndAt), new Date());
    if (remainedSeconds >= 3600 * 24 * 3) {
      return { data: Math.floor(remainedSeconds / (3600 * 24)), unit: "日" };
    } else if (remainedSeconds >= 3600) {
      return { data: Math.floor(remainedSeconds / 3600), unit: "時間" };
    } else if (remainedSeconds >= 60) {
      return { data: Math.floor(remainedSeconds / 60), unit: "分" };
    } else if (remainedSeconds >= 1) {
      return { data: 1, unit: "分" };
    } else {
      return null;
    }
  }, [supportEndAt]);
};
