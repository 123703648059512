import { useCallback, useContext, useState } from "react";
import {
  AnialeFormCrowdFundingData,
  AnialeFormHometownTaxData,
  FrontOrderService,
  TippingOutput,
} from "lib/service/client/FrontOrderService";
import { ErrorV2 } from "lib/service/BaseClientService";
import * as Sentry from "@sentry/node";
import { useRouter } from "next/router";
import { MemberTippingCommentHelper } from "lib/hooks/useMemberTippingComment";
import { AnialeFormStep } from "components/front/aniale/form/AnialeFormContainer";
import { useActivation } from "lib/hooks/useActivation";
import EnhancedEcommerceContext from "lib/contexts/EnhancedEcommerceContext";

const useAnialeSubmitHandler = (
  memberTippingCommentHelper: MemberTippingCommentHelper,
  setStep: (step: AnialeFormStep) => void
): [
  (TippingOutput & { amount: number }) | undefined,
  ErrorV2 | undefined,
  boolean,
  (value: AnialeFormCrowdFundingData | AnialeFormHometownTaxData) => void
] => {
  const { triggerAnialeThanksEvent } = useContext(EnhancedEcommerceContext.Aniale);
  const [tippingResult, setTippingResult] = useState<TippingOutput & { amount: number }>();
  const [error, setError] = useState<ErrorV2>();
  const [isNetworkError, onNetworkError] = useActivation(false);
  const router = useRouter();
  const handleSubmit = useCallback(async (values: AnialeFormCrowdFundingData | AnialeFormHometownTaxData) => {
    try {
      const service = new FrontOrderService(router);
      const result = await service.orderAsTipping(values);
      if (result.isSuccess()) {
        await memberTippingCommentHelper.reload();
        const tippingResult = result.value as TippingOutput;
        await setTippingResult({ ...tippingResult, amount: values.amount || 0 });
        await setStep("thanks");
        await triggerAnialeThanksEvent(tippingResult, values.amount).catch(console.error);
      } else {
        await setError(result.value as ErrorV2);
        await setStep("failure");
      }
    } catch (e) {
      await onNetworkError();
      await setStep("failure");
      Sentry.captureException(e);
    }
  }, []);
  return [tippingResult, error, isNetworkError, handleSubmit];
};
export default useAnialeSubmitHandler;
