import React, { FC } from "react";
import { DialogTitle, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  icon: {
    position: "absolute",
    right: "6px",
    top: "0",
    color: "gray",
  },
}));

type Props = {
  title: string;
  onClose: () => void;
};
export const FrontDialogTitle: FC<Props> = (props: Props) => {
  const classes = useStyles();
  return (
    <DialogTitle disableTypography>
      <span>{props.title}</span>
      <IconButton className={classes.icon} onClick={props.onClose}>
        <Close viewBox={"0 0 24 24"} />
      </IconButton>
    </DialogTitle>
  );
};
