import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { OrderPayment } from "interfaces/front/business";
import { CvsAdditionalInfo, getCvsInfos } from "lib/order_util";
import { toDateFormat } from "lib/util";

export const CvsInfoArea: FC<{ payment: OrderPayment; withStore: boolean }> = ({ payment, withStore }) => {
  const classes = useStyles();
  const cvsDetail = payment.payment_method === "cvs" ? payment.cvs_detail : undefined;
  const infos = cvsDetail ? getCvsInfos(cvsDetail, withStore) : undefined;
  return (cvsDetail !== undefined && infos !== undefined) ? (
    <div className={classes.root}>
      <span>期限日までにコンビニにてお支払いを完了して下さい。</span>
      <span>お支払いの際に以下の情報が必要となります。</span>
      <InfoTable infos={infos} />
      <div className={classes.payExpirationBlock}>
        <div className={classes.payExpirationHeader}>お支払い期限</div>
        <div className={classes.payExpirationContent}>
          <div className={classes.payExpirationDate}>{toDateFormat(cvsDetail.bill_date)}</div>
          <div className={classes.payExpirationNote}>期限をすぎると応援は自動的にキャンセルとなります。</div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

const InfoTable: FC<{
  infos: CvsAdditionalInfo[];
}> = ({ infos }: { infos: CvsAdditionalInfo[] }) => {
  const classes = useStyles();
  return (
    <div style={{ margin: "20px 0" }}>
      <table className={classes.cvsInfoTable}>
        <tbody>
          {infos.map((info, index) => (
            <tr key={index}>
              <td className={classes.cvsInfoRowHead}>{info.label}</td>
              <td className={classes.cvsInfoRowValue}>
                {info.isLink ? (
                  <a href={info.value} style={{ color: "#00F" }}>
                    {info.value}
                  </a>
                ) : (
                  <>{info.value}</>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px auto",
    maxWidth: "440px",
    lineHeight: "1.428em",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "800px",
    },
  },
  cvsInfoTable: {
    width: "100%",
    border: "solid 1px #DDD",
  },
  cvsInfoRowHead: {
    padding: "10px",
    border: "solid 1px #DDD",
    backgroundColor: "#EEE",
    width: "100px",
    [theme.breakpoints.up("lg")]: {
      width: "200px",
    },
  },
  cvsInfoRowValue: {
    padding: "10px",
    border: "solid 1px #DDD",
    lineBreak: "anywhere",
  },
  payExpirationBlock: {
    border: "solid 1px #F00",
  },
  payExpirationHeader: {
    padding: "10px",
    backgroundColor: "#FFEEEE",
    color: "#F00",
    borderBottom: "solid 1px #F00",
    textAlign: "center",
  },
  payExpirationContent: {
    padding: "20px",
    color: "#000",
    textAlign: "center",
  },
  payExpirationDate: {
    margin: "10px 0",
    fontSize: "20px",
  },
  payExpirationNote: {
    margin: "10px",
    fontSize: "12px",
    color: "#F00",
  },
}));
