import styled from "styled-components";
import { BORDER_RADIUS } from "./theme";

export const SubmitButton = styled.button`
  box-shadow: 0 3px 2px #555;
  ${BORDER_RADIUS};
  background-color: #fecf00;
  color: #503701;
  @media screen and (max-width: 960px) {
    min-width: 200px;
    padding: 10px;
    font-size: 1.2em;
  }
  @media screen and (min-width: 961px) {
    min-width: 300px;
    padding: 15px;
    font-size: 1.2em;
  }
`;
