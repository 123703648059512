import React, { FC } from "react";

type Props = {
  message: string;
}
export const MessageText: FC<Props> = (props: Props) => {
  return (
    <>
      {props.message.split('\n').map((str, index) => (
        <React.Fragment key={index}>{str}<br /></React.Fragment>
      ))}
    </>
  )
}
