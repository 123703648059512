import React, { FC, HTMLAttributes } from "react";
import { FastField, FieldProps } from "formik";
import styled from "styled-components";
import ErrorText from "components/admin/project/layouts/ErrorText";

type Props = HTMLAttributes<HTMLElement> & { name: string; label: string; disabled?: boolean; fullWidth?: boolean };
const DateField: FC<Props> = ({ name, label, fullWidth = false, disabled = false, ...props }) => {
  return (
    <FastField name={name}>
      {({ field, meta }: FieldProps<string>) => (
        <Root $fullWidth={fullWidth} {...props}>
          <Label htmlFor={props.id}>{label}</Label>
          <Input type="date" disabled={disabled} {...field} $fullWidth={fullWidth} step="1" />
          {meta.touched && meta.error && (<ErrorText>{meta.error}</ErrorText>)}
        </Root>
      )}
    </FastField>
  );
};
export default DateField;

const Root = styled.div<{ $fullWidth: boolean }>`
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "inherit")};
`;
const Label = styled.label`
  display: block;
`;
const Input = styled.input<{ $fullWidth: boolean }>`
  padding: 2px;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "200px")};
`;
