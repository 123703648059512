import styled from "styled-components";

export const Root = styled.div`
  position: relative;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: normal;
`;
export const Label = styled.label`
  position: absolute;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  top: 0;
  left: 8px;
  padding: 0 8px;
  background-color: #fff;
`;
export const Box = styled.div<{ $fullWidth?: boolean }>`
  margin-top: 6px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.23);
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.87);
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "inherit")};
`;
