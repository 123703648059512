import React, { FC } from "react";
import { CheckboxField } from "./CheckboxField";
import { StyledLeftText2 } from "../project/layout/styled";

const OnestopFlagField: FC<{ name: string }> = ({ name }) => {
  return (
    <div>
      <CheckboxField
        name={name}
        label={
          <>
            <span style={{ display: "inline-block" }}>
              ワンストップ特例申請書の
            </span>
            <span style={{ display: "inline-block" }}>送付を希望する</span>
          </>
        }
      />
      <div style={{ marginTop: "10px", overflow: "hidden" }}>
        <StyledLeftText2 style={{ fontSize: "12px", lineHeight: "1.428em" }}>
          TODO: ワンストップ特例申請書に関する説明をここに記述します
        </StyledLeftText2>
      </div>
    </div>
  );
};
export default OnestopFlagField;
