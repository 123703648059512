import React, { FC, HTMLAttributes } from "react";
import AmountInput from "./AmountInput";
import { FastField } from "formik";
import styled from "styled-components";
import HelpIcon from "@material-ui/icons/Help";
import { BASE_COLOR1 } from "../theme";
import { FieldInputProps, FieldMetaProps } from "formik/dist/types";

const AmountField: FC<HTMLAttributes<HTMLDivElement> & { name: string; label: string; onHelp?: () => void }> = ({
  name,
  label,
  onHelp,
  ...props
}) => (
  <FastField name={name}>
    {({ field, meta }: { field: FieldInputProps<string>; meta: FieldMetaProps<string> }) => (
      <>
        <Root {...props}>
          <LabelArea>
            <Label>{label}</Label>
            {onHelp && <Icon onClick={onHelp} />}
          </LabelArea>
          <div>
            <StyledInput unit="円" maxLength={11} {...field} />
          </div>
        </Root>
        {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
      </>
    )}
  </FastField>
);
export default AmountField;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LabelArea = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
`;
const Label = styled.label``;
const Icon = styled(HelpIcon)`
  margin: 5px;
  color: ${BASE_COLOR1};
`;
const StyledInput = styled(AmountInput)`
  @media screen and (max-width: 960px) {
    // mobile-deviceのdouble-tapによるzoom対策
    font-size: 16px;
    width: 100px;
  }
  @media screen and (min-width: 961px) {
    font-size: 20px;
    width: 300px;
  }
  text-align: right;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const ErrorMessage = styled.div`
  text-align: right;
  font-size: 0.8em;
  margin-top: 5px;
  color: #eb0000;
`;
