import styled from "styled-components";
import React, { FC } from "react";

const GrayBlock: FC = ({ children }) => {
  return (
    <Root>
      <div>{children}</div>
    </Root>
  )
};
export default GrayBlock;

const Root = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ccc;
  padding: 10px;
`;
