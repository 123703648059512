import styled from "@material-ui/core/styles/styled";
import React from "react";
import { WhiteTriangleLacking } from "../../layout/WhiteTriangleLacking";
import HelpIcon from "@material-ui/icons/Help";

export const Section = styled("section")(() => ({
  padding: "0",
}));
export const WhiteBox = styled("div")(({ theme }) => ({
  width: "100%",
  backgroundColor: "#fff",
  color: "#1a1a1a",
  padding: "20px",
  [theme.breakpoints.down("sm")]: {
    padding: "15px 10px 30px",
  },
}));

const TextBlock = styled("div")(() => ({
  color: "#fff",
  padding: "5px 10px",
  display: "inline-block",
  position: "relative",
  "&:after": {
    content: "''",
    position: "absolute",
    bottom: "0",
    right: "0",
    border: "3px solid #fff",
    borderTopColor: "transparent",
    borderLeftColor: "transparent",
  },
}));

export const BlackTextBlock = styled(TextBlock)(() => ({
  backgroundColor: "#1a1a1a",
}));

export const GoldTextBlock = styled(TextBlock)(() => ({
  backgroundColor: "#AF9840",
}));

export const StyledText = styled("span")(() => ({
  display: "inline-block",
}));

export const StyledLeftText = styled(StyledText)(() => ({
  textAlign: "left",
  transformOrigin: "left",
}));
export const StyledLeftText2 = styled(StyledLeftText)(() => ({
  width: "100%",
}));

export const StyledRightText = styled(StyledText)(() => ({
  transformOrigin: "right",
}));
export const StyledCenterText = styled(StyledText)(() => ({
  transformOrigin: "center",
}));

export const WhiteLabel = styled(WhiteTriangleLacking)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    padding: "8px 2px 8px 10px",
    display: "inline-flex",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 5px 5px 10px",
    display: "inline-block",
  },
  backgroundColor: "#FFF",
  color: "#1a1a1a",
  alignItems: "center",
  position: "relative",
}));

const Icon = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: "9px",
    height: "12px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "7px",
    height: "10px",
  },
  display: "inline-block",
  backgroundSize: "cover",
  marginRight: "5px",
  position: "relative",
  top: "0",
}));

export const PriceIcon = styled(Icon)(() => ({
  backgroundImage: "url(/img/icon_price.svg)",
}));

export const OrderCountIcon = styled(Icon)(() => ({
  backgroundImage: "url(/img/icon_supporter.svg)",
}));

export const RemainingDaysIcon = styled(Icon)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: "12px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "10px",
  },
  backgroundImage: "url(/img/icon_days.svg)",
}));

export const Unit = styled("span")(() => ({
  marginLeft: "5px",
}));

export const Value = styled("span")(() => ({
  fontStyle: "italic",
}));

export const RightHelpButton = styled(HelpIcon)(() => ({
  height: "14px",
  width: "14px",
  position: "absolute",
  cursor: "pointer",
  top: "0",
  right: "10px",
}));
