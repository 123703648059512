import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import React, { FC } from "react";
import { ErrorV2 } from "lib/service/BaseClientService";
import { toStringOrderError } from "lib/order_util";
import { LineBreaker } from "components/common/LineBreaker";
import { BlockText } from "components/front/aniale/Styles";
import { NetworkError } from "components/front/order/error/NetworkError";

type Props = {
  isOpen: boolean;
  close: () => void;
  error?: ErrorV2;
  isNetworkError: boolean;
};
export const AnialeFailureDialog: FC<Props> = (props) => {
  return (
    <Dialog open={props.isOpen} scroll="body" onClose={props.close}>
      <DialogContent>
        <div>
          {props.isNetworkError ? (
            <NetworkError type="tipping" />
          ) : (
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                lineHeight: "1.4",
                margin: "0 0 20px",
              }}
            >
              <BlockText>申し訳ございません。</BlockText>
              <BlockText>アニエールができませんでした。</BlockText>
              <div>
                <img src="/img/error.png" width="200" height="200" />
              </div>
              {props.error?.order_error && (
                <div
                  style={{
                    fontSize: "13px",
                    lineHeight: "1.4",
                    margin: "30px 0 10px",
                  }}
                >
                  <LineBreaker
                    text={toStringOrderError(props.error?.order_error)}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          color="primary"
          variant="outlined"
          fullWidth
          style={{ borderColor: "#888", color: "#888" }}
          onClick={props.close}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};
