import { isIeBrowser } from "lib/util";
import loadjs from "loadjs";
import { useEffect } from "react";
import { useActivation } from "lib/hooks/useActivation";

const useTwitterWidget = (): [boolean, () => Twitter.Widgets | undefined] => {
  const [active, activate, deactivate] = useActivation(false);
  useEffect(() => {
    if (isIeBrowser()) return;
    if (!loadjs.isDefined("twttr")) {
      loadjs("https://platform.twitter.com/widgets.js", "twttr");
    }
    loadjs.ready("twttr", {
      success: () => (!!window?.twttr?.widgets ? activate() : deactivate()),
      error: () => deactivate(),
    });
    return () => loadjs.done("twttr");
  }, []);
  return [active, () => window?.twttr?.widgets];
};
export default useTwitterWidget;

declare global {
  interface Window {
    twttr: {
      widgets?: Twitter.Widgets;
    };
  }

  namespace Twitter {
    interface Widgets {
      createShareButton: (url: string, element: HTMLElement, option: object) => Promise<HTMLElement>;
      createTimeline: (dataSource: Twitter.DataSource, target: Element | null, options?: Twitter.Options) => void;
    }

    export type DataSource = {
      sourceType: "profile";
      screenName: "animefund";
    };
    type Options = {
      theme?: "dark";
      width: string;
      height: string;
      tweetLimit?: number;
    };
  }
}
