import React, { FC, useMemo } from "react";
import { TippingAmountCondition } from "interfaces/admin/business";
import { partition } from "lodash";
import { FormDialogContent } from "./Styles";
import UpFade from "components/front/motion/UpFade";
import BottomSlide from "components/front/motion/BottomSlide";
import styles from "./AvatarSurpriseArea.module.css";

const AvatarSurpriseArea: FC<{ conditions: TippingAmountCondition[]; amount?: number; currentAmount?: number }> = ({
  conditions,
  amount,
  currentAmount = 0,
}) => {
  const [presented, candidate] = useMemo<[TippingAmountCondition[], TippingAmountCondition[]]>(
    () => partition(conditions, (condition) => condition.threshold_amount <= currentAmount),
    [conditions, currentAmount]
  );
  const minimum = useMemo(() => Math.min(...candidate.map((it) => it.threshold_amount)), [candidate]);
  const futureAmount = useMemo(() => currentAmount + (amount || 0), [amount]);
  return (
    <BottomSlide in={(amount || 0) >= 500}>
      <FormDialogContent>
        <p className={styles.fieldLabel}>アニエールのお返し</p>
        <UpFade in={minimum <= futureAmount}>
          <div className={styles.subText}>サービス内で利用できるアイコン</div>
          <div className={styles.icons}>
            {presented.map((condition, index) => (
              <div key={index} className={styles.icon}>
                <img className={styles.avatarImage} src={condition.avatar_url} />
                <div className={styles.mask} />
              </div>
            ))}
            {candidate.map((condition, index) => (
              <UpFade in={condition.threshold_amount <= futureAmount} key={index}>
                <div className={styles.icon}>
                  <img className={styles.avatarImage} src={condition.avatar_url} />
                </div>
              </UpFade>
            ))}
          </div>
          <div className={styles.plus}>+</div>
        </UpFade>
        <div className={styles.surpriseImage}>
          <img src="/img/project/aniale/surprise.jpg" width="100%" />
        </div>
      </FormDialogContent>
    </BottomSlide>
  );
};
export default AvatarSurpriseArea;
