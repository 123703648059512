export async function extractUnconvertableChar(
  original: string,
  encoding: string
): Promise<string[] | null> {
  // TODO 現状絵文字を分割できてない。これでできるか検証予定。https://github.com/thanhdnh/emoji-splitter
  const strSplitRegexp = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF]/g;
  const iconv = await import("iconv-lite");
  const converted = iconv.decode(iconv.encode(original, encoding), encoding);
  const originalChars = original.match(strSplitRegexp) || [];
  const convertedChars = converted.match(strSplitRegexp) || [];
  const shorterLength = Math.min(originalChars.length, convertedChars.length);
  const invalidChars = [];
  for (let i = 0; i < shorterLength; i++) {
    if (originalChars[i] !== convertedChars[i]) {
      invalidChars.push(originalChars[i]);
    }
  }
  return invalidChars.length > 0 ? invalidChars : null;
}
