import { AdminBaseService } from "lib/service/client/AdminBaseService";
import { ErrorV2, Result } from "lib/service/BaseClientService";

export class AdminShipUploadService extends AdminBaseService {
  constructor(adminType: string) {
    super(adminType);
    this.url = `${this.url}/page/ship_upload/`;
  }

  async getTargetProjects(): Promise<Result<TargetProjectsOutput, ErrorV2>> {
    return super.doGet<TargetProjectsOutput, ErrorV2>(this.url, "target_projects");
  };

  async getTempfileHandler(): Promise<Result<TempfileHandlerOutput, ErrorV2>> {
    return super.doGet<TempfileHandlerOutput, ErrorV2>(`${this.url}`, "tempfile_handler");
  }

  async registerShipRecord(projectId: number, input: RegisterShipInput): Promise<Result<void, ErrorV2>> {
    const body = JSON.stringify(input);
    return super.doPost<void, ErrorV2>(`${this.url}/${projectId}/ship`, body);
  }
}

export type TargetProjectsOutput = {
  id: number;
  title: string;
}[];

export type UploadShipInput = {
  csv_file?: File;
};

export type TempfileHandlerOutput = {
  filename: string;
  upload_url: string;
};

export type RegisterShipInput = {
  filename: string;
};
